import { SingleSelectResultV1 } from "@pulse/pulse-rpcs";
import {
  createSingleSelectAnswerV1Model,
  getHundredthDecimalRoundedPercentageString,
  SingleSelectAnswerV1Model,
} from "@pulse/shared-components";
import { Instance, types } from "mobx-state-tree";

export const SingleSelectResultsModelV1 = types.model(
  "SingleSelectResultsModelV1",
  {
    answer: SingleSelectAnswerV1Model,
    percentage: types.string,
    count: types.number,
  },
);

export const createSingleSelectResultsModelV1 = (
  singleSelectResultV1: SingleSelectResultV1[],
): Instance<typeof SingleSelectResultsModelV1>[] => {
  return singleSelectResultV1.map((singleSelectResult) => {
    return SingleSelectResultsModelV1.create({
      answer: createSingleSelectAnswerV1Model(
        singleSelectResult.answer.code.code,
        singleSelectResult.answer.answer,
      ),
      percentage: getHundredthDecimalRoundedPercentageString(
        singleSelectResult.commonResultBreakdownDetails.percentage,
      ),
      count: singleSelectResult.commonResultBreakdownDetails.count.count,
    });
  });
};
