import { Badge, Stack } from "@mui/material";
import { SectionHeader } from "../../../components/SectionHeader";
import {
  Spacing,
  Button,
  Shadow,
  FoundationColorTokens,
  CornerRadius,
  Typography as LeoTypography,
  Breadcrumb,
} from "@surya-digital/leo-reactjs-material-ui";
import { ChevronDown, ChevronUp, FilePieChart } from "lucide-react";
import React, { useState } from "react";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import { SurveyViewDetailsStore } from "../stores/SurveyViewDetailsStore";
import {
  getRespondentFilterList,
  mapRespondentFiltersToFilterComponentFilters,
} from "../../../components/filter/utils/getRespondentFilterList";
import { DashboardFilterComponent } from "./DashboardFilterComponent";
import {
  BorderStyle,
  EMPTY_CHARACTER,
  ZERO_VALUE,
} from "@pulse/shared-components";
import {
  NavigateToFunctions,
  processSurveyParams,
  processSurveyViewParams,
} from "../../../routes/RoutesHelper";
import { FeatureInDevelopmentSnackbar } from "@pulse/shared-components";
import { FilterStore } from "../../../components/filter/store/FilterStore";

export const ActionElement = observer(
  ({
    spacing,
    isFilterContainerVisible,
    setIsFilterContainerVisible,
    t,
    filterStore,
    tokens,
  }: {
    spacing: Spacing;
    isFilterContainerVisible: boolean;
    setIsFilterContainerVisible: (isFilterContainerVisible: boolean) => void;
    t: TFunction;
    filterStore: Instance<typeof FilterStore>;
    tokens: FoundationColorTokens<string>;
  }): React.ReactElement => {
    const [
      isFeatureInDevelopmentSnackbarVisible,
      setIsFeatureInDevelopmentSnackbarVisible,
    ] = useState(false);

    const ManageFiltersButton = (): React.ReactElement => {
      return (
        <Button
          name="manageFilters"
          variant={
            filterStore.areNoFiltersApplied
              ? "outlined-neutral"
              : "outlined-color"
          }
          size="small"
          label={
            filterStore.areNoFiltersApplied
              ? t("filters.addFilter")
              : t("surveyDashboard.manageFilters")
          }
          onClick={(): void => {
            setIsFilterContainerVisible(!isFilterContainerVisible);
          }}
          icon={isFilterContainerVisible ? <ChevronUp /> : <ChevronDown />}
          iconPosition="trailing"
        />
      );
    };

    return (
      <Stack spacing={spacing.spaceSM} direction="row">
        <Button
          name="downloadPDF"
          variant="outlined-neutral"
          size="small"
          label={t("surveyDashboard.downloadPDF")}
          onClick={(): void => {
            setIsFeatureInDevelopmentSnackbarVisible(true);
          }}
        />
        {filterStore.areNoFiltersApplied ? (
          <ManageFiltersButton />
        ) : (
          <Badge
            variant="dot"
            color="secondary"
            badgeContent={EMPTY_CHARACTER}
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: tokens.backgroundPrimary,
              },
            }}
          >
            <ManageFiltersButton />
          </Badge>
        )}
        <FeatureInDevelopmentSnackbar
          isSnackbarVisible={isFeatureInDevelopmentSnackbarVisible}
          setIsSnackbarVisible={setIsFeatureInDevelopmentSnackbarVisible}
        />
      </Stack>
    );
  },
);

interface QuestionViewSectionHeaderProps {
  spacing: Spacing;
  t: TFunction;
  shadow: Shadow;
  surveyViewDetailsStore: Instance<typeof SurveyViewDetailsStore>;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  border: BorderStyle;
  borderRadius: CornerRadius;
  navigateTo: NavigateToFunctions;
  questionViewsSectionComponentRef: React.RefObject<HTMLDivElement>;
}

export const QuestionViewSectionHeader = observer(
  ({
    spacing,
    shadow,
    t,
    surveyViewDetailsStore,
    tokens,
    typography,
    border,
    borderRadius,
    navigateTo,
    questionViewsSectionComponentRef,
  }: QuestionViewSectionHeaderProps): React.ReactElement => {
    const [isFilterContainerVisible, setIsFilterContainerVisible] =
      React.useState(false);

    const getSurveyViewResults = async (): Promise<void> => {
      surveyViewDetailsStore.resetCurrentQuestionViewIds();
      surveyViewDetailsStore.resetIsLastElementReached();
      questionViewsSectionComponentRef.current?.scrollTo({
        top: ZERO_VALUE,
        behavior: "smooth",
      });
      await processSurveyViewParams(
        async (surveyViewId: string, surveyId: string, projectId: string) => {
          surveyViewDetailsStore.getPaginatedSurveyViewResults(
            surveyViewId,
            surveyId,
            projectId,
            false,
          );
        },
        navigateTo,
      );
    };

    return (
      <Stack
        position="sticky"
        top={0}
        width="100%"
        zIndex={1}
        bgcolor={tokens.backgroundElevatedLevel1}
      >
        <SectionHeader
          padding={`${spacing.spaceMD} ${spacing.space2XL}`}
          actionElement={
            <ActionElement
              spacing={spacing}
              isFilterContainerVisible={isFilterContainerVisible}
              setIsFilterContainerVisible={setIsFilterContainerVisible}
              t={t}
              filterStore={surveyViewDetailsStore.filterStore}
              tokens={tokens}
            />
          }
          titleActionElement={
            <Breadcrumb
              links={[
                {
                  label: t("surveyDashboard.views.viewsStringLiteral"),
                  icon: <FilePieChart />,
                  onLabelClick: () =>
                    processSurveyParams(
                      async (surveyId, projectId): Promise<void> => {
                        navigateTo.report(projectId, surveyId);
                      },
                      navigateTo,
                    ),
                },
              ]}
              currentLabel={
                surveyViewDetailsStore.surveyViewName ?? EMPTY_CHARACTER
              }
            />
          }
        />
        {isFilterContainerVisible && (
          <Stack
            padding={`${spacing.spaceMD} ${spacing.space2XL}`}
            width="100%"
            height="100%"
            boxShadow={shadow.level3}
            position="absolute"
            top="65px"
            bgcolor={tokens.backgroundElevatedLevel1}
          >
            <DashboardFilterComponent
              filters={mapRespondentFiltersToFilterComponentFilters(
                getRespondentFilterList({
                  t,
                  isSurveyFilterShown: false,
                  respondentColumnDetails:
                    surveyViewDetailsStore.respondentColumns,
                }),
                [],
                t,
                false,
              )}
              isDisabled={false}
              onApplyFiltersClick={getSurveyViewResults}
              onRemoveFiltersClick={getSurveyViewResults}
              store={surveyViewDetailsStore.filterStore}
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              border={border}
              borderRadius={borderRadius}
              shadow={shadow}
              t={t}
            />
          </Stack>
        )}
      </Stack>
    );
  },
);
