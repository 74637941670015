import { SampleBreakdown } from "@pulse/pulse-rpcs";
import { Instance, types } from "mobx-state-tree";

export const SampleBreakdownModel = types.model("SampleBreakdownModel", {
  sample: types.number,
  skipped: types.number,
  total: types.maybeNull(types.number),
});

export const createSampleBreakdownModel = (
  sampleBreakdown: SampleBreakdown,
): Instance<typeof SampleBreakdownModel> => {
  return SampleBreakdownModel.create({
    sample: sampleBreakdown.sample,
    skipped: sampleBreakdown.skipped,
    total: sampleBreakdown.total,
  });
};
