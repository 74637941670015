import {
  FoundationColorTokens,
  Typography as LeoTypography,
  Spacing,
  TableReloadHandle,
} from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import React from "react";
import { NestedTypographyDestructiveComponent } from "../NestedTypographyDestructiveComponent";
import { TFunction } from "i18next";
import { Dialog, TextInputField } from "@surya-digital/leo-reactjs-material-ui";
import { Instance } from "mobx-state-tree";
import {
  ArchiveProjectRPCErrors,
  ArchiveProjectValidationErrors,
  ProjectsRootStore,
} from "../../store/ProjectsRootStore";
import { Stack, Typography } from "@mui/material";
import { DialogErrorContent } from "../../../../components/DialogErrorContent";
import { getFullySpacedString } from "../../utils/StringUtils";

export interface ArchiveProjectDialogProps {
  isArchiveProjectDialogOpen: boolean;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  t: TFunction;
  projectRootStore: Instance<typeof ProjectsRootStore>;
  spacing: Spacing;
  setIsArchiveProjectDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  tableRef: React.MutableRefObject<TableReloadHandle | null> | undefined;
}

export const ArchiveProjectDialog = observer(
  ({
    isArchiveProjectDialogOpen,
    tokens,
    typography,
    t,
    projectRootStore,
    spacing,
    setIsArchiveProjectDialogOpen,
    tableRef,
  }: ArchiveProjectDialogProps): React.ReactElement => {
    const getProjectNameTextFieldHelperText = (): string | undefined => {
      switch (projectRootStore.archiveProjectValidationErrors) {
        case ArchiveProjectValidationErrors.InvalidProjectName:
          return t(
            "projects.archiveProjectDialog.incorrectProjectNameErrorLabel",
          );
      }
    };

    const getDialogErrorText = (): string => {
      switch (projectRootStore.rpcErrors) {
        case ArchiveProjectRPCErrors.ProjectAlreadyArchived: {
          return t(
            "projects.archiveProjectDialog.projectAlreadyArchivedErrorText",
          );
        }
        default: {
          return t("projects.archiveProjectDialog.unexpectedErrorText");
        }
      }
    };

    const getDialogChild = (): React.ReactElement => {
      if (projectRootStore.doesStoreContainArchiveProjectErrors) {
        return (
          <DialogErrorContent
            spacing={spacing}
            tokens={tokens}
            typography={typography}
            errorText={getDialogErrorText()}
          />
        );
      } else {
        return (
          <Stack gap={spacing.spaceLG}>
            <NestedTypographyDestructiveComponent
              tokens={tokens}
              typography={typography}
              endText={t("projects.archiveProjectDialog.archiveInfoText")}
              startText={t("projects.archiveProjectDialog.areYouSureText")}
              middleBoldText={t(
                "projects.archiveProjectDialog.projectNamePlaceholder",
                {
                  projectName: getFullySpacedString(
                    projectRootStore.selectedProjectToArchive?.projectName,
                  ),
                },
              )}
              noteText={t("projects.archiveProjectDialog.archiveProjectNote")}
              spacing={spacing}
            />
            <Typography {...typography.b2} color={tokens.labelSubtle}>
              {t("projects.archiveProjectDialog.confirmArchiveText")}
            </Typography>
            <TextInputField
              name="projectName"
              value={projectRootStore.archivedProjectName}
              onTextChange={(value) => {
                projectRootStore.clearRpcErrors();
                projectRootStore.clearArchiveProjectValidationErrors();
                projectRootStore.setArchivedProjectName(value);
              }}
              label={t("projects.projectNameLabel")}
              type={"text"}
              placeholder={t("projects.enterProjectNamePlaceholder")}
              error={
                projectRootStore.doesStoreContainArchiveProjectValidationErrors
              }
              helperText={getProjectNameTextFieldHelperText()}
              disabled={projectRootStore.isRPCLoading}
              required={true}
            />
          </Stack>
        );
      }
    };

    return (
      <Dialog
        open={isArchiveProjectDialogOpen}
        width="560px"
        title={t("projects.archiveProjectTitle")}
        contentPadding={spacing.spaceLG}
        primaryButtonText={
          projectRootStore.doesStoreContainArchiveProjectErrors
            ? undefined
            : t("projects.archiveProjectTitle")
        }
        onPrimaryButtonClick={async (): Promise<void> => {
          if (
            projectRootStore.isArchivedProjectNameValid() &&
            !projectRootStore.doesStoreContainArchiveProjectErrors
          ) {
            await projectRootStore.archiveProject();
          }
          if (
            !projectRootStore.doesStoreContainArchiveProjectErrors &&
            !projectRootStore.doesStoreContainArchiveProjectValidationErrors
          ) {
            projectRootStore.clearArchiveProjectDetails();
            tableRef?.current?.reload();
            setIsArchiveProjectDialogOpen(false);
          }
        }}
        isPrimaryButtonDisabled={
          projectRootStore.isArchiveProjectButtonDisabled
        }
        secondaryButtonText={t("common.cancel")}
        onSecondaryButtonClick={(): void => {
          if (projectRootStore.rpcErrors) {
            tableRef?.current?.reload();
          }
          projectRootStore.clearArchiveProjectDetails();
          setIsArchiveProjectDialogOpen(false);
        }}
        isSecondaryButtonDisabled={projectRootStore.isRPCLoading}
        disableBackdropClick={true}
        primaryButtonColor="destructive"
      >
        {/*
        getDialogChild() is not called like <DialogChild />.
        This is done to avoid giving it its own lifecycle which will result in loss of focus from <TextInputField /> on store updation.
        */}
        {getDialogChild()}
      </Dialog>
    );
  },
);
