import {
  GridResultV1,
  GridResultV1Enums,
  QuestionViewTypeV1,
} from "@pulse/pulse-rpcs";
import { Instance, types } from "mobx-state-tree";
import {
  createSampleBreakdownModel,
  SampleBreakdownModel,
} from "./SampleBreakdownModel";
import {
  createGridSingleSelectModel,
  GridSingleSelectModel,
} from "./GridSingleSelectModel";
import {
  createGridSingleSelectWithMeanModel,
  GridSingleSelectWithMeanModel,
} from "./GridSingleSelectWithMeanModel";
import {
  createGridOpenEndedModel,
  GridOpenEndedModel,
} from "./GridOpenEndedModel";
import {
  createGridMultiSelectModel,
  GridMultiSelectModel,
} from "./GridMultiSelectModel";

export enum GridQuestionViewTypeV1Enum {
  GRID_SINGLE_SELECT_MODEL = "GridSingleSelectModel",
  GRID_SINGLE_SELECT_PROPORTION_MODEL = "GridSingleSelectWithMeanModel",
  GRID_OPEN_ENDED_MODEL = "GridOpenEndedModel",
  GRID_MULTI_SELECT_MODEL = "GridMultiSelectModel",
}

const GridResultV1Model = types.model("GridResultV1Model", {
  columnCode: types.string,
  columnHeader: types.maybeNull(types.string),
  columnQuestionType: types.union(
    GridSingleSelectModel,
    GridSingleSelectWithMeanModel,
    GridOpenEndedModel,
    GridMultiSelectModel,
  ),
});

const createGridResultV1Model = (
  gridResult: GridResultV1,
): Instance<typeof GridResultV1Model> => {
  const getColumnQuestionType = (): Instance<
    | typeof GridSingleSelectModel
    | typeof GridSingleSelectWithMeanModel
    | typeof GridOpenEndedModel
    | typeof GridMultiSelectModel
  > => {
    if (
      gridResult.columnQuestionType instanceof
      GridResultV1Enums.ColumnQuestionType.SingleSelect
    ) {
      return createGridSingleSelectModel(
        gridResult.columnQuestionType,
        gridResult.columnHeader?.text,
      );
    } else if (
      gridResult.columnQuestionType instanceof
      GridResultV1Enums.ColumnQuestionType.SingleSelectWithProportion
    ) {
      return createGridSingleSelectWithMeanModel(
        gridResult.columnQuestionType,
        gridResult.columnHeader?.text,
      );
    } else if (
      gridResult.columnQuestionType instanceof
      GridResultV1Enums.ColumnQuestionType.OpenEnded
    ) {
      return createGridOpenEndedModel(
        gridResult.columnQuestionType,
        gridResult.columnHeader?.text,
      );
    } else {
      return createGridMultiSelectModel(
        gridResult.columnQuestionType,
        gridResult.columnHeader?.text,
      );
    }
  };

  return GridResultV1Model.create({
    columnCode: gridResult.columnCode.code,
    columnHeader: gridResult.columnHeader?.text,
    columnQuestionType: getColumnQuestionType(),
  });
};

export const GridQuestionViewV1Model = types
  .model("GridQuestionViewV1Model", {
    question: types.maybeNull(types.string),
    surveyQuestionViewId: types.string,
    sampleBreakdown: SampleBreakdownModel,
    gridResults: types.array(GridResultV1Model),
  })
  .views((store) => ({
    get isGraphSelectionUpdated(): boolean {
      let isButtonEnabled = false;
      store.gridResults.map((gridResult) => {
        if (
          !isButtonEnabled &&
          gridResult.columnQuestionType.isGraphSelectionUpdated
        ) {
          isButtonEnabled =
            gridResult.columnQuestionType.isGraphSelectionUpdated;
        }
      });
      return isButtonEnabled;
    },
  }));

export const createGridQuestionViewV1Model = (
  gridQuestion: QuestionViewTypeV1.Grid,
  surveyQuestionViewId: string,
  question: string | null = null,
): Instance<typeof GridQuestionViewV1Model> => {
  return GridQuestionViewV1Model.create({
    question,
    surveyQuestionViewId,
    sampleBreakdown: createSampleBreakdownModel(gridQuestion.sampleBreakdown),
    gridResults: gridQuestion.gridResults.map((gridResult) =>
      createGridResultV1Model(gridResult),
    ),
  });
};
