import { BorderStyle, EMPTY_CHARACTER } from "@pulse/shared-components";
import {
  CornerRadius,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import React, { ReactElement } from "react";
import { observer } from "mobx-react";
import { Stack } from "@mui/material";
import { GridSelectedGraph } from "./GridSelectedGraph";
import {
  DataProps,
  DataPropsForGridStackedAndGroupedBar,
  DataSection,
} from "../DataSection";
import { HeaderQuestionDetails } from "../HeaderQuestionDetails";
import { SurveyQuestionViewStoreNames } from "../../stores/SurveyViewDetailsStore";
import { GridSingleSelectGraphTypeV1 } from "@pulse/pulse-rpcs";
import { GridSingleSelectWithMeanModel } from "../../models/GridSingleSelectWithMeanModel";
import { ChartSelection, QuestionTypeGraphs } from "../ChartSelection";

interface GridSingleSelectQuestionViewProps {
  typography: Typography;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  spacing: Spacing;
  border: BorderStyle;
  cornerRadius: CornerRadius;
  singleSelectQuestionWithMeanView: Instance<
    typeof GridSingleSelectWithMeanModel
  >;
  isChildQuestion: boolean;
}

export const GridSingleSelectQuestionWithMeanView = observer(
  ({
    spacing,
    t,
    tokens,
    typography,
    border,
    cornerRadius,
    singleSelectQuestionWithMeanView,
    isChildQuestion,
  }: GridSingleSelectQuestionViewProps): ReactElement => {
    const gridSingleSelectOptions: Set<string> = new Set();
    const gridSingleSelectRowOptions: Set<string> = new Set();
    let isNeutral = true;
    const dataForGridBar =
      singleSelectQuestionWithMeanView.gridSingleSelectRowResults.map(
        (gridSingleSelectRowResult): DataPropsForGridStackedAndGroupedBar => {
          gridSingleSelectRowOptions.add(
            gridSingleSelectRowResult.option ?? EMPTY_CHARACTER,
          );
          return {
            option: gridSingleSelectRowResult.option,
            answerCounts: gridSingleSelectRowResult.singleSelectResults.map(
              (singleSelectData) => {
                gridSingleSelectOptions.add(
                  singleSelectData.answer.answer ?? EMPTY_CHARACTER,
                );
                return {
                  count: singleSelectData.count,
                  answer: singleSelectData.answer.answer ?? EMPTY_CHARACTER,
                };
              },
            ),
            id: gridSingleSelectRowResult.code,
            rowOptionText: gridSingleSelectRowResult.option ?? EMPTY_CHARACTER,
          };
        },
      );
    const getData = ():
      | DataProps[][]
      | DataPropsForGridStackedAndGroupedBar[] => {
      switch (singleSelectQuestionWithMeanView.updatedGraphType) {
        case GridSingleSelectGraphTypeV1.GridSingleSelectGraphTypeV1.NONE:
        case GridSingleSelectGraphTypeV1.GridSingleSelectGraphTypeV1
          .PROPORTION: {
          return singleSelectQuestionWithMeanView.gridSingleSelectRowResults.map(
            (gridSingleSelectRowResult): DataProps[] => {
              if (gridSingleSelectRowResult.mean !== null) {
                isNeutral = false;
              }
              return gridSingleSelectRowResult.singleSelectResults.map(
                (singleSelectResult): DataProps => {
                  return {
                    option: singleSelectResult.answer.answer,
                    count: singleSelectResult.count,
                    percentage: singleSelectResult.percentage,
                    id: singleSelectResult.answer.code,
                    mean: gridSingleSelectRowResult.mean,
                  };
                },
              );
            },
          );
        }
        case GridSingleSelectGraphTypeV1.GridSingleSelectGraphTypeV1
          .GROUPED_BAR:
        case GridSingleSelectGraphTypeV1.GridSingleSelectGraphTypeV1
          .STACKED_COLUMN: {
          return dataForGridBar;
        }
      }
    };

    return (
      <Stack
        width="100%"
        alignItems="center"
        borderRadius={cornerRadius.radiusXS}
        gap={spacing.spaceXL}
        paddingBottom={spacing.space2XL}
      >
        <Stack
          padding={spacing.spaceSM}
          width="100%"
          borderBottom={border.default}
        >
          <Stack direction="row">
            <HeaderQuestionDetails
              questionText={singleSelectQuestionWithMeanView.columnHeader}
              sampleBreakdown={undefined}
              typography={typography}
              tokens={tokens}
              t={t}
              spacing={spacing}
              surveyQuestionViewType={
                SurveyQuestionViewStoreNames.SINGLE_SELECT_QUESTION_WITH_PROPORTION_MODEL
              }
              isQuestionTypeIconVisible={false}
            />
            <ChartSelection
              border={border}
              cornerRadius={cornerRadius}
              spacing={spacing}
              surveyQuestionViewType={
                SurveyQuestionViewStoreNames.GRID_SINGLE_SELECT_PROPORTION_MODEL
              }
              selectedGraph={
                QuestionTypeGraphs[
                  singleSelectQuestionWithMeanView.updatedGraphType
                ]
              }
              onChartIconClick={
                singleSelectQuestionWithMeanView.updateSelectedGraphType
              }
              tokens={tokens}
            />
          </Stack>
        </Stack>
        <Stack padding={spacing.space2XL} gap={spacing.spaceXL} width="100%">
          <GridSelectedGraph
            selectedGraph={
              QuestionTypeGraphs[
                singleSelectQuestionWithMeanView.updatedGraphType
              ]
            }
            data={getData()}
            isNeutral={isNeutral}
            cornerRadius={cornerRadius}
            tokens={tokens}
            spacing={spacing}
            typography={typography}
            border={border}
            gridSingleSelectRowOptions={[...gridSingleSelectRowOptions]}
            t={t}
            isChildQuestion={isChildQuestion}
            naOptionCode={singleSelectQuestionWithMeanView.naOptionCode}
          />
          <DataSection
            spacing={spacing}
            t={t}
            isNPS={false}
            typography={typography}
            tokens={tokens}
            cornerRadius={cornerRadius}
            total={null}
            data={dataForGridBar}
            border={border}
            isColoredSwatchRequired={false}
            customOptionHeaders={[...gridSingleSelectOptions]}
          />
        </Stack>
      </Stack>
    );
  },
);
