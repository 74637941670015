import { observer } from "mobx-react";
import React, { ReactElement } from "react";
import { SPoCChartCustomisations } from "../stores/AddViewStore";
import { TFunction } from "i18next";
import {
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioProps,
  Stack,
} from "@mui/material";
import { BorderStyle } from "@pulse/shared-components";
import { RadioLabelTypography, Sh3LabelTypography } from "./AddViewDialogChild";

interface SPoCsChartCustomisationProps {
  t: TFunction;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  radioProps: RadioProps;
  border: BorderStyle;
  isRPCLoading: boolean;
  spocChartCustomisation: SPoCChartCustomisations;
  setSpoCChartCustomisation: (
    spocChartCustomisation: SPoCChartCustomisations,
  ) => void;
}

export const SPoCsChartCustomisation = observer(
  ({
    spacing,
    t,
    tokens,
    typography,
    radioProps,
    border,
    isRPCLoading,
    setSpoCChartCustomisation,
    spocChartCustomisation,
  }: SPoCsChartCustomisationProps): ReactElement => {
    return (
      <Stack gap={spacing.spaceMD} width="100%">
        <Sh3LabelTypography
          text={t("surveyDashboard.addViewDialog.spocsChartCustomisation")}
          tokens={tokens}
          typography={typography}
          border={border}
          spacing={spacing}
        />
        <RadioGroup
          value={spocChartCustomisation}
          onChange={(_, value) => {
            switch (value) {
              case SPoCChartCustomisations.AllowSPoCToChangeGraphType:
                setSpoCChartCustomisation(
                  SPoCChartCustomisations.AllowSPoCToChangeGraphType,
                );
                break;
              case SPoCChartCustomisations.RestrictToSelectType:
                setSpoCChartCustomisation(
                  SPoCChartCustomisations.RestrictToSelectType,
                );
                break;
              default: {
                console.error(
                  `The value ${value} is not a valid SPoCChartCustomisation.`,
                );
              }
            }
          }}
          sx={{
            gap: spacing.spaceSM,
          }}
        >
          <FormControlLabel
            control={<Radio disabled={isRPCLoading} {...radioProps} />}
            value={SPoCChartCustomisations.AllowSPoCToChangeGraphType}
            label={
              <RadioLabelTypography
                title={t(
                  "surveyDashboard.addViewDialog.allowSPoCToChangeGraphType",
                )}
                subTitle={t(
                  "surveyDashboard.addViewDialog.spocCanSelectAnyAvailableGraphType",
                )}
                tokens={tokens}
                typography={typography}
              />
            }
          />
          <FormControlLabel
            control={<Radio disabled={isRPCLoading} {...radioProps} />}
            value={SPoCChartCustomisations.RestrictToSelectType}
            label={
              <RadioLabelTypography
                title={t("surveyDashboard.addViewDialog.restrictToSelectType")}
                subTitle={t(
                  "surveyDashboard.addViewDialog.spocCannotChangeTheGraphType",
                )}
                tokens={tokens}
                typography={typography}
              />
            }
          />
        </RadioGroup>
      </Stack>
    );
  },
);
