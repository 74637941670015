import { Button, Stack, Tooltip, Typography } from "@mui/material";
import { BorderStyle, ICON_SIZE, getIconProps } from "@pulse/shared-components";
import { FilterIcon, InfoIcon } from "lucide-react";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React from "react";
import { FilterStore } from "../store/FilterStore";
import { TFunction } from "i18next";
import {
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
  CornerRadius,
} from "@surya-digital/leo-reactjs-material-ui";

export interface AddFilterButtonProps {
  store: Instance<typeof FilterStore>;
  t: TFunction;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  spacing: Spacing;
  borderRadius: CornerRadius;
  border: BorderStyle;
  maxFilterLimit: number | undefined;
  handleFilterButtonClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  currentAppliedFiltersCount: number;
  isDisabled: boolean;
  size?: "small" | "medium";
}

export const AddFilterButton = observer(
  ({
    store,
    t,
    typography,
    tokens,
    spacing,
    borderRadius,
    border,
    maxFilterLimit,
    handleFilterButtonClick,
    currentAppliedFiltersCount,
    isDisabled,
    size = "medium",
  }: AddFilterButtonProps): React.ReactElement => {
    const iconColor = isDisabled ? tokens.iconLowEmphasis : tokens.icon;
    const buttonTextColor = isDisabled ? tokens.labelLowEmphasis : tokens.label;

    return (
      <Stack direction="row" gap={spacing.spaceSM} alignItems="center">
        <Button
          disableElevation
          sx={{
            height: size === "small" ? "32px" : "40px",
            borderRadius: borderRadius.radiusXS,
            padding: `${spacing.spaceXS} ${spacing.spaceMD}`,
            border: border.default,
            outline: "outline-width",
            color: tokens.label,
            gap: spacing.spaceXXS,
            position: "relative",
            "&:hover": {
              background: tokens.backgroundHovered,
              border: border.default,
            },
            "&& .MuiTouchRipple-child": {
              background: tokens.backgroundHovered,
            },
          }}
          variant={"outlined"}
          onClick={handleFilterButtonClick}
          disabled={isDisabled}
        >
          <FilterIcon
            {...getIconProps(
              iconColor,
              size === "small" ? ICON_SIZE.small : ICON_SIZE.default,
            )}
          />
          <Typography
            sx={{
              textTransform: "none",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              typography: size === "small" ? typography.bt3 : typography.bt2,
              marginRight: spacing.spaceXXS,
              color: buttonTextColor,
            }}
          >
            {t("filters.addFilter")}
          </Typography>
        </Button>
        <Typography
          sx={{
            ...typography.b2,
            color: tokens.labelSubtle,
          }}
        >
          {currentAppliedFiltersCount === 0
            ? t("filters.noFiltersAppliedText")
            : t("filters.filtersAppliedText", {
                numberOfFilters: currentAppliedFiltersCount,
              })}
        </Typography>
        {store.isMaxFilterLimitReached(maxFilterLimit) && (
          <Stack
            gap={spacing.spaceSM}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Stack
              bgcolor={tokens.backgroundWarningSubtle}
              borderRadius={borderRadius.radiusXXS}
              padding={`${spacing.spaceXXS} ${spacing.spaceXS}`}
            >
              <Typography
                sx={{
                  ...typography.b2,
                  color: tokens.labelWarning,
                }}
              >
                {t("filters.maxLimitReachedText")}
              </Typography>
            </Stack>
            <Tooltip
              title={t("filters.maxFiltersAppliedText", {
                maxFilterLimit,
              })}
            >
              <InfoIcon
                {...getIconProps(
                  tokens.iconLowEmphasis,
                  size === "small" ? ICON_SIZE.small : ICON_SIZE.default,
                )}
              />
            </Tooltip>
          </Stack>
        )}
      </Stack>
    );
  },
);
