import { Instance, types } from "mobx-state-tree";
import { SurveyTheme, ThemeWithHexCodes } from "@pulse/pulse-rpcs";

export const ThemeWithHexCodesModel = types.model("ThemeWithHexCodesModel", {
  theme: types.enumeration(Object.values(SurveyTheme.SurveyTheme)),
  primaryHexCode: types.string,
});

export const createThemeWithHexCodesModel = (
  themeWithHexCodes: ThemeWithHexCodes,
): Instance<typeof ThemeWithHexCodesModel> => {
  return ThemeWithHexCodesModel.create({
    theme: themeWithHexCodes.theme,
    primaryHexCode: themeWithHexCodes.primaryHexCode.code,
  });
};
