import { Box, Stack } from "@mui/material";
import {
  RICH_TEXT_MARKDOWN_PROPS,
  richTextTypography,
} from "@pulse/shared-components";
import { CornerRadius, Spacing } from "@surya-digital/leo-reactjs-material-ui";
import { RichTextEditor } from "@surya-digital/leo-reactjs-remirror";
import React, { ReactElement } from "react";
import { QUESTION_VIEW_MAX_WIDTH_IN_PIXELS } from "../../../utils/constants";

interface LegendsProps {
  legends: string[];
  spacing: Spacing;
  cornerRadius: CornerRadius;
  colors: string[];
}

export const Legends = ({
  legends,
  spacing,
  cornerRadius,
  colors,
}: LegendsProps): ReactElement => {
  return (
    <Stack
      direction="row"
      gap={spacing.spaceMD}
      maxWidth={`${QUESTION_VIEW_MAX_WIDTH_IN_PIXELS}px`}
      flexWrap="wrap"
    >
      {legends.map((legend, index) => {
        return (
          <Stack
            key={index}
            direction="row"
            gap={spacing.spaceXXS}
            justifyContent="center"
            sx={{
              "& .ProseMirror": {
                width: "fit-content",
              },
              flexBasis: 0,
              flexGrow: 1,
            }}
          >
            <Box
              height="12px"
              minWidth="12px"
              borderRadius={cornerRadius.radiusMax}
              sx={{
                backgroundColor: colors[index % colors.length],
              }}
              marginTop={spacing.spaceXXS}
            />
            <RichTextEditor
              name={legend}
              typography={richTextTypography()}
              mode="preview"
              borderOnPreview={false}
              initialValue={legend}
              supports={RICH_TEXT_MARKDOWN_PROPS}
            />
          </Stack>
        );
      })}
    </Stack>
  );
};
