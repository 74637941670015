import { RadioProps, Stack } from "@mui/material";
import { Instance } from "mobx-state-tree";
import React, { ReactElement } from "react";
import {
  EditViewRPCError,
  SurveyDashboardViewModel,
} from "../models/SurveyDashboardViewModel";
import {
  FoundationColorTokens,
  Spacing,
  TextInputField,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import {
  BorderStyle,
  SURVEY_VIEW_NAME_VALIDATIONS,
} from "@pulse/shared-components";
import { SPoCsChartCustomisation } from "./SPoCsChartCustomisation";
import { observer } from "mobx-react";

interface EditSurveyViewDialogChildProps {
  surveyDashboardView: Instance<typeof SurveyDashboardViewModel>;
  t: TFunction;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  radioProps: RadioProps;
  border: BorderStyle;
}

export const EditSurveyViewDialogChild = observer(
  ({
    surveyDashboardView,
    t,
    border,
    spacing,
    tokens,
    typography,
    radioProps,
  }: EditSurveyViewDialogChildProps): ReactElement => {
    const getViewNameTextFieldHelperText = (): string | undefined => {
      switch (surveyDashboardView.rpcError) {
        case EditViewRPCError.InvalidViewName:
          return t(
            "surveyDashboard.addViewDialog.viewNameHelperText.invalidViewName",
            {
              allowedSpecialCharacters:
                SURVEY_VIEW_NAME_VALIDATIONS.allowedSpecialCharacters,
            },
          );
        case EditViewRPCError.InvalidViewNameLength:
          return t(
            "surveyDashboard.addViewDialog.viewNameHelperText.invalidViewNameLength",
            {
              minLength: SURVEY_VIEW_NAME_VALIDATIONS.minLength,
              maxLength: SURVEY_VIEW_NAME_VALIDATIONS.maxLength,
            },
          );
        case EditViewRPCError.SurveyViewAlreadyExists:
          return t(
            "surveyDashboard.addViewDialog.viewNameHelperText.viewNameNotUnique",
          );
      }
    };

    return (
      <Stack width="100%">
        <TextInputField
          name="viewName"
          value={surveyDashboardView.updatedSurveyViewName}
          type="text"
          onTextChange={surveyDashboardView.setUpdatedSurveyViewName}
          placeholder={t("surveyDashboard.addViewDialog.enterViewName")}
          label={t("surveyDashboard.addViewDialog.viewName")}
          helperText={getViewNameTextFieldHelperText()}
          error={surveyDashboardView.doesStoreContainViewNameError}
          disabled={surveyDashboardView.isRPCLoading}
          required={true}
        />
        <SPoCsChartCustomisation
          t={t}
          spacing={spacing}
          tokens={tokens}
          typography={typography}
          radioProps={radioProps}
          border={border}
          isRPCLoading={surveyDashboardView.isRPCLoading}
          spocChartCustomisation={
            surveyDashboardView.updatedSPoCChartCustomisation
          }
          setSpoCChartCustomisation={
            surveyDashboardView.setUpdatedSpoCChartCustomisation
          }
        />
      </Stack>
    );
  },
);
