import { OpenEndedGraphTypeV1, QuestionViewTypeV1 } from "@pulse/pulse-rpcs";
import { Instance, types } from "mobx-state-tree";
import {
  createSampleBreakdownModel,
  SampleBreakdownModel,
} from "./SampleBreakdownModel";
import { QuestionTypeGraphs } from "../components/ChartSelection";
import {
  OpenEndedResultV1Model,
  createOpenEndedResultsV1Model,
} from "./OpenEndedResultV1Model";
import { DataProps } from "../components/DataSection";
import { TOP_N_OPEN_ENDED_WORDS } from "../../utils/constants";

export const OpenEndedQuestionViewModelV1 = types
  .model("OpenEndedQuestionViewModelV1", {
    sampleBreakdown: SampleBreakdownModel,
    openEndedGraphType: types.enumeration(
      "OpenEndedGraphTypeV1",
      Object.values(OpenEndedGraphTypeV1.OpenEndedGraphTypeV1),
    ),
    updatedOpenEndedGraphType: types.enumeration(
      "OpenEndedGraphTypeV1",
      Object.values(OpenEndedGraphTypeV1.OpenEndedGraphTypeV1),
    ),
    openEndedV1Results: types.array(OpenEndedResultV1Model),
    question: types.maybeNull(types.string),
    topNWords: types.optional(types.number, TOP_N_OPEN_ENDED_WORDS[0]),
  })
  .views((store) => ({
    get isGraphSelectionUpdated(): boolean {
      return store.updatedOpenEndedGraphType !== store.openEndedGraphType;
    },
    get openEndedResultData(): DataProps[] {
      return store.openEndedV1Results
        .map((openEndedResult): DataProps => {
          return {
            option: openEndedResult.answer,
            count: openEndedResult.count,
            percentage: openEndedResult.percentage,
            id: openEndedResult.answer,
          };
        })
        .slice(0, store.topNWords);
    },
  }))
  .actions((store) => ({
    updateSelectedGraphType: (
      updatedOpenEndedGraphType: QuestionTypeGraphs,
    ): void => {
      store.updatedOpenEndedGraphType =
        OpenEndedGraphTypeV1.OpenEndedGraphTypeV1[
          updatedOpenEndedGraphType as keyof typeof OpenEndedGraphTypeV1.OpenEndedGraphTypeV1
        ];
    },
    setTopNWords: (topNWords: number): void => {
      store.topNWords = topNWords;
    },
  }));

export const createOpenEndedQuestionViewModelV1 = (
  openEndedQuestionView: QuestionViewTypeV1.OpenEnded,
  question: string | null = null,
): Instance<typeof OpenEndedQuestionViewModelV1> => {
  return OpenEndedQuestionViewModelV1.create({
    sampleBreakdown: createSampleBreakdownModel(
      openEndedQuestionView.sampleBreakdown,
    ),
    openEndedGraphType: openEndedQuestionView.openEndedGraphType,
    updatedOpenEndedGraphType: openEndedQuestionView.openEndedGraphType,
    openEndedV1Results: createOpenEndedResultsV1Model(
      openEndedQuestionView.openEndedResults,
    ),
    question,
  });
};
