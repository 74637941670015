import React, { ReactElement } from "react";
import { Instance } from "mobx-state-tree";
import {
  CornerRadius,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { BorderStyle, EMPTY_CHARACTER } from "@pulse/shared-components";
import { observer } from "mobx-react";
import { Stack } from "@mui/material";
import { HeaderQuestionDetails } from "../HeaderQuestionDetails";
import { SurveyQuestionViewStoreNames } from "../../stores/SurveyViewDetailsStore";
import {
  DataPropsForGridStackedAndGroupedBar,
  DataSection,
} from "../DataSection";
import { GridSelectedGraph } from "./GridSelectedGraph";
import { GridMultiSelectModel } from "../../models/GridMultiSelectModel";
import { ChartSelection, QuestionTypeGraphs } from "../ChartSelection";

interface GridMultiSelectQuestionViewProps {
  typography: Typography;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  spacing: Spacing;
  border: BorderStyle;
  cornerRadius: CornerRadius;
  multiSelectQuestionView: Instance<typeof GridMultiSelectModel>;
  isChildQuestion: boolean;
}

export const GridMultiSelectQuestionView = observer(
  ({
    spacing,
    t,
    tokens,
    typography,
    border,
    cornerRadius,
    multiSelectQuestionView,
    isChildQuestion,
  }: GridMultiSelectQuestionViewProps): ReactElement => {
    const gridMultiSelectOptions: Set<string> = new Set();
    const data: DataPropsForGridStackedAndGroupedBar[] =
      multiSelectQuestionView.multiSelectResults.map(
        (multiSelectResult): DataPropsForGridStackedAndGroupedBar => {
          return {
            option: multiSelectResult.option,
            answerCounts: multiSelectResult.multiSelectResults.map(
              (multiSelectData) => {
                gridMultiSelectOptions.add(multiSelectData.answer);
                return {
                  count: multiSelectData.count,
                  answer: multiSelectData.answer,
                };
              },
            ),
            id: multiSelectResult.code,
            rowOptionText: multiSelectResult.rowOptionText ?? EMPTY_CHARACTER,
          };
        },
      );
    return (
      <Stack
        width="100%"
        alignItems="center"
        borderRadius={cornerRadius.radiusXS}
        gap={spacing.spaceXL}
        paddingBottom={spacing.space2XL}
      >
        <Stack
          padding={spacing.spaceSM}
          width="100%"
          borderBottom={border.default}
        >
          <Stack direction="row">
            <HeaderQuestionDetails
              questionText={multiSelectQuestionView.columnHeader}
              sampleBreakdown={undefined}
              typography={typography}
              tokens={tokens}
              t={t}
              spacing={spacing}
              surveyQuestionViewType={
                SurveyQuestionViewStoreNames.MULTI_SELECT_QUESTION_MODEL
              }
              isQuestionTypeIconVisible={false}
            />
            <ChartSelection
              border={border}
              cornerRadius={cornerRadius}
              spacing={spacing}
              surveyQuestionViewType={
                SurveyQuestionViewStoreNames.GRID_MULTI_SELECT_MODEL
              }
              selectedGraph={
                QuestionTypeGraphs[multiSelectQuestionView.updatedGraphType]
              }
              onChartIconClick={multiSelectQuestionView.updateSelectedGraphType}
              tokens={tokens}
            />
          </Stack>
        </Stack>
        <Stack padding={spacing.space2XL} gap={spacing.spaceXL} width="100%">
          <GridSelectedGraph
            selectedGraph={
              QuestionTypeGraphs[multiSelectQuestionView.updatedGraphType]
            }
            data={data}
            isNeutral={true}
            cornerRadius={cornerRadius}
            tokens={tokens}
            spacing={spacing}
            typography={typography}
            border={border}
            gridSingleSelectRowOptions={undefined}
            t={t}
            isChildQuestion={isChildQuestion}
          />
          <DataSection
            spacing={spacing}
            t={t}
            isNPS={false}
            typography={typography}
            tokens={tokens}
            cornerRadius={cornerRadius}
            total={null}
            data={data as DataPropsForGridStackedAndGroupedBar[]}
            border={border}
            isColoredSwatchRequired={false}
            customOptionHeaders={[...gridMultiSelectOptions]}
          />
        </Stack>
      </Stack>
    );
  },
);
