import React, { ReactElement, useState } from "react";
import { Instance } from "mobx-state-tree";
import { NPSSingleSelectQuestionModelV1 } from "../models/NPSSingleSelectQuestionModelV1";
import {
  CornerRadius,
  FoundationColorTokens,
  Spacing,
  Shadow,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { BorderStyle } from "@pulse/shared-components";
import { SurveyQuestionViewStoreNames } from "../stores/SurveyViewDetailsStore";
import { QuestionView } from "./QuestionView";
import { QuestionTypeGraphs } from "./ChartSelection";
import { observer } from "mobx-react";

interface NPSSingleSelectQuestionViewProps {
  surveyQuestionView: Instance<typeof NPSSingleSelectQuestionModelV1>;
  typography: Typography;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  spacing: Spacing;
  border: BorderStyle;
  cornerRadius: CornerRadius;
  boxShadow: Shadow;
  isChildQuestion: boolean;
}

export const NPSSingleSelectQuestionView = observer(
  ({
    surveyQuestionView,
    spacing,
    t,
    tokens,
    typography,
    border,
    cornerRadius,
    boxShadow,
    isChildQuestion,
  }: NPSSingleSelectQuestionViewProps): ReactElement => {
    const [
      featureInDevelopmentSnackbarVisible,
      setFeatureInDevelopmentSnackbarVisible,
    ] = useState(false);
    return (
      <QuestionView
        t={t}
        spacing={spacing}
        typography={typography}
        tokens={tokens}
        cornerRadius={cornerRadius}
        border={border}
        isNPS={true}
        questionText={surveyQuestionView.question}
        sampleBreakdown={surveyQuestionView.sampleBreakdown}
        total={surveyQuestionView.sampleBreakdown.total}
        selectedGraph={
          QuestionTypeGraphs[surveyQuestionView.updatedSingleSelectNPSGraphType]
        }
        surveyQuestionViewType={
          SurveyQuestionViewStoreNames.NPS_SINGLE_SELECT_QUESTION_MODEL
        }
        updateSelectedGraphType={surveyQuestionView.updateSelectedGraphType}
        data={[
          {
            option: surveyQuestionView.detractors.answer,
            count: surveyQuestionView.detractors.count,
            percentage: surveyQuestionView.detractors.percentage,
            id: t("surveyDashboard.views.detractors", {
              startRange: surveyQuestionView.detractors.rangeStart,
              endRange: surveyQuestionView.detractors.rangeEnd,
            }),
          },
          {
            option: surveyQuestionView.passives.answer,
            count: surveyQuestionView.passives.count,
            percentage: surveyQuestionView.passives.percentage,
            id: t("surveyDashboard.views.passives", {
              startRange: surveyQuestionView.passives.rangeStart,
              endRange: surveyQuestionView.passives.rangeEnd,
            }),
          },
          {
            option: surveyQuestionView.promoters.answer,
            count: surveyQuestionView.promoters.count,
            percentage: surveyQuestionView.promoters.percentage,
            id: t("surveyDashboard.views.promoters", {
              startRange: surveyQuestionView.promoters.rangeStart,
              endRange: surveyQuestionView.promoters.rangeEnd,
            }),
          },
        ]}
        buttonProps={{
          onDownloadClick: () => {},
          onSaveChangeClick: () => {
            setFeatureInDevelopmentSnackbarVisible(true);
          },
          isDownloadDisabled: true,
          isSaveChangeDisabled: !surveyQuestionView.isGraphSelectionUpdated,
        }}
        featureInDevelopmentSnackbarVisible={
          featureInDevelopmentSnackbarVisible
        }
        setFeatureInDevelopmentSnackbarVisible={
          setFeatureInDevelopmentSnackbarVisible
        }
        boxShadow={boxShadow}
        isChildQuestion={isChildQuestion}
      />
    );
  },
);
