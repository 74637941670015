import {
  GridMultiSelectGraphTypeV1,
  GridMultiSelectResultV1,
  GridMultiSelectRowResultV1,
  GridResultV1Enums,
} from "@pulse/pulse-rpcs";
import { Instance, types } from "mobx-state-tree";
import { QuestionTypeGraphs } from "../components/ChartSelection";

const GridMultiSelectResultV1Model = types.model(
  "GridMultiSelectResultV1Model",
  {
    answer: types.string,
    count: types.number,
    code: types.string,
  },
);

const createGridMultiSelectResultV1Model = (
  gridMultiSelectResult: GridMultiSelectResultV1,
): Instance<typeof GridMultiSelectResultV1Model> => {
  return GridMultiSelectResultV1Model.create({
    code: gridMultiSelectResult.answer.code.code,
    count: gridMultiSelectResult.count.count,
    answer: gridMultiSelectResult.answer.answer,
  });
};

const GridMultiSelectRowResultV1Model = types.model(
  "GridMultiSelectRowResultV1Model",
  {
    code: types.string,
    option: types.maybeNull(types.string),
    multiSelectResults: types.array(GridMultiSelectResultV1Model),
    rowOptionText: types.maybeNull(types.string),
  },
);

const createMultiSelectRowResultV1Model = (
  multiSelectResultV1: GridMultiSelectRowResultV1,
  rowOptionText: string | null = null,
): Instance<typeof GridMultiSelectRowResultV1Model> => {
  return GridMultiSelectRowResultV1Model.create({
    code: multiSelectResultV1.gridOptionV1.code.code,
    option: multiSelectResultV1.gridOptionV1.option?.text,
    multiSelectResults: multiSelectResultV1.multiSelectResults.map(
      (multiSelectResult) =>
        createGridMultiSelectResultV1Model(multiSelectResult),
    ),
    rowOptionText,
  });
};

export const GridMultiSelectModel = types
  .model("GridMultiSelectModel", {
    selectedGraphType: types.enumeration(
      Object.values(GridMultiSelectGraphTypeV1.GridMultiSelectGraphTypeV1),
    ),
    updatedGraphType: types.enumeration(
      Object.values(GridMultiSelectGraphTypeV1.GridMultiSelectGraphTypeV1),
    ),
    multiSelectResults: types.array(GridMultiSelectRowResultV1Model),
    columnHeader: types.maybeNull(types.string),
  })
  .views((store) => ({
    get isGraphSelectionUpdated(): boolean {
      return store.updatedGraphType !== store.selectedGraphType;
    },
  }))
  .actions((store) => ({
    updateSelectedGraphType: (
      updatedSingleSelectGraphType: QuestionTypeGraphs,
    ): void => {
      store.updatedGraphType =
        GridMultiSelectGraphTypeV1.GridMultiSelectGraphTypeV1[
          updatedSingleSelectGraphType as keyof typeof GridMultiSelectGraphTypeV1.GridMultiSelectGraphTypeV1
        ];
    },
  }));

export const createGridMultiSelectModel = (
  gridMultiSelect: GridResultV1Enums.ColumnQuestionType.MultiSelect,
  columnHeader: string | null = null,
): Instance<typeof GridMultiSelectModel> => {
  return GridMultiSelectModel.create({
    columnHeader,
    selectedGraphType: gridMultiSelect.multiSelectGraphType,
    updatedGraphType: gridMultiSelect.multiSelectGraphType,
    multiSelectResults: gridMultiSelect.multiSelectResults.map(
      (multiSelectResultV1) => {
        return createMultiSelectRowResultV1Model(
          multiSelectResultV1,
          multiSelectResultV1.gridOptionV1.option?.text,
        );
      },
    ),
  });
};
