import { Stack } from "@mui/material";
import { Spacing, Button } from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import { FilterStore } from "../store/FilterStore";
import { Filter } from "./FilterComponent";
import React from "react";

export const FilterMenuButtons = observer(
  ({
    store,
    handleFilterButtonClose,
    t,
    spacing,
    filters,
    isDateRangeInputInvalid,
  }: {
    store: Instance<typeof FilterStore>;
    handleFilterButtonClose: () => void;
    t: TFunction;
    spacing: Spacing;
    filters: Filter[];
    isDateRangeInputInvalid: boolean;
  }): React.ReactElement => {
    return (
      <Stack
        padding={`${spacing.spaceMD} ${spacing.spaceXL}`}
        gap={spacing.spaceSM}
        justifyContent="flex-end"
        direction="row"
        flexWrap="wrap"
      >
        <Button
          onClick={handleFilterButtonClose}
          name="cancelButton"
          label={t("common.cancel")}
          variant="outlined-neutral"
          size="small"
        />
        <Button
          onClick={() => {
            store.addCurrentSelectedFilter();
            handleFilterButtonClose();
          }}
          name="addFilter"
          label={t("filters.addFilter")}
          variant="filled"
          size="small"
          disabled={
            store.isNoFilterValueAdded(filters) || isDateRangeInputInvalid
          }
        />
      </Stack>
    );
  },
);
