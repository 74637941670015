import { ProjectDetailsTabs } from "../pages/ProjectDetailsPage";
import {
  UserPrivileges,
  getPersistedUserPrivileges,
} from "../../store/user/UserPrivileges";
import { TFunction } from "i18next";
import { EMPTY_CHARACTER } from "@pulse/shared-components";
import { NavigateToFunctions } from "../../../routes/RoutesHelper";

interface TabLabelAndType {
  label: string;
  type: ProjectDetailsTabs;
}

export const getProjectDetailsPageTabs = (t: TFunction): TabLabelAndType[] => {
  const tabs: TabLabelAndType[] = [];
  const privileges = getPersistedUserPrivileges();
  const privilegesWithLabelAndTabType = [
    {
      privilege: UserPrivileges.viewSurveys,
      label: t("surveys.surveys"),
      type: ProjectDetailsTabs.Survey,
    },
    {
      privilege: UserPrivileges.viewRespondent,
      label: t("surveys.respondents"),
      type: ProjectDetailsTabs.Respondent,
    },
    {
      privilege: UserPrivileges.viewSurveyResults,
      label: t("projects.dashboard.dashboardTabTitle"),
      type: ProjectDetailsTabs.Dashboard,
    },
    {
      privilege: UserPrivileges.viewSpocs,
      label: t("projects.userAccess.userAccessTitle"),
      type: ProjectDetailsTabs.UserAccess,
    },
    {
      privilege: UserPrivileges.processProject,
      label: t("projects.settings.settingsTitle"),
      type: ProjectDetailsTabs.Settings,
    },
  ];
  privilegesWithLabelAndTabType.forEach((privilegeWithTabLabelAndType) => {
    if (privileges.includes(privilegeWithTabLabelAndType.privilege)) {
      tabs.push({ ...privilegeWithTabLabelAndType });
    }
  });

  return tabs;
};

export const getDefaultProjectDetailsTab = (
  t: TFunction,
  navigateTo: NavigateToFunctions,
): ProjectDetailsTabs | undefined => {
  try {
    const tab = getProjectDetailsPageTabs(t)[0].type;
    if (tab) {
      return tab;
    } else {
      navigateTo.pageNotFound();
    }
  } catch (e) {
    console.error(e);
    navigateTo.pageNotFound();
  }
};

export const setURLTabParam = (selectedTabType: ProjectDetailsTabs): void => {
  const currentURL = new URL(window.location.toString());
  currentURL.searchParams.set("tab", selectedTabType);
  history.pushState(null, EMPTY_CHARACTER, currentURL);
};
