import { Wordcloud } from "@visx/wordcloud";
import { scaleLog } from "@visx/scale";
import { Text } from "@visx/text";
import {
  DASHBOARD_CHART_COLORS,
  QUESTION_VIEW_MAX_WIDTH_IN_PIXELS,
} from "../../../utils/constants";
import { DataProps } from "../DataSection";
import { EMPTY_CHARACTER } from "@pulse/shared-components";
import React from "react";

export const CustomWordcloud = ({
  graphData,
}: {
  graphData: DataProps[];
}): React.ReactElement => {
  const data = graphData.map((item) => {
    return {
      id: item.option ?? EMPTY_CHARACTER,
      label: item.option ?? EMPTY_CHARACTER,
      value: item.count,
    };
  });
  const fontScale = scaleLog({
    domain: [
      Math.min(...data.map((w) => w.value)),
      Math.max(...data.map((w) => w.value)),
    ],
    range: [10, 80],
  });

  return (
    <Wordcloud
      words={data.map((item) => {
        return { text: item.label, value: item.value };
      })}
      height={200}
      width={QUESTION_VIEW_MAX_WIDTH_IN_PIXELS}
      fontSize={(datum: { text: string; value: number }): number =>
        fontScale(datum.value)
      }
      spiral="archimedean"
      random={() => 0.5}
      rotate={() => 0}
    >
      {(cloudWords) =>
        cloudWords.map((word, index) => (
          <Text
            key={word.text}
            fill={DASHBOARD_CHART_COLORS[index % DASHBOARD_CHART_COLORS.length]}
            textAnchor={"middle"}
            transform={`translate(${word.x}, ${word.y}) rotate(${word.rotate})`}
            fontSize={word.size}
            fontFamily={word.font}
          >
            {word.text}
          </Text>
        ))
      }
    </Wordcloud>
  );
};
