import { QuestionViewTypeV1, SingleSelectGraphTypeV1 } from "@pulse/pulse-rpcs";
import { Instance, types } from "mobx-state-tree";
import {
  createSampleBreakdownModel,
  SampleBreakdownModel,
} from "./SampleBreakdownModel";
import {
  createSingleSelectResultsModelV1,
  SingleSelectResultsModelV1,
} from "./SingleSelectResultsModelV1";
import { QuestionTypeGraphs } from "../components/ChartSelection";

export const RegularSingleSelectQuestionModelV1 = types
  .model("RegularSingleSelectQuestionModelV1", {
    sampleBreakdown: SampleBreakdownModel,
    singleSelectGraphType: types.enumeration(
      "SingleSelectGraphTypeV1",
      Object.values(SingleSelectGraphTypeV1.SingleSelectGraphTypeV1),
    ),
    updatedSingleSelectGraphType: types.enumeration(
      "SingleSelectGraphTypeV1",
      Object.values(SingleSelectGraphTypeV1.SingleSelectGraphTypeV1),
    ),
    singleSelectResults: types.array(SingleSelectResultsModelV1),
    question: types.maybeNull(types.string),
  })
  .views((store) => ({
    get isGraphSelectionUpdated(): boolean {
      return store.updatedSingleSelectGraphType !== store.singleSelectGraphType;
    },
  }))
  .actions((store) => ({
    updateSelectedGraphType: (
      updatedSingleSelectGraphType: QuestionTypeGraphs,
    ): void => {
      store.updatedSingleSelectGraphType =
        SingleSelectGraphTypeV1.SingleSelectGraphTypeV1[
          updatedSingleSelectGraphType as keyof typeof SingleSelectGraphTypeV1.SingleSelectGraphTypeV1
        ];
    },
  }));

export const createRegularSingleSelectQuestionModelV1 = (
  regularSingleSelectQuestion: QuestionViewTypeV1.SingleSelect,
  question: string | null = null,
): Instance<typeof RegularSingleSelectQuestionModelV1> => {
  return RegularSingleSelectQuestionModelV1.create({
    sampleBreakdown: createSampleBreakdownModel(
      regularSingleSelectQuestion.sampleBreakdown,
    ),
    singleSelectGraphType: regularSingleSelectQuestion.singleSelectGraphType,
    updatedSingleSelectGraphType:
      regularSingleSelectQuestion.singleSelectGraphType,
    singleSelectResults: createSingleSelectResultsModelV1(
      regularSingleSelectQuestion.singleSelectResults,
    ),
    question,
  });
};
