import { getType, Instance } from "mobx-state-tree";
import React, { ReactElement, useState } from "react";
import { GroupQuestionViewModelV1 } from "../models/GroupQuestionViewModelV1";
import {
  Button,
  CornerRadius,
  FoundationColorTokens,
  Shadow,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import {
  BorderStyle,
  EMPTY_CHARACTER,
  FeatureInDevelopmentSnackbar,
} from "@pulse/shared-components";
import { Stack } from "@mui/material";
import { HeaderQuestionDetails } from "./HeaderQuestionDetails";
import { SurveyQuestionViewStoreNames } from "../stores/SurveyViewDetailsStore";
import { Download } from "lucide-react";
import { RegularSingleSelectQuestionView } from "./RegularSingleSelectQuestionView";
import { RegularSingleSelectQuestionModelV1 } from "../models/RegularSingleSelectQuestionModelV1";
import { SingleSelectQuestionWithProportionView } from "./SingleSelectQuestionWithProportionView";
import { SingleSelectQuestionWithProportionModelV1 } from "../models/SingleSelectQuestionWithProportionModelV1";
import { NPSSingleSelectQuestionView } from "./NPSSingleSelectQuestionView";
import { NPSSingleSelectQuestionModelV1 } from "../models/NPSSingleSelectQuestionModelV1";
import { OpenEndedQuestionView } from "./OpenEndedQuestionView";
import { OpenEndedQuestionViewModelV1 } from "../models/OpenEndedQuestionViewModelV1";
import { MultiSelectQuestionView } from "./MultiSelectQuestionView";
import { MultiSelectQuestionViewModelV1 } from "../models/MultiSelectQuestionViewModelV1";
import { RankingQuestionView } from "./RankingQuestionView";
import { RankingQuestionViewModelV1 } from "../models/RankingQuestionViewModelV1";
import { observer } from "mobx-react";
import { GridQuestionView } from "./gridView/GridQuestionView";
import { GridQuestionViewV1Model } from "../models/GridQuestionV1Model";

interface GroupQuestionViewProps {
  surveyQuestionView: Instance<typeof GroupQuestionViewModelV1>;
  spacing: Spacing;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  border: BorderStyle;
  cornerRadius: CornerRadius;
  boxShadow: Shadow;
}

export const GroupQuestionView = observer(
  ({
    surveyQuestionView,
    spacing,
    t,
    tokens,
    typography,
    border,
    cornerRadius,
    boxShadow,
  }: GroupQuestionViewProps): ReactElement => {
    const [
      featureInDevelopmentSnackbarVisible,
      setFeatureInDevelopmentSnackbarVisible,
    ] = useState(false);
    return (
      <Stack
        width="1024px"
        alignItems="center"
        border={border.default}
        borderRadius={cornerRadius.radiusXS}
        gap={spacing.spaceXL}
        paddingBottom={spacing.space2XL}
      >
        <FeatureInDevelopmentSnackbar
          isSnackbarVisible={featureInDevelopmentSnackbarVisible}
          setIsSnackbarVisible={setFeatureInDevelopmentSnackbarVisible}
        />
        <Stack
          padding={`${spacing.spaceMD} ${spacing.spaceXL}`}
          gap={spacing.spaceSM}
          width="100%"
          borderBottom={border.default}
        >
          <HeaderQuestionDetails
            questionText={surveyQuestionView.question}
            sampleBreakdown={undefined}
            typography={typography}
            tokens={tokens}
            t={t}
            spacing={spacing}
            surveyQuestionViewType={
              SurveyQuestionViewStoreNames.GROUP_QUESTION_MODEL
            }
            isQuestionTypeIconVisible={true}
          />
          <Stack
            gap={spacing.spaceXS}
            direction="row"
            justifyContent="flex-end"
          >
            <Button
              label={EMPTY_CHARACTER}
              onClick={() => {
                setFeatureInDevelopmentSnackbarVisible(true);
              }}
              name="downloadView"
              size="small"
              variant="outlined-neutral"
              disabled={true}
              icon={<Download />}
            />
            <Button
              label={t("common.saveChanges")}
              onClick={() => {
                setFeatureInDevelopmentSnackbarVisible(true);
              }}
              name="saveChanges"
              size="small"
              variant="filled"
              disabled={!surveyQuestionView.isSaveButtonEnabled}
            />
          </Stack>
        </Stack>
        <Stack width="100%" px={spacing.space2XL}>
          {surveyQuestionView.childSurveyQuestionViews.map(
            (surveyQuestion, index) => {
              switch (getType(surveyQuestion).name) {
                case SurveyQuestionViewStoreNames.REGULAR_SINGLE_SELECT_QUESTION_MODEL: {
                  return (
                    <RegularSingleSelectQuestionView
                      key={index}
                      surveyQuestionView={
                        surveyQuestion as Instance<
                          typeof RegularSingleSelectQuestionModelV1
                        >
                      }
                      spacing={spacing}
                      t={t}
                      tokens={tokens}
                      typography={typography}
                      border={border}
                      cornerRadius={cornerRadius}
                      boxShadow={boxShadow}
                      isChildQuestion={true}
                    />
                  );
                }
                case SurveyQuestionViewStoreNames.SINGLE_SELECT_QUESTION_WITH_PROPORTION_MODEL: {
                  return (
                    <SingleSelectQuestionWithProportionView
                      key={index}
                      surveyQuestionView={
                        surveyQuestion as Instance<
                          typeof SingleSelectQuestionWithProportionModelV1
                        >
                      }
                      spacing={spacing}
                      t={t}
                      tokens={tokens}
                      typography={typography}
                      border={border}
                      cornerRadius={cornerRadius}
                      boxShadow={boxShadow}
                      isChildQuestion={true}
                    />
                  );
                }
                case SurveyQuestionViewStoreNames.NPS_SINGLE_SELECT_QUESTION_MODEL: {
                  return (
                    <NPSSingleSelectQuestionView
                      key={index}
                      surveyQuestionView={
                        surveyQuestion as Instance<
                          typeof NPSSingleSelectQuestionModelV1
                        >
                      }
                      spacing={spacing}
                      t={t}
                      tokens={tokens}
                      typography={typography}
                      border={border}
                      cornerRadius={cornerRadius}
                      boxShadow={boxShadow}
                      isChildQuestion={true}
                    />
                  );
                }
                case SurveyQuestionViewStoreNames.OPEN_ENDED_QUESTION_MODEL: {
                  return (
                    <OpenEndedQuestionView
                      key={index}
                      surveyQuestionView={
                        surveyQuestion as Instance<
                          typeof OpenEndedQuestionViewModelV1
                        >
                      }
                      spacing={spacing}
                      t={t}
                      tokens={tokens}
                      typography={typography}
                      border={border}
                      cornerRadius={cornerRadius}
                      boxShadow={boxShadow}
                      isChildQuestion={true}
                    />
                  );
                }
                case SurveyQuestionViewStoreNames.MULTI_SELECT_QUESTION_MODEL: {
                  return (
                    <MultiSelectQuestionView
                      key={index}
                      surveyQuestionView={
                        surveyQuestion as Instance<
                          typeof MultiSelectQuestionViewModelV1
                        >
                      }
                      spacing={spacing}
                      t={t}
                      tokens={tokens}
                      typography={typography}
                      border={border}
                      cornerRadius={cornerRadius}
                      boxShadow={boxShadow}
                      isChildQuestion={true}
                    />
                  );
                }
                case SurveyQuestionViewStoreNames.RANKING_QUESTION_MODEL: {
                  return (
                    <RankingQuestionView
                      key={index}
                      surveyQuestionView={
                        surveyQuestion as Instance<
                          typeof RankingQuestionViewModelV1
                        >
                      }
                      spacing={spacing}
                      t={t}
                      tokens={tokens}
                      typography={typography}
                      border={border}
                      cornerRadius={cornerRadius}
                      boxShadow={boxShadow}
                      isChildQuestion={true}
                    />
                  );
                }
                case SurveyQuestionViewStoreNames.GRID_QUESTION_MODEL: {
                  return (
                    <GridQuestionView
                      key={index}
                      surveyQuestionView={
                        surveyQuestion as Instance<
                          typeof GridQuestionViewV1Model
                        >
                      }
                      spacing={spacing}
                      t={t}
                      tokens={tokens}
                      typography={typography}
                      border={border}
                      cornerRadius={cornerRadius}
                      boxShadow={boxShadow}
                      isChildQuestion={true}
                    />
                  );
                }
              }
            },
          )}
        </Stack>
      </Stack>
    );
  },
);
