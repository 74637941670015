import { Instance, flow, getRoot, types } from "mobx-state-tree";
import {
  SurveyDataModel,
  createSurveyDataModel,
} from "../models/SurveyDataModel";
import { GetProjectSurveyResultsRPC } from "@pulse/pulse-rpcs";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import { CommonErrors, NetworkingError } from "@pulse/shared-components";
import { useGetProjectSurveyResultsRPCClient } from "../rpcs/RPC";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "../../networking/APIClient";
import { RootStore } from "../../root/store/RootStore";
import {
  SurveyIdAndNameModel,
  createSurveyIdAndNameModel,
} from "../models/SurveyIdAndNameModel";
import { AutoCompleteItem } from "@surya-digital/leo-reactjs-material-ui";
import { createPivotTableStore, PivotTableStore } from "./PivotTableStore";
import {
  createReportsStore,
  ReportsStore,
} from "../../reports/stores/ReportsStore";

export enum SurveyDataType {
  NoSurveyData = "NO_SURVEY_DATA",
  SurveyData = "SURVEY_DATA",
}

export const DashboardStore = types
  .model("DashboardStore", {
    surveyDataType: types.optional(
      types.enumeration(Object.values(SurveyDataType)),
      SurveyDataType.NoSurveyData,
    ),
    surveyData: types.maybeNull(SurveyDataModel),
    rpcErrors: types.maybeNull(
      types.enumeration(Object.values(NetworkingError)),
    ),
    isRPCLoading: types.optional(types.boolean, false),
    selectedSurvey: types.maybeNull(SurveyIdAndNameModel),
    projectSurveyResultSurveyId: types.maybe(types.string),
    isGetProjectSurveyResultsRPCCalled: types.optional(types.boolean, false),
    pivotTableStore: PivotTableStore,
    reportsStore: ReportsStore,
  })
  .views((store) => ({
    get doesDashboardStoreContainErrors(): boolean {
      return store.rpcErrors !== null;
    },
    get isDashboardLoadingIndicatorShown(): boolean {
      return store.isRPCLoading && !store.isGetProjectSurveyResultsRPCCalled;
    },
    hasSelectedSurveyUpdated(selectedSurvey: AutoCompleteItem | null): boolean {
      return selectedSurvey?.id === store.selectedSurvey?.surveyId;
    },
    get isAllSurveyShown(): boolean {
      return store.projectSurveyResultSurveyId === undefined;
    },
  }))
  .actions((store) => ({
    setIsRPCLoading: (isRPCLoading: boolean): void => {
      store.isRPCLoading = isRPCLoading;
    },
    setIsGetProjectSurveyResultsRPCCalled: (
      isGetProjectSurveyResultsRPCCalled: boolean,
    ): void => {
      store.isGetProjectSurveyResultsRPCCalled =
        isGetProjectSurveyResultsRPCCalled;
    },
    setSelectedSurvey: (selectedSurvey: AutoCompleteItem | null): void => {
      if (selectedSurvey === null) {
        store.selectedSurvey = null;
      } else {
        selectedSurvey.id === null
          ? (store.selectedSurvey = null)
          : (store.selectedSurvey = createSurveyIdAndNameModel(
              selectedSurvey.id,
              selectedSurvey.label,
            ));
      }
    },
  }))
  .actions((store) => ({
    getProjectSurveyResults: flow(function* (projectId: string) {
      store.isRPCLoading = true;
      store.rpcErrors = null;
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new GetProjectSurveyResultsRPC.Request(
          new LeoUUID(projectId),
          store.selectedSurvey
            ? new GetProjectSurveyResultsRPC.RequestEnums.SurveyResultsType.SpecificSurvey(
                new LeoUUID(store.selectedSurvey.surveyId),
              )
            : new GetProjectSurveyResultsRPC.RequestEnums.SurveyResultsType.AllSurveys(),
        );
        const {
          response,
          error,
        }: {
          response?: GetProjectSurveyResultsRPC.Response;
          error?: GetProjectSurveyResultsRPC.Errors.Errors;
        } =
          yield useGetProjectSurveyResultsRPCClient(apiClient).execute(request);
        if (response) {
          const surveyResults = response.surveyResults;
          store.projectSurveyResultSurveyId = store.selectedSurvey?.surveyId;
          if (
            surveyResults instanceof
            GetProjectSurveyResultsRPC.ResponseEnums.SurveyResults.NoSurveyData
          ) {
            store.surveyData = null;
            store.surveyDataType = SurveyDataType.NoSurveyData;
          } else if (
            surveyResults instanceof
            GetProjectSurveyResultsRPC.ResponseEnums.SurveyResults.SurveyData
          ) {
            store.surveyDataType = SurveyDataType.SurveyData;
            store.surveyData = createSurveyDataModel(
              surveyResults.linkBreakdown,
              surveyResults.surveySubmissionData,
            );
          }
          return response.projectDetails;
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              break;
            default: {
              console.error(`Unhandled error occured: ${error}`);
              break;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        store.isRPCLoading = false;
        if (
          !store.isGetProjectSurveyResultsRPCCalled &&
          store.surveyDataType === SurveyDataType.NoSurveyData
        ) {
          store.isGetProjectSurveyResultsRPCCalled = false;
        } else {
          store.isGetProjectSurveyResultsRPCCalled = true;
        }
      }
    }),
  }));

export const createDashboardStore = (): Instance<typeof DashboardStore> => {
  return DashboardStore.create({
    pivotTableStore: createPivotTableStore(),
    reportsStore: createReportsStore(),
  });
};
