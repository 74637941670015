import {
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioProps,
  Stack,
  Typography,
} from "@mui/material";
import {
  Spacing,
  TextInputField,
  Typography as LeoTypography,
  FoundationColorTokens,
  RadioButtonColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import React, { ReactElement } from "react";
import {
  AddViewRPCError,
  AddViewStore,
  DashboardCustomisations,
} from "../stores/AddViewStore";
import { Instance } from "mobx-state-tree";
import { observer } from "mobx-react";
import {
  BorderStyle,
  getRadioButtonStyleProps,
  SURVEY_VIEW_NAME_VALIDATIONS,
} from "@pulse/shared-components";
import { SPoCsChartCustomisation } from "./SPoCsChartCustomisation";

export const RadioLabelTypography = ({
  title,
  subTitle,
  typography,
  tokens,
}: {
  title: string;
  subTitle: string;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
}): ReactElement => {
  return (
    <Stack>
      <Typography {...typography.b1} color={tokens.label}>
        {title}
      </Typography>
      <Typography {...typography.b3} color={tokens.labelSubtle}>
        {subTitle}
      </Typography>
    </Stack>
  );
};

export const Sh3LabelTypography = ({
  text,
  typography,
  tokens,
  border,
  spacing,
}: {
  text: string;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  border: BorderStyle;
  spacing: Spacing;
}): ReactElement => {
  return (
    <Typography
      {...typography.sh3}
      color={tokens.label}
      borderBottom={border.default}
      padding={spacing.spaceXS}
    >
      {text}
    </Typography>
  );
};

interface DashboardCustomisationProps {
  t: TFunction;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  addViewStore: Instance<typeof AddViewStore>;
  radioProps: RadioProps;
  border: BorderStyle;
}

const DashboardCustomisation = observer(
  ({
    spacing,
    t,
    tokens,
    typography,
    addViewStore,
    radioProps,
    border,
  }: DashboardCustomisationProps): ReactElement => {
    return (
      <Stack gap={spacing.spaceMD} width="100%">
        <Sh3LabelTypography
          text={t("surveyDashboard.addViewDialog.dashboardCustomisation")}
          tokens={tokens}
          typography={typography}
          border={border}
          spacing={spacing}
        />
        <RadioGroup
          value={addViewStore.dashboardCustomisation}
          onChange={(_, value) => {
            switch (value) {
              case DashboardCustomisations.ShowAllQuestions:
                addViewStore.setDashboardCustomisation(
                  DashboardCustomisations.ShowAllQuestions,
                );
                break;
              case DashboardCustomisations.StartFromBlank:
                addViewStore.setDashboardCustomisation(
                  DashboardCustomisations.StartFromBlank,
                );
                break;
              default: {
                console.error(
                  `The value ${value} is not a valid DashboardCustomisation.`,
                );
              }
            }
          }}
          sx={{
            gap: spacing.spaceSM,
          }}
        >
          <FormControlLabel
            control={
              <Radio disabled={addViewStore.isRPCLoading} {...radioProps} />
            }
            value={DashboardCustomisations.ShowAllQuestions}
            label={
              <RadioLabelTypography
                title={t("surveyDashboard.addViewDialog.showAllQuestions")}
                subTitle={t(
                  "surveyDashboard.addViewDialog.allQuestionsAreVisibleByDefault",
                )}
                tokens={tokens}
                typography={typography}
              />
            }
          />
          <FormControlLabel
            control={
              <Radio disabled={addViewStore.isRPCLoading} {...radioProps} />
            }
            value={DashboardCustomisations.StartFromBlank}
            label={
              <RadioLabelTypography
                title={t("surveyDashboard.addViewDialog.startFromBlank")}
                subTitle={t(
                  "surveyDashboard.addViewDialog.allQuestionsAreHiddenByDefault",
                )}
                tokens={tokens}
                typography={typography}
              />
            }
          />
        </RadioGroup>
      </Stack>
    );
  },
);

interface AddViewDialogChildProps {
  t: TFunction;
  spacing: Spacing;
  addViewStore: Instance<typeof AddViewStore>;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  radioButtonTokens: RadioButtonColorTokens<string>;
  border: BorderStyle;
}

export const AddViewDialogChild = observer(
  ({
    spacing,
    t,
    addViewStore,
    tokens,
    typography,
    radioButtonTokens,
    border,
  }: AddViewDialogChildProps): ReactElement => {
    const radioProps = getRadioButtonStyleProps(
      tokens,
      radioButtonTokens,
      spacing,
      `0px ${spacing.spaceXS}`,
    );
    const getViewNameTextFieldHelperText = (): string | undefined => {
      switch (addViewStore.rpcError) {
        case AddViewRPCError.InvalidViewName:
          return t(
            "surveyDashboard.addViewDialog.viewNameHelperText.invalidViewName",
            {
              allowedSpecialCharacters:
                SURVEY_VIEW_NAME_VALIDATIONS.allowedSpecialCharacters,
            },
          );
        case AddViewRPCError.InvalidViewNameLength:
          return t(
            "surveyDashboard.addViewDialog.viewNameHelperText.invalidViewNameLength",
            {
              minLength: SURVEY_VIEW_NAME_VALIDATIONS.minLength,
              maxLength: SURVEY_VIEW_NAME_VALIDATIONS.maxLength,
            },
          );
        case AddViewRPCError.SurveyViewAlreadyExists:
          return t(
            "surveyDashboard.addViewDialog.viewNameHelperText.viewNameNotUnique",
          );
      }
    };

    return (
      <Stack gap={spacing.spaceLG} width="100%">
        <TextInputField
          name="viewName"
          value={addViewStore.viewName}
          type="text"
          onTextChange={addViewStore.setViewName}
          placeholder={t("surveyDashboard.addViewDialog.enterViewName")}
          label={t("surveyDashboard.addViewDialog.viewName")}
          helperText={getViewNameTextFieldHelperText()}
          error={addViewStore.doesStoreContainViewNameError}
          disabled={addViewStore.isRPCLoading}
          required={true}
        />
        <DashboardCustomisation
          spacing={spacing}
          t={t}
          tokens={tokens}
          typography={typography}
          addViewStore={addViewStore}
          radioProps={radioProps}
          border={border}
        />
        <SPoCsChartCustomisation
          spacing={spacing}
          t={t}
          tokens={tokens}
          typography={typography}
          radioProps={radioProps}
          border={border}
          isRPCLoading={addViewStore.isRPCLoading}
          setSpoCChartCustomisation={addViewStore.setSpoCChartCustomisation}
          spocChartCustomisation={addViewStore.spocChartCustomisation}
        />
      </Stack>
    );
  },
);
