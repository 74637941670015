import { Instance, flow, getRoot, types } from "mobx-state-tree";
import {
  FileUploadError,
  getFileSHA,
  uploadFileToS3,
} from "../../utils/FileUploadUtils";
import { getAPIClient } from "../../networking/APIClient";
import {
  FileAttributes,
  GetPresignedDownloadURLForTranslationsRPC,
  GetPresignedUploadURLForTranslationsRPC,
  ValidateAndUploadTranslationsRPC,
} from "@pulse/pulse-rpcs";
import {
  CSV_FILE_TYPE,
  CommonErrors,
  EMPTY_CHARACTER,
  MAX_COLUMN_SIZE_FOR_TRANSLATIONS,
  MAX_FILE_NAME,
  MAX_TRANSLATIONS_FILE_SIZE_IN_BYTES,
  MIN_FILE_NAME,
  MIN_FILE_SIZE_IN_BYTES,
  SHA_256_LENGTH,
  SURVEY_TRANSLATIONS_FILE_NAME,
  TEMPLATE_FILE_DELIMITER,
  getDelimitedFileName,
} from "@pulse/shared-components";
import Papa, { ParseResult } from "papaparse";
import {
  useGetPresignedDownloadURLForTranslationsRPCClientImpl,
  useGetPresignedUploadURLForTranslationsRPCClientImpl,
  useValidateAndUploadTranslationsRPCClientImpl,
} from "../rpcs/RPC";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import { RootStore } from "../../root/store/RootStore";
import {
  downloadFile,
  insertDocumentRecord,
} from "../../utils/FileDownloadUtils";

export enum GetPresignedDownloadURLForTranslationErrors {
  BaseTranslationNotFound = "BASE_TRANSLATION_NOT_FOUND",
}

export enum GetPresignedUploadURLForTranslationErrors {
  ProjectAlreadyArchived = "PROJECT_ALREADY_ARCHIVED",
}

export enum ValidateAndUploadTranslationErrors {
  BaseTranslationNotFound = "BASE_TRANSLATION_NOT_FOUND",
  InvalidFile = "INVALID_FILE",
  UnknownFile = "UNKNOWN_FILE",
  TranslationUpdateInProgress = "TRANSLATION_UPDATE_IN_PROGRESS",
  MaximumTranslationLimitReached = "MAXIMUM_TRANSLATION_LIMIT_REACHED",
  ProjectAlreadyArchived = "PROJECT_ALREADY_ARCHIVED",
}

export enum UploadTranslationsDialogState {
  UploadTranslations = "UPLOAD_TRANSLATIONS",
  UploadSuccessful = "UPLOAD_SUCCESSFUL",
  UploadingFile = "UPLOADING_FILE",
  ValidatingFile = "VALIDATING_FILE",
  ValidationSuccessful = "VALIDATION_SUCCESSFUL",
  ValidationErrorFound = "VALIDATION_ERROR_FOUND",
  ErrorState = "ERROR_STATE",
}

export const TranslationUploadStore = types
  .model("TranslationUploadStore", {
    fileName: types.maybe(types.string),
    documentId: types.maybe(types.string),
    rpcError: types.maybeNull(
      types.union(
        types.enumeration(
          "GetPresignedDownloadURLForTranslationErrors",
          Object.values(GetPresignedDownloadURLForTranslationErrors),
        ),
        types.enumeration(
          "ValidateAndUploadTranslationErrors",
          Object.values(ValidateAndUploadTranslationErrors),
        ),
        types.enumeration(
          "GetPresignedUploadURLForTranslationErrors",
          Object.values(GetPresignedUploadURLForTranslationErrors),
        ),
      ),
    ),
    validationErrors: types.maybeNull(
      types.enumeration(Object.values(FileUploadError)),
    ),
    uploadTranslationsDialogState: types.optional(
      types.enumeration(
        "UploadTranslationsDialogState",
        Object.values(UploadTranslationsDialogState),
      ),
      UploadTranslationsDialogState.UploadTranslations,
    ),
    invalidFileDetails: types.maybeNull(types.string),
    // This is added to the store to avoid re-rendering the translation list when the dialog is opened.
    isTranslationUploadDialogVisible: types.optional(types.boolean, false),
  })
  .views((store) => ({
    get isFileInvalid(): boolean {
      return (
        store.rpcError === ValidateAndUploadTranslationErrors.InvalidFile &&
        store.uploadTranslationsDialogState ===
          UploadTranslationsDialogState.ValidationErrorFound
      );
    },
    get isUploadButtonDisabled(): boolean {
      return (
        store.uploadTranslationsDialogState ===
          UploadTranslationsDialogState.ErrorState ||
        store.uploadTranslationsDialogState ===
          UploadTranslationsDialogState.UploadTranslations ||
        store.uploadTranslationsDialogState ===
          UploadTranslationsDialogState.UploadingFile ||
        store.uploadTranslationsDialogState ===
          UploadTranslationsDialogState.ValidatingFile
      );
    },
    get isSecondaryDialogButtonDisabled(): boolean {
      return (
        store.uploadTranslationsDialogState ===
          UploadTranslationsDialogState.UploadingFile ||
        store.uploadTranslationsDialogState ===
          UploadTranslationsDialogState.ValidatingFile
      );
    },
  }))
  .actions((store) => ({
    clearErrorsAndFlags: (): void => {
      store.rpcError = null;
      store.validationErrors = null;
      store.fileName = undefined;
      store.documentId = undefined;
      store.uploadTranslationsDialogState =
        UploadTranslationsDialogState.UploadTranslations;
    },
    setIsTranslationDialogVisible: (isDialogVisible: boolean): void => {
      store.isTranslationUploadDialogVisible = isDialogVisible;
    },
    setFileName: (fileName: string): void => {
      store.fileName = fileName;
    },
    getFileAttribute: flow(function* (file: File) {
      if (!CSV_FILE_TYPE.includes(file.type)) {
        store.uploadTranslationsDialogState =
          UploadTranslationsDialogState.ValidationErrorFound;
        store.validationErrors = FileUploadError.InvalidFileFormat;
        return;
      }
      const sha256: string = yield getFileSHA(file);

      const csvText: string = yield new Response(file).text();

      const parsedData: ParseResult<{ [key: string]: string }> = Papa.parse(
        csvText,
        { header: true },
      );

      const csvBaseColumns = parsedData.meta.fields?.map((baseColumn) =>
        baseColumn.toLocaleLowerCase(),
      );
      if (csvBaseColumns === undefined) {
        store.uploadTranslationsDialogState =
          UploadTranslationsDialogState.ValidationErrorFound;
        store.validationErrors = FileUploadError.InternalError;
        return;
      }

      if (csvBaseColumns.length > MAX_COLUMN_SIZE_FOR_TRANSLATIONS) {
        store.uploadTranslationsDialogState =
          UploadTranslationsDialogState.ValidationErrorFound;
        store.validationErrors = FileUploadError.MaxColumnsExceeded;
        return;
      }

      try {
        if (sha256.length !== SHA_256_LENGTH) {
          store.uploadTranslationsDialogState =
            UploadTranslationsDialogState.ValidationErrorFound;
          store.validationErrors = FileUploadError.InvalidFileSHA;
          return;
        } else if (
          file.name.length < MIN_FILE_NAME ||
          file.name.length > MAX_FILE_NAME
        ) {
          store.uploadTranslationsDialogState =
            UploadTranslationsDialogState.ValidationErrorFound;
          store.validationErrors = FileUploadError.InvalidFileName;
          return;
        } else if (file.size > MAX_TRANSLATIONS_FILE_SIZE_IN_BYTES) {
          store.uploadTranslationsDialogState =
            UploadTranslationsDialogState.ValidationErrorFound;
          store.validationErrors = FileUploadError.MaxFileSizeReached;
          return;
        } else if (file.size < MIN_FILE_SIZE_IN_BYTES) {
          store.uploadTranslationsDialogState =
            UploadTranslationsDialogState.ValidationErrorFound;
          store.validationErrors = FileUploadError.MinFileSizeNotReached;
          return;
        }

        const fileAttribute = new FileAttributes(sha256, file.name, file.size);
        return fileAttribute;
      } catch (error) {
        console.error(error);
        store.uploadTranslationsDialogState =
          UploadTranslationsDialogState.ErrorState;
        store.validationErrors = FileUploadError.InternalError;
      }
    }),
  }))
  .actions((store) => ({
    uploadCSVFile: flow(function* (
      file: File,
      surveyId: string,
      projectId: string,
    ) {
      store.uploadTranslationsDialogState =
        UploadTranslationsDialogState.UploadingFile;
      try {
        const apiClient = getAPIClient(store);

        const fileAttribute: FileAttributes | undefined =
          yield store.getFileAttribute(file);

        if (fileAttribute === undefined) {
          return;
        }

        const {
          response,
          error,
        }: {
          response?: GetPresignedUploadURLForTranslationsRPC.Response;
          error?: GetPresignedUploadURLForTranslationsRPC.Errors.Errors;
        } = yield useGetPresignedUploadURLForTranslationsRPCClientImpl(
          apiClient,
        ).execute(
          new GetPresignedUploadURLForTranslationsRPC.Request(
            fileAttribute,
            new LeoUUID(surveyId),
            new LeoUUID(projectId),
          ),
        );

        if (response) {
          try {
            yield uploadFileToS3(response.documentURL, file);
            store.documentId = yield insertDocumentRecord(
              fileAttribute,
              apiClient,
            );
            store.uploadTranslationsDialogState =
              UploadTranslationsDialogState.UploadSuccessful;
          } catch (e) {
            store.uploadTranslationsDialogState =
              UploadTranslationsDialogState.ErrorState;
            store.validationErrors = FileUploadError.InternalError;
          }
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              break;
            case GetPresignedUploadURLForTranslationErrors.ProjectAlreadyArchived: {
              store.rpcError =
                GetPresignedUploadURLForTranslationErrors.ProjectAlreadyArchived;
            }
          }
          store.uploadTranslationsDialogState =
            UploadTranslationsDialogState.ErrorState;
        }
      } catch (e) {
        store.uploadTranslationsDialogState =
          UploadTranslationsDialogState.ErrorState;
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      }
    }),
    downloadTranslationFile: flow(function* (
      surveyId: string,
      projectId: string,
      surveyName: string | undefined,
    ) {
      try {
        const apiClient = getAPIClient(store);
        const request = new GetPresignedDownloadURLForTranslationsRPC.Request(
          new LeoUUID(surveyId),
          new LeoUUID(projectId),
        );

        let fileName = EMPTY_CHARACTER;
        if (surveyName) {
          fileName = getDelimitedFileName(surveyName) + TEMPLATE_FILE_DELIMITER;
        }

        const {
          response,
          error,
        }: {
          response?: GetPresignedDownloadURLForTranslationsRPC.Response;
          error?: GetPresignedDownloadURLForTranslationsRPC.Errors.Errors;
        } =
          yield useGetPresignedDownloadURLForTranslationsRPCClientImpl(
            apiClient,
          ).execute(request);

        if (response) {
          yield downloadFile(
            response.documentURL.href,
            fileName + SURVEY_TRANSLATIONS_FILE_NAME,
          );
          store.uploadTranslationsDialogState =
            UploadTranslationsDialogState.UploadTranslations;
        }
        if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              break;
            case GetPresignedDownloadURLForTranslationErrors.BaseTranslationNotFound: {
              store.rpcError =
                GetPresignedDownloadURLForTranslationErrors.BaseTranslationNotFound;
              break;
            }
          }
          store.uploadTranslationsDialogState =
            UploadTranslationsDialogState.ErrorState;
        }
      } catch (e) {
        store.uploadTranslationsDialogState =
          UploadTranslationsDialogState.ErrorState;
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      }
    }),
    validateAndUploadTranslations: flow(function* (
      surveyId: string,
      projectId: string,
    ) {
      store.rpcError = null;
      store.invalidFileDetails = null;
      store.uploadTranslationsDialogState =
        UploadTranslationsDialogState.ValidatingFile;
      try {
        const apiClient = getAPIClient(store);
        const request = new ValidateAndUploadTranslationsRPC.Request(
          new LeoUUID(surveyId),
          new LeoUUID(projectId),
          new LeoUUID(store.documentId),
        );

        const {
          response,
          error,
        }: {
          response?: ValidateAndUploadTranslationsRPC.Response;
          error?: ValidateAndUploadTranslationsRPC.Errors.Errors;
        } =
          yield useValidateAndUploadTranslationsRPCClientImpl(
            apiClient,
          ).execute(request);

        if (response) {
          store.uploadTranslationsDialogState =
            UploadTranslationsDialogState.ValidationSuccessful;
          return;
        }
        if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              break;
            case ValidateAndUploadTranslationErrors.MaximumTranslationLimitReached: {
              store.uploadTranslationsDialogState =
                UploadTranslationsDialogState.ErrorState;
              store.rpcError =
                ValidateAndUploadTranslationErrors.MaximumTranslationLimitReached;
              break;
            }
            case ValidateAndUploadTranslationErrors.ProjectAlreadyArchived: {
              store.uploadTranslationsDialogState =
                UploadTranslationsDialogState.ErrorState;
              store.rpcError =
                ValidateAndUploadTranslationErrors.ProjectAlreadyArchived;
              break;
            }
            case ValidateAndUploadTranslationErrors.TranslationUpdateInProgress: {
              store.uploadTranslationsDialogState =
                UploadTranslationsDialogState.ErrorState;
              store.rpcError =
                ValidateAndUploadTranslationErrors.TranslationUpdateInProgress;
              break;
            }
            case ValidateAndUploadTranslationErrors.BaseTranslationNotFound: {
              store.uploadTranslationsDialogState =
                UploadTranslationsDialogState.ErrorState;
              store.rpcError =
                ValidateAndUploadTranslationErrors.BaseTranslationNotFound;
              break;
            }
            case ValidateAndUploadTranslationErrors.UnknownFile: {
              store.uploadTranslationsDialogState =
                UploadTranslationsDialogState.ErrorState;
              store.rpcError = ValidateAndUploadTranslationErrors.UnknownFile;
              break;
            }
            case ValidateAndUploadTranslationErrors.InvalidFile: {
              store.uploadTranslationsDialogState =
                UploadTranslationsDialogState.ValidationErrorFound;
              store.rpcError = ValidateAndUploadTranslationErrors.InvalidFile;
              if (
                error instanceof
                ValidateAndUploadTranslationsRPC.Errors.InvalidFile
              ) {
                store.invalidFileDetails = error.invalidFileDetails;
              }
              break;
            }
          }
        }
      } catch (e) {
        store.uploadTranslationsDialogState =
          UploadTranslationsDialogState.ErrorState;
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      }
    }),
  }));

export const createTranslationUploadStore = (): Instance<
  typeof TranslationUploadStore
> => {
  return TranslationUploadStore.create();
};
