import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { DetailSectionComponent } from "../../../components/DetailSectionComponent";
import { useReportsStore, useSurveyStore } from "../../store/Hooks";
import { getSurveyStatusChipProps } from "../../utils/getSurveyStatusChipProps";
import { useTranslation } from "react-i18next";
import {
  NavigateToFunctions,
  processProjectParams,
  processSurveyParams,
  useRouteNavigator,
} from "../../../routes/RoutesHelper";
import {
  Button,
  FoundationColorTokens,
  LoadingIndicator,
  Spacing,
  Typography as LeoTypography,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
  RadioButtonColorTokens,
  useRadioButtonColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import { ProjectDetailsTabs } from "../../projects/pages/ProjectDetailsPage";
import {
  BorderStyle,
  FeatureInDevelopmentSnackbar,
  getIconProps,
  ICON_SIZE,
  useBorder,
} from "@pulse/shared-components";
import { observer } from "mobx-react";
import { FileX2, Plus } from "lucide-react";
import { navigateOrGetNetworkErrorPage } from "../../../utility/navigateOrGetNetworkErrorPage";
import { useNetworkingErrorStore } from "../../networking/store/hooks";
import { SurveyDashboardViews } from "../components/SurveyDashboardViews";
import { TFunction } from "i18next";
import { AddViewDialog } from "../components/AddViewDialog";
import { Instance } from "mobx-state-tree";
import { AddViewStore } from "../stores/AddViewStore";
import { Stack, Typography } from "@mui/material";

interface AddViewProps {
  t: TFunction;
  addViewStore: Instance<typeof AddViewStore>;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  radioButtonTokens: RadioButtonColorTokens<string>;
  border: BorderStyle;
  navigateTo: NavigateToFunctions;
}

const AddView = observer(
  ({
    t,
    addViewStore,
    spacing,
    tokens,
    typography,
    radioButtonTokens,
    border,
    navigateTo,
  }: AddViewProps): ReactElement => {
    const [isAddViewDialogOpen, setIsAddViewDialogOpen] = useState(false);
    return (
      <>
        <Button
          label={t("surveyDashboard.addViewDialog.addView")}
          name="addView"
          size="medium"
          variant="outlined-neutral"
          onClick={(): void => {
            setIsAddViewDialogOpen(true);
          }}
          icon={<Plus />}
        />
        <AddViewDialog
          isAddViewDialogOpen={isAddViewDialogOpen}
          setIsAddViewDialogOpen={setIsAddViewDialogOpen}
          addViewStore={addViewStore}
          t={t}
          spacing={spacing}
          tokens={tokens}
          typography={typography}
          radioButtonTokens={radioButtonTokens}
          border={border}
          navigateTo={navigateTo}
        />
      </>
    );
  },
);

export const SurveyViews = observer((): ReactElement => {
  const surveyStore = useSurveyStore();
  const { t } = useTranslation();
  const navigateTo = useRouteNavigator();
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const errorStore = useNetworkingErrorStore();
  const reportsStore = useReportsStore();
  const border = useBorder();
  const cornerRadius = useCornerRadius();
  const spacing = useSpacing();
  const tokens = useFoundationColorTokens();
  const typography = useTypography();
  const [isGetSurveyDashboardViewsCalled, setIsGetSurveyDashboardViewsCalled] =
    useState(false);
  const radioButtonTokens = useRadioButtonColorTokens();

  const errorPage = navigateOrGetNetworkErrorPage(
    errorStore.error,
    navigateTo,
    errorStore.removeError,
  );

  if (errorPage) {
    errorStore.removeError();
    return errorPage;
  }

  const getSurveyDashboardViews = useCallback(async () => {
    await processSurveyParams(reportsStore.getSurveyDashboardViews, navigateTo);
    setIsGetSurveyDashboardViewsCalled(true);
  }, []);

  useEffect(() => {
    getSurveyDashboardViews();
  }, []);

  return (
    <DetailSectionComponent
      isManageUsersLogoVisible={true}
      isAuditLogsLogoVisible={true}
      mainHeaderProps={{
        title: surveyStore.surveyName,
        prefersLargeTitle: true,
        chipProps:
          surveyStore.surveyStatus &&
          getSurveyStatusChipProps(surveyStore.surveyStatus, t),
        backButtonCallback: () => {
          processProjectParams(async (projectId): Promise<void> => {
            navigateTo.projectDetails(projectId, ProjectDetailsTabs.Dashboard);
          }, navigateTo);
        },
      }}
      isChildHeightVariable={
        reportsStore.isSurveyDashboardViewsListEmpty ? false : true
      }
      isOverflowHidden={true}
      onLogoClickCallback={() => {
        navigateTo.project();
      }}
      sectionHeaderProps={{
        actionElement: (
          <AddView
            t={t}
            addViewStore={reportsStore.addViewStore}
            spacing={spacing}
            tokens={tokens}
            typography={typography}
            radioButtonTokens={radioButtonTokens}
            border={border}
            navigateTo={navigateTo}
          />
        ),
        title: t("surveyDashboard.views.viewsStringLiteral"),
        prefersLargeTitle: true,
      }}
    >
      <FeatureInDevelopmentSnackbar
        isSnackbarVisible={isSnackbarVisible}
        setIsSnackbarVisible={setIsSnackbarVisible}
      />
      {reportsStore.isRPCLoading ? (
        <LoadingIndicator isLoading={true} />
      ) : reportsStore.isSurveyDashboardViewsListEmpty &&
        isGetSurveyDashboardViewsCalled ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          gap={spacing.spaceXS}
        >
          <FileX2 {...getIconProps(tokens.iconLowEmphasis, ICON_SIZE.large)} />
          <Typography {...typography.b1} color={tokens.labelLowEmphasis}>
            {t("surveyDashboard.noViewsFound")}
          </Typography>
        </Stack>
      ) : (
        <SurveyDashboardViews
          surveyDashboardViews={reportsStore.surveyDashboardViews}
          border={border}
          cornerRadius={cornerRadius}
          spacing={spacing}
          tokens={tokens}
          typography={typography}
          t={t}
          navigateTo={navigateTo}
          radioButtonTokens={radioButtonTokens}
        />
      )}
    </DetailSectionComponent>
  );
});
