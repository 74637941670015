import {
  Dialog,
  FoundationColorTokens,
  RadioButtonColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Instance } from "mobx-state-tree";
import React, { useEffect } from "react";
import {
  AddViewDialogState,
  AddViewRPCError,
  AddViewStore,
} from "../stores/AddViewStore";
import { TFunction } from "i18next";
import { AddViewDialogChild } from "./AddViewDialogChild";
import { observer } from "mobx-react";
import {
  BorderStyle,
  MAXIMUM_SURVEY_VIEW_COUNT,
} from "@pulse/shared-components";
import {
  NavigateToFunctions,
  processSurveyParams,
} from "../../../routes/RoutesHelper";
import { DialogErrorContent } from "../../../components/DialogErrorContent";

interface AddViewDialogProps {
  isAddViewDialogOpen: boolean;
  setIsAddViewDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  addViewStore: Instance<typeof AddViewStore>;
  t: TFunction;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  radioButtonTokens: RadioButtonColorTokens<string>;
  border: BorderStyle;
  navigateTo: NavigateToFunctions;
}

export const AddViewDialog = observer(
  ({
    isAddViewDialogOpen,
    setIsAddViewDialogOpen,
    addViewStore,
    t,
    spacing,
    tokens,
    typography,
    radioButtonTokens,
    border,
    navigateTo,
  }: AddViewDialogProps): React.ReactElement => {
    const getPrimaryButtonText = (): string | undefined => {
      switch (addViewStore.addViewDialogState) {
        case AddViewDialogState.AddView: {
          return t("surveyDashboard.addViewDialog.addView");
        }
        default: {
          return undefined;
        }
      }
    };

    const getSecondaryButtonText = (): string | undefined => {
      switch (addViewStore.addViewDialogState) {
        case AddViewDialogState.AddView: {
          return t("common.cancel");
        }
        case AddViewDialogState.Error: {
          return t("common.close");
        }
      }
    };

    const getErrorText = (): string => {
      switch (addViewStore.rpcError) {
        case AddViewRPCError.MaximumSurveyViewsReached:
          return t(
            "surveyDashboard.addViewDialog.errorMessages.maximumViewCountExceededErrorText",
            {
              maximumSurveyViewCount: MAXIMUM_SURVEY_VIEW_COUNT,
            },
          );
        case AddViewRPCError.NoSurveyLinkFound:
          return t(
            "surveyDashboard.addViewDialog.errorMessages.noSurveyLinkFoundErrorText",
          );
        case AddViewRPCError.ProjectAlreadyArchived:
          return t("common.projectAlreadyArchivedErrorText");
        default:
          return t(
            "surveyDashboard.addViewDialog.errorMessages.unexpectedError",
          );
      }
    };

    const getDialogChild = (): React.ReactElement => {
      switch (addViewStore.addViewDialogState) {
        case AddViewDialogState.AddView: {
          return (
            <AddViewDialogChild
              t={t}
              spacing={spacing}
              addViewStore={addViewStore}
              tokens={tokens}
              typography={typography}
              radioButtonTokens={radioButtonTokens}
              border={border}
            />
          );
        }
        case AddViewDialogState.Error: {
          return (
            <DialogErrorContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              errorText={getErrorText()}
            />
          );
        }
      }
    };

    useEffect(() => {
      return () => {
        addViewStore.resetStoreValues();
      };
    }, []);

    return (
      <Dialog
        open={isAddViewDialogOpen}
        title={t("surveyDashboard.addViewDialog.addView")}
        primaryButtonText={getPrimaryButtonText()}
        secondaryButtonText={getSecondaryButtonText()}
        onSecondaryButtonClick={(): void => {
          setIsAddViewDialogOpen(false);
          addViewStore.resetStoreValues();
        }}
        isSecondaryButtonDisabled={addViewStore.isRPCLoading}
        isPrimaryButtonDisabled={addViewStore.isPrimaryButtonDisabled}
        onPrimaryButtonClick={async (): Promise<void> => {
          if (addViewStore.validateViewName()) {
            await processSurveyParams(addViewStore.addView, navigateTo);
            if (!addViewStore.doesStoreContainError) {
              addViewStore.resetStoreValues();
              setIsAddViewDialogOpen(false);
            }
          }
        }}
        width="560px"
      >
        {/*
        getDialogChild() is not called like <DialogChild />.
        This is done to avoid giving it its own lifecycle which will result in loss of focus from <TextInputField /> on store updation.
        */}
        {getDialogChild()}
      </Dialog>
    );
  },
);
