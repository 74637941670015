import React, { ReactElement } from "react";
import { QuestionTypeGraphs } from "./ChartSelection";
import { CustomWordcloud } from "./graphs/CustomWordcloud";
import { PieGraph } from "./graphs/PieGraph";
import { DataProps, DataPropsForRanking } from "./DataSection";
import { BarGraph } from "./graphs/BarGraph";
import { LineGraph } from "./graphs/LineGraph";
import { GaugeGraph } from "./graphs/GaugeGraph";
import { ProportionGraph } from "./graphs/ProportionGraph";
import {
  CornerRadius,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { BorderStyle } from "@pulse/shared-components";
import { StackedColumnGroupedBarGraph } from "./graphs/StackedColumnGroupedBarGraph";

export interface GraphData {
  id: string;
  label: string;
  value: number;
  percentage: string;
}

interface SelectedGraphProps {
  selectedGraph: QuestionTypeGraphs;
  data: DataProps[] | DataPropsForRanking[];
  isNeutral?: boolean;
  spacing: Spacing;
  cornerRadius: CornerRadius;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  border: BorderStyle;
  naOptionCode: string | null;
}

export const SelectedGraph = ({
  selectedGraph,
  data,
  isNeutral,
  spacing,
  cornerRadius,
  tokens,
  typography,
  border,
  naOptionCode,
}: SelectedGraphProps): ReactElement => {
  switch (selectedGraph) {
    case QuestionTypeGraphs.PIE: {
      return (
        <PieGraph
          data={data as DataProps[]}
          spacing={spacing}
          cornerRadius={cornerRadius}
          isDonut={false}
          tokens={tokens}
          border={border}
          typography={typography}
        />
      );
    }
    case QuestionTypeGraphs.DONUT: {
      return (
        <PieGraph
          data={data as DataProps[]}
          spacing={spacing}
          cornerRadius={cornerRadius}
          isDonut={true}
          tokens={tokens}
          border={border}
          typography={typography}
        />
      );
    }
    case QuestionTypeGraphs.HORIZONTAL_BAR: {
      return (
        <BarGraph
          data={data as DataProps[]}
          isHorizontal={true}
          spacing={spacing}
          cornerRadius={cornerRadius}
          tokens={tokens}
          typography={typography}
          border={border}
        />
      );
    }
    case QuestionTypeGraphs.VERTICAL_BAR: {
      return (
        <BarGraph
          data={data as DataProps[]}
          isHorizontal={false}
          spacing={spacing}
          cornerRadius={cornerRadius}
          tokens={tokens}
          typography={typography}
          border={border}
        />
      );
    }
    case QuestionTypeGraphs.LINE: {
      return (
        <LineGraph
          data={data as DataProps[]}
          isLineArea={false}
          cornerRadius={cornerRadius}
          spacing={spacing}
          tokens={tokens}
          border={border}
          typography={typography}
        />
      );
    }
    case QuestionTypeGraphs.LINE_AREA: {
      return (
        <LineGraph
          data={data as DataProps[]}
          isLineArea={true}
          cornerRadius={cornerRadius}
          spacing={spacing}
          tokens={tokens}
          border={border}
          typography={typography}
        />
      );
    }
    case QuestionTypeGraphs.GAUGE: {
      return (
        <GaugeGraph
          data={data as DataProps[]}
          spacing={spacing}
          cornerRadius={cornerRadius}
          typography={typography}
          tokens={tokens}
        />
      );
    }
    case QuestionTypeGraphs.PROPORTION: {
      const proportionData = data as DataProps[];
      return (
        <ProportionGraph
          data={
            naOptionCode !== null
              ? proportionData.filter((item) => item.id !== naOptionCode)
              : proportionData
          }
          isNeutral={isNeutral}
          spacing={spacing}
          cornerRadius={cornerRadius}
          tokens={tokens}
          border={border}
          typography={typography}
          graphWidth={undefined}
          isLegendsVisible={true}
        />
      );
    }
    case QuestionTypeGraphs.NONE: {
      return <></>;
    }
    case QuestionTypeGraphs.WORD_CLOUD: {
      return <CustomWordcloud graphData={data as DataProps[]} />;
    }
    case QuestionTypeGraphs.STACKED_COLUMN: {
      return (
        <StackedColumnGroupedBarGraph
          data={data as DataPropsForRanking[]}
          spacing={spacing}
          cornerRadius={cornerRadius}
          tokens={tokens}
          border={border}
          typography={typography}
          isStackedBarGraph={true}
          graphWidth={undefined}
        />
      );
    }
    case QuestionTypeGraphs.GROUPED_BAR: {
      return (
        <StackedColumnGroupedBarGraph
          data={data as DataPropsForRanking[]}
          spacing={spacing}
          cornerRadius={cornerRadius}
          tokens={tokens}
          border={border}
          typography={typography}
          isStackedBarGraph={false}
          graphWidth={undefined}
        />
      );
    }
  }
};
