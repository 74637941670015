import { SignInRPC, SignInRPCClientImpl } from "@pulse/pulse-rpcs";
import { APIClient } from "@surya-digital/tedwig";
import { MockSignInClientImpl } from "./MockSignInClientImpl";
import { MOCK } from "@pulse/shared-components";

export const useSignInRPCClientImpl = (apiClient: APIClient): SignInRPC => {
  if (MOCK.auth) {
    return new MockSignInClientImpl();
  } else {
    return new SignInRPCClientImpl(apiClient);
  }
};
