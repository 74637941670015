import { getType, Instance } from "mobx-state-tree";
import React, { ReactElement, useState } from "react";
import {
  Button,
  CornerRadius,
  FoundationColorTokens,
  IconButton,
  Shadow,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import {
  BorderStyle,
  FeatureInDevelopmentSnackbar,
} from "@pulse/shared-components";
import { Stack } from "@mui/material";
import { HeaderQuestionDetails } from "../HeaderQuestionDetails";
import { Download } from "lucide-react";
import { observer } from "mobx-react";
import {
  GridQuestionViewTypeV1Enum,
  GridQuestionViewV1Model,
} from "../../models/GridQuestionV1Model";
import { SurveyQuestionViewStoreNames } from "../../stores/SurveyViewDetailsStore";
import { GridOpenEndedQuestionView } from "./GridOpenEndedView";
import { GridMultiSelectQuestionView } from "./GridMultiSelectQuestionView";
import { GridSingleSelectQuestionView } from "./GridSingleSelectQuestionView";
import { GridSingleSelectQuestionWithMeanView } from "./GridSingleSelectQuestionWithMeanView";
import { GridOpenEndedModel } from "../../models/GridOpenEndedModel";
import { GridSingleSelectModel } from "../../models/GridSingleSelectModel";
import { GridSingleSelectWithMeanModel } from "../../models/GridSingleSelectWithMeanModel";
import { GridMultiSelectModel } from "../../models/GridMultiSelectModel";
import { QuestionTypeGraphs } from "../ChartSelection";

interface GridQuestionViewProps {
  surveyQuestionView: Instance<typeof GridQuestionViewV1Model>;
  spacing: Spacing;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  border: BorderStyle;
  cornerRadius: CornerRadius;
  boxShadow: Shadow;
  isChildQuestion: boolean;
}

export const GridQuestionView = observer(
  ({
    surveyQuestionView,
    spacing,
    t,
    tokens,
    typography,
    border,
    cornerRadius,
    boxShadow,
    isChildQuestion,
  }: GridQuestionViewProps): ReactElement => {
    const [
      featureInDevelopmentSnackbarVisible,
      setFeatureInDevelopmentSnackbarVisible,
    ] = useState(false);
    return (
      <Stack
        width={isChildQuestion ? "100%" : "1024px"}
        alignItems="center"
        border={isChildQuestion ? undefined : border.default}
        borderRadius={cornerRadius.radiusXS}
        gap={spacing.spaceXL}
        paddingBottom={spacing.space2XL}
      >
        <FeatureInDevelopmentSnackbar
          isSnackbarVisible={featureInDevelopmentSnackbarVisible}
          setIsSnackbarVisible={setFeatureInDevelopmentSnackbarVisible}
        />
        <Stack
          padding={
            isChildQuestion
              ? spacing.spaceSM
              : `${spacing.spaceMD} ${spacing.spaceXL}`
          }
          gap={isChildQuestion ? "0px" : spacing.spaceSM}
          width="100%"
          borderBottom={border.default}
        >
          <HeaderQuestionDetails
            questionText={surveyQuestionView.question}
            sampleBreakdown={surveyQuestionView.sampleBreakdown}
            typography={typography}
            tokens={tokens}
            t={t}
            spacing={spacing}
            surveyQuestionViewType={
              SurveyQuestionViewStoreNames.GRID_QUESTION_MODEL
            }
            isQuestionTypeIconVisible={true}
          />
          {!isChildQuestion && (
            <Stack
              gap={spacing.spaceXS}
              direction="row"
              justifyContent="flex-end"
            >
              <IconButton
                onClick={() => {
                  setFeatureInDevelopmentSnackbarVisible(true);
                }}
                name="downloadView"
                size="small"
                variant="outlined-neutral"
                disabled={true}
                icon={<Download />}
              />
              <Button
                label={t("common.saveChanges")}
                onClick={() => {
                  setFeatureInDevelopmentSnackbarVisible(true);
                }}
                name="saveChanges"
                size="small"
                variant="filled"
                disabled={!surveyQuestionView.isGraphSelectionUpdated}
              />
            </Stack>
          )}
        </Stack>
        <Stack width="100%" px={spacing.space2XL}>
          {surveyQuestionView.gridResults.map((gridResult, index) => {
            switch (getType(gridResult.columnQuestionType).name) {
              case GridQuestionViewTypeV1Enum.GRID_SINGLE_SELECT_MODEL: {
                return (
                  <GridSingleSelectQuestionView
                    key={index}
                    singleSelectQuestionView={
                      gridResult.columnQuestionType as Instance<
                        typeof GridSingleSelectModel
                      >
                    }
                    border={border}
                    cornerRadius={cornerRadius}
                    spacing={spacing}
                    typography={typography}
                    t={t}
                    isChildQuestion={isChildQuestion}
                    tokens={tokens}
                  />
                );
              }
              case GridQuestionViewTypeV1Enum.GRID_SINGLE_SELECT_PROPORTION_MODEL: {
                return (
                  <GridSingleSelectQuestionWithMeanView
                    key={index}
                    singleSelectQuestionWithMeanView={
                      gridResult.columnQuestionType as Instance<
                        typeof GridSingleSelectWithMeanModel
                      >
                    }
                    border={border}
                    cornerRadius={cornerRadius}
                    spacing={spacing}
                    typography={typography}
                    t={t}
                    tokens={tokens}
                    isChildQuestion={isChildQuestion}
                  />
                );
              }
              case GridQuestionViewTypeV1Enum.GRID_OPEN_ENDED_MODEL: {
                const openEndedQuestionView =
                  gridResult.columnQuestionType as Instance<
                    typeof GridOpenEndedModel
                  >;
                return (
                  <GridOpenEndedQuestionView
                    key={index}
                    gridOpenEndedQuestionView={openEndedQuestionView}
                    t={t}
                    spacing={spacing}
                    typography={typography}
                    tokens={tokens}
                    cornerRadius={cornerRadius}
                    border={border}
                    columnHeader={openEndedQuestionView.columnHeader}
                    selectedGraph={
                      QuestionTypeGraphs[
                        gridResult.columnQuestionType.updatedGraphType
                      ]
                    }
                    updateSelectedGraphType={
                      gridResult.columnQuestionType.updateSelectedGraphType
                    }
                    boxShadow={boxShadow}
                  />
                );
              }
              case GridQuestionViewTypeV1Enum.GRID_MULTI_SELECT_MODEL: {
                const multiSelectQuestionView =
                  gridResult.columnQuestionType as Instance<
                    typeof GridMultiSelectModel
                  >;
                return (
                  <GridMultiSelectQuestionView
                    key={index}
                    multiSelectQuestionView={multiSelectQuestionView}
                    typography={typography}
                    t={t}
                    tokens={tokens}
                    spacing={spacing}
                    border={border}
                    cornerRadius={cornerRadius}
                    isChildQuestion={isChildQuestion}
                  />
                );
              }
            }
          })}
        </Stack>
      </Stack>
    );
  },
);
