import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import { RespondentFilters } from "../../../modules/projects/components/RespondentsPane";
import { RespondentColumnDetailsModel } from "../../../modules/projects/models/RespondentColumnDetailsModel";
import { SurveyIdAndNameModel } from "../../../modules/projects/models/SurveyIdAndNameModel";
import { SurveyLinkStatus } from "@pulse/pulse-rpcs";
import { FilterType } from "../model/FilterModel";
import { Filter } from "../components/FilterComponent";

export const getRespondentFilterList = ({
  t,
  respondentColumnDetails,
  isSurveyFilterShown,
}: {
  t: TFunction;
  respondentColumnDetails: Instance<typeof RespondentColumnDetailsModel>[];
  isSurveyFilterShown: boolean;
}): { key: string; label: string }[] => {
  const filterList: { key: string; label: string }[] = [
    {
      key: RespondentFilters.Id,
      label: t("projects.respondentList.columnNames.id"),
    },
    {
      key: RespondentFilters.FirstName,
      label: t("projects.respondentList.columnNames.firstName"),
    },
    {
      key: RespondentFilters.LastName,
      label: t("projects.respondentList.columnNames.lastName"),
    },
    {
      key: RespondentFilters.EmailAddress,
      label: t("projects.respondentList.columnNames.emailAddress"),
    },
    {
      key: RespondentFilters.PhoneNumber,
      label: t("projects.respondentList.columnNames.phoneNumber"),
    },
    {
      key: RespondentFilters.SurveyStatus,
      label: t("projects.respondentList.columnNames.surveyStatus"),
    },
  ];
  if (isSurveyFilterShown) {
    // Here the survey filter is being added to the 5th index as seen in the respondent columns.
    filterList.splice(5, 0, {
      key: RespondentFilters.Survey,
      label: t("projects.respondentList.columnNames.survey"),
    });
  }
  respondentColumnDetails.map((respondentColumn) => {
    filterList.push({
      key: respondentColumn.name,
      label: respondentColumn.name,
    });
  });
  return filterList;
};

export const mapRespondentFiltersToFilterComponentFilters = (
  filterList: { key: string; label: string }[],
  surveyList: Instance<typeof SurveyIdAndNameModel>[],
  t: TFunction,
  showUnassignedSurveyFilter = true,
): Filter[] => {
  return filterList.map((column) => {
    if (column.key === RespondentFilters.Survey) {
      return {
        ...column,
        valueType: FilterType.MultiSelect,
        values: surveyList.map((survey) => {
          return {
            id: survey.surveyName,
            label: survey.surveyName,
          };
        }),
      };
    }
    if (column.key === RespondentFilters.SurveyStatus) {
      const values = [
        {
          id: SurveyLinkStatus.SurveyLinkStatus.SUBMITTED,
          label: t("surveyLinkStatus.submitted"),
        },
        {
          id: SurveyLinkStatus.SurveyLinkStatus.NOT_ASSIGNED,
          label: t("surveyLinkStatus.unassigned"),
        },
        {
          id: SurveyLinkStatus.SurveyLinkStatus.NOT_STARTED,
          label: t("surveyLinkStatus.notStarted"),
        },
        {
          id: SurveyLinkStatus.SurveyLinkStatus.VIEWED,
          label: t("surveyLinkStatus.viewed"),
        },
        {
          id: SurveyLinkStatus.SurveyLinkStatus.STARTED,
          label: t("surveyLinkStatus.started"),
        },
        {
          id: SurveyLinkStatus.SurveyLinkStatus.LAST_PAGE,
          label: t("surveyLinkStatus.lastPage"),
        },
        {
          id: SurveyLinkStatus.SurveyLinkStatus.IN_PROGRESS,
          label: t("surveyLinkStatus.inProgress"),
        },
      ];

      if (!showUnassignedSurveyFilter) {
        values.splice(1, 1);
      }
      return {
        ...column,
        valueType: FilterType.MultiSelect,
        values,
      };
    } else {
      return {
        ...column,
        valueType: FilterType.OpenEnded,
      };
    }
  });
};
