import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React, { useEffect } from "react";
import { ProjectDetailsStore } from "../store/ProjectDetailsStore";
import {
  NavigateToFunctions,
  processProjectParams,
} from "../../../routes/RoutesHelper";
import { Stack } from "@mui/material";
import {
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
  CornerRadius,
  AutoCompleteItem,
} from "@surya-digital/leo-reactjs-material-ui";
import { SurveyDataType } from "../store/DashboardStore";
import { TFunction } from "i18next";
import { LoadingIndicator } from "@surya-digital/leo-reactjs-material-ui";
import { BorderStyle } from "@pulse/shared-components";
import { SurveyResultsPane } from "./surveyResults/SurveyResultsPane";
import { SurveySubmissionDataPane } from "./surveyResults/SurveySubmissionDataPane";
import { ErrorComponent } from "../../../components/ErrorComponent";
import { EmptyListPane } from "../../surveys/components/EmptyListPane";
import { SurveySelectionComponent } from "./surveyResults/SurveySelectionComponent";
import { PivotComponent } from "./pivot/PivotComponent";
import { SignInStore } from "../../auth/store/SignInStore";

export interface SurveyResultsDashboardProps {
  projectStore: Instance<typeof ProjectDetailsStore>;
  navigateTo: NavigateToFunctions;
  spacing: Spacing;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  border: BorderStyle;
  borderRadius: CornerRadius;
  setIsChildHeightVariable: React.Dispatch<React.SetStateAction<boolean>>;
  signInStore: Instance<typeof SignInStore>;
  setIsDownloadReportsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SurveyResultsDashboard = observer(
  ({
    projectStore,
    navigateTo,
    spacing,
    t,
    typography,
    tokens,
    border,
    borderRadius,
    setIsChildHeightVariable,
    signInStore,
    setIsDownloadReportsDialogOpen,
  }: SurveyResultsDashboardProps): React.ReactElement => {
    const allSurveysListItem = {
      label: t("projects.dashboard.allSurveysText"),
      id: null,
    };

    useEffect(() => {
      setIsChildHeightVariable(false);
      if (
        !projectStore.dashboardStore.isRPCLoading &&
        projectStore.dashboardStore.rpcErrors === null &&
        projectStore.dashboardStore.surveyDataType === SurveyDataType.SurveyData
      ) {
        setIsChildHeightVariable(true);
      }
    }, [
      projectStore.dashboardStore.surveyDataType,
      projectStore.dashboardStore.isRPCLoading,
      projectStore.dashboardStore.rpcErrors,
    ]);

    const getSurveySelectionList = (): AutoCompleteItem[] => {
      const surveyList: AutoCompleteItem[] = [allSurveysListItem];
      surveyList.push(
        ...projectStore.surveyIdAndNames.map((surveyIdAndNames) => {
          return {
            label: surveyIdAndNames.surveyName,
            id: surveyIdAndNames.surveyId,
          };
        }),
      );
      return surveyList;
    };

    const SurveyDashboardPane = observer((): React.ReactElement => {
      return (
        <Stack
          width="100%"
          height="100%"
          padding={`${spacing.spaceXL} ${spacing.space2XL}`}
          gap={spacing.spaceXL}
        >
          <Stack gap={spacing.spaceSM}>
            <SurveySelectionComponent
              spacing={spacing}
              t={t}
              isDisabled={projectStore.dashboardStore.isRPCLoading}
              onSelect={(survey: AutoCompleteItem | null) => {
                projectStore.dashboardStore.setSelectedSurvey(survey);
              }}
              onViewReportClick={() => {
                processProjectParams(async (projectId) => {
                  const projectDetails =
                    await projectStore.dashboardStore.getProjectSurveyResults(
                      projectId,
                    );
                  if (projectDetails !== undefined) {
                    projectStore.setProjectDetails(projectDetails);
                  }
                }, navigateTo);
              }}
              surveyIdAndNames={getSurveySelectionList()}
              selectedValue={
                projectStore.dashboardStore.selectedSurvey
                  ? {
                      label:
                        projectStore.dashboardStore.selectedSurvey.surveyName,
                      id: projectStore.dashboardStore.selectedSurvey.surveyId,
                    }
                  : allSurveysListItem
              }
              hasSelectedSurveyUpdated={
                projectStore.dashboardStore.hasSelectedSurveyUpdated
              }
            />
            <Stack
              direction="row"
              border={border.default}
              borderRadius={borderRadius.radiusXS}
              width="100%"
            >
              {projectStore.dashboardStore.isRPCLoading ? (
                <LoadingIndicator
                  isLoading={projectStore.dashboardStore.isRPCLoading}
                  variant="overlay"
                />
              ) : (
                <>
                  <SurveyResultsPane
                    border={border}
                    borderRadius={borderRadius}
                    t={t}
                    typography={typography}
                    tokens={tokens}
                    spacing={spacing}
                    projectStore={projectStore}
                    navigateTo={navigateTo}
                    signInStore={signInStore}
                    setIsDownloadReportsDialogOpen={
                      setIsDownloadReportsDialogOpen
                    }
                  />
                  <SurveySubmissionDataPane
                    border={border}
                    borderRadius={borderRadius}
                    spacing={spacing}
                    tokens={tokens}
                    typography={typography}
                    surveyDataModel={projectStore.dashboardStore.surveyData}
                    t={t}
                  />
                </>
              )}
            </Stack>
            <PivotComponent
              t={t}
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              border={border}
              borderRadius={borderRadius}
              navigateTo={navigateTo}
              pivotTableStore={projectStore.dashboardStore.pivotTableStore}
            />
          </Stack>
        </Stack>
      );
    });

    const SurveyDashboardData = observer((): React.ReactElement => {
      switch (projectStore.dashboardStore.surveyDataType) {
        case SurveyDataType.NoSurveyData: {
          if (!projectStore.dashboardStore.isGetProjectSurveyResultsRPCCalled) {
            return (
              <EmptyListPane
                headingText={t("projects.dashboard.noDataTitle")}
                subHeadingText={t(
                  "projects.dashboard.noSurveyResultsDescription",
                )}
              />
            );
          } else {
            return <SurveyDashboardPane />;
          }
        }
        case SurveyDataType.SurveyData: {
          return <SurveyDashboardPane />;
        }
      }
    });

    if (projectStore.dashboardStore.isDashboardLoadingIndicatorShown) {
      return (
        <LoadingIndicator
          isLoading={projectStore.dashboardStore.isRPCLoading}
          variant="overlay"
        />
      );
    } else if (projectStore.dashboardStore.doesDashboardStoreContainErrors) {
      return (
        <ErrorComponent
          errorMessage={t("projects.dashboard.errorFetchingDataText")}
          resolveButtonText={t("common.refresh")}
          resolveButtonCallback={(): void => {
            navigateTo.reload();
          }}
        />
      );
    } else {
      return <SurveyDashboardData />;
    }
  },
);
