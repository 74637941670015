import { Instance, types } from "mobx-state-tree";
import { createGridSingleSelectResultsModelV1 } from "./GridSingleSelectModel";
import { SingleSelectResultsModelV1 } from "./SingleSelectResultsModelV1";
import {
  GridResultV1Enums,
  GridSingleSelectGraphTypeV1,
  GridSingleSelectRowResultWithMeanV1,
  ProportionChartType,
} from "@pulse/pulse-rpcs";
import { QuestionTypeGraphs } from "../components/ChartSelection";

const GridSingleSelectRowResultWithMeanV1Model = types.model(
  "GridSingleSelectRowResultWithMeanV1Model",
  {
    code: types.string,
    option: types.maybeNull(types.string),
    singleSelectResults: types.array(SingleSelectResultsModelV1),
    mean: types.maybeNull(types.number),
  },
);

const createGridSingleSelectRowResultWithMeanV1Model = (
  gridSingleSelectRowResult: GridSingleSelectRowResultWithMeanV1,
): Instance<typeof GridSingleSelectRowResultWithMeanV1Model> => {
  const getMean = (): number | undefined => {
    if (
      gridSingleSelectRowResult.proportionChartType instanceof
      ProportionChartType.Ryg
    ) {
      return gridSingleSelectRowResult.proportionChartType.mean;
    } else if (
      gridSingleSelectRowResult.proportionChartType instanceof
      ProportionChartType.Neutral
    ) {
      return undefined;
    } else {
      console.error(
        `Unknown proportion chart type: ${gridSingleSelectRowResult.proportionChartType} found`,
      );
      return undefined;
    }
  };
  return GridSingleSelectRowResultWithMeanV1Model.create({
    code: gridSingleSelectRowResult.gridOptionV1.code.code,
    option: gridSingleSelectRowResult.gridOptionV1.option?.text,
    singleSelectResults: createGridSingleSelectResultsModelV1(
      gridSingleSelectRowResult.singleSelectResults,
    ),
    mean: getMean(),
  });
};

export const GridSingleSelectWithMeanModel = types
  .model("GridSingleSelectWithMeanModel", {
    gridSingleSelectRowResults: types.array(
      GridSingleSelectRowResultWithMeanV1Model,
    ),
    selectedGraphType: types.enumeration(
      Object.values(GridSingleSelectGraphTypeV1.GridSingleSelectGraphTypeV1),
    ),
    updatedGraphType: types.enumeration(
      Object.values(GridSingleSelectGraphTypeV1.GridSingleSelectGraphTypeV1),
    ),
    columnHeader: types.maybeNull(types.string),
    naOptionCode: types.maybeNull(types.string),
  })
  .views((store) => ({
    get isGraphSelectionUpdated(): boolean {
      return store.selectedGraphType !== store.updatedGraphType;
    },
  }))
  .actions((store) => ({
    updateSelectedGraphType: (
      updatedSingleSelectGraphType: QuestionTypeGraphs,
    ): void => {
      store.updatedGraphType =
        GridSingleSelectGraphTypeV1.GridSingleSelectGraphTypeV1[
          updatedSingleSelectGraphType as keyof typeof GridSingleSelectGraphTypeV1.GridSingleSelectGraphTypeV1
        ];
    },
  }));

export const createGridSingleSelectWithMeanModel = (
  gridSingleSelectWithProportion: GridResultV1Enums.ColumnQuestionType.SingleSelectWithProportion,
  columnHeader: string | null = null,
): Instance<typeof GridSingleSelectWithMeanModel> => {
  return GridSingleSelectWithMeanModel.create({
    gridSingleSelectRowResults:
      gridSingleSelectWithProportion.gridSingleSelectRowResultsWithMean.map(
        (gridSingleSelectRowResult) => {
          return createGridSingleSelectRowResultWithMeanV1Model(
            gridSingleSelectRowResult,
          );
        },
      ),
    selectedGraphType:
      gridSingleSelectWithProportion.singleSelectWithProportionGraphType,
    updatedGraphType:
      gridSingleSelectWithProportion.singleSelectWithProportionGraphType,
    columnHeader,
    naOptionCode: gridSingleSelectWithProportion.naOptionCode?.code,
  });
};
