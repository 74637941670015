import React, { ReactElement } from "react";
import {
  DataPropsForGridStackedAndGroupedBar,
  DataPropsForRanking,
} from "../DataSection";
import {
  CornerRadius,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  BorderStyle,
  EMPTY_CHARACTER,
  RICH_TEXT_MARKDOWN_PROPS,
  richTextTypography,
} from "@pulse/shared-components";
import { Bar, BarCustomLayerProps, BarDatum } from "@nivo/bar";
import { Stack } from "@mui/material";
import {
  DASHBOARD_CHART_COLORS,
  QUESTION_VIEW_MAX_WIDTH_IN_PIXELS,
} from "../../../utils/constants";
import { TooltipContent } from "./TooltipContent";
import { Legends } from "./Legends";
import { RichTextEditor } from "@surya-digital/leo-reactjs-remirror";

enum PropertyNames {
  COUNT = "count",
  ROW_OPTION_TEXT = "rowOptionText",
}

interface StackedColumnGroupedBarGraphProps {
  data: DataPropsForRanking[] | DataPropsForGridStackedAndGroupedBar[];
  spacing: Spacing;
  cornerRadius: CornerRadius;
  tokens: FoundationColorTokens<string>;
  border: BorderStyle;
  typography: Typography;
  isStackedBarGraph: boolean;
  graphWidth: number | undefined;
}

export const StackedColumnGroupedBarGraph = ({
  border,
  cornerRadius,
  spacing,
  tokens,
  typography,
  data,
  isStackedBarGraph,
  graphWidth,
}: StackedColumnGroupedBarGraphProps): ReactElement => {
  const legends: Set<string> = new Set();
  const isRankingQuestion = PropertyNames.COUNT in data[0];
  const transformDataForBar: BarDatum[] = data.map((item, dataIndex) => {
    if (PropertyNames.COUNT in item) {
      // This block is for ranking questions.
      const transformedItem: BarDatum = {
        optionIndex: dataIndex + 1,
        option: item.option ?? EMPTY_CHARACTER,
      };
      item.count.forEach((value, index) => {
        transformedItem[String(index)] = value;
      });
      return transformedItem;
    } else {
      // This block is for grid questions with GridStackedAndGroupedBar.
      const transformedItem: BarDatum = {
        optionIndex: item.option ?? EMPTY_CHARACTER,
        option: item.option ?? EMPTY_CHARACTER,
      };

      item.answerCounts.forEach((answerCount, index) => {
        legends.add(answerCount.answer);
        transformedItem[String(index)] = answerCount.count;
        transformedItem[`answer_${index}`] = answerCount.answer;
      });

      return transformedItem;
    }
  });

  const CustomAxisLabels = (
    props: BarCustomLayerProps<BarDatum>,
  ): ReactElement => {
    const width = isStackedBarGraph
      ? props.bars[0].width
      : props.bars[0].width * Math.sqrt(props.bars.length);
    return (
      <g transform="translate(0, 177)">
        {data.map((item, index) => (
          <foreignObject
            key={index}
            height={100}
            width={width}
            x={props.bars[index].x}
            y={10}
          >
            <Stack
              sx={{
                "& .ProseMirror": {
                  textAlign: "center",
                },
              }}
            >
              <RichTextEditor
                name={`${index}`}
                typography={richTextTypography()}
                mode="preview"
                borderOnPreview={false}
                initialValue={`${PropertyNames.ROW_OPTION_TEXT in item ? item.rowOptionText : index + 1}`}
                supports={RICH_TEXT_MARKDOWN_PROPS}
              />
            </Stack>
          </foreignObject>
        ))}
      </g>
    );
  };

  return (
    <Stack>
      <Bar
        data={transformDataForBar}
        keys={transformDataForBar.map((_, index) => `${index}`)}
        height={247}
        width={graphWidth ?? QUESTION_VIEW_MAX_WIDTH_IN_PIXELS}
        margin={{ top: 20, right: 0, bottom: 50, left: 40 }}
        layout="vertical"
        animate={false}
        groupMode={isStackedBarGraph ? "stacked" : "grouped"}
        colors={DASHBOARD_CHART_COLORS}
        indexBy="optionIndex"
        enableLabel={false}
        layers={["grid", CustomAxisLabels, "bars", "axes"]}
        axisBottom={{
          renderTick: () => {
            return <></>;
          },
        }}
        axisLeft={{
          renderTick: (tick) => {
            return (
              <text
                x={tick.x - 30}
                y={tick.y}
                color={tokens.labelSubtle}
                {...typography.b2}
              >
                {Math.floor(tick.value) === tick.value
                  ? tick.value
                  : EMPTY_CHARACTER}
              </text>
            );
          },
        }}
        tooltip={(tooltipData) => {
          const count = tooltipData.data[Number(tooltipData.id)];
          const label = isRankingQuestion
            ? (data[Number(tooltipData.id)].option ?? EMPTY_CHARACTER)
            : Array.from(legends)[Number(tooltipData.id)];
          return (
            <TooltipContent
              spacing={spacing}
              cornerRadius={cornerRadius}
              tokens={tokens}
              count={`${count}`}
              label={`${label}`}
              border={border}
              typography={typography}
            />
          );
        }}
      />
      {data.length > 0 && (
        <Legends
          legends={
            isRankingQuestion
              ? data.map((item) => item.option ?? EMPTY_CHARACTER)
              : [...legends]
          }
          spacing={spacing}
          colors={DASHBOARD_CHART_COLORS}
          cornerRadius={cornerRadius}
        />
      )}
    </Stack>
  );
};
