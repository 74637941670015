import React from "react";
import { RespondentList } from "./RespondentList";
import { FilterComponent } from "../../../components/filter/components/FilterComponent";
import { Stack } from "@mui/material";
import {
  FoundationColorTokens,
  LoadingIndicator,
  Spacing,
  TableReloadHandle,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Instance } from "mobx-state-tree";
import { RespondentStore } from "../store/RespondentStore";
import { observer } from "mobx-react";
import { TFunction } from "i18next";
import {
  getRespondentFilterList,
  mapRespondentFiltersToFilterComponentFilters,
} from "../../../components/filter/utils/getRespondentFilterList";

export interface RespondentsPaneProps {
  respondentStore: Instance<typeof RespondentStore>;
  isSurveyFilterShown: boolean;
  surveyFilterName: string | undefined;
  t: TFunction;
  spacing: Spacing;
  typography: Typography;
  tokens: FoundationColorTokens<string>;
  tableRef: React.MutableRefObject<TableReloadHandle | null>;
}

export enum RespondentFilters {
  Id = "ID",
  FirstName = "First Name",
  LastName = "Last Name",
  EmailAddress = "Email Address",
  PhoneNumber = "Phone Number",
  Survey = "Survey",
  SurveyStatus = "Survey Status",
  CreatedAt = "Created At",
  ModifiedOn = "Modified On",
}

export const RespondentsPane = observer(
  ({
    respondentStore,
    isSurveyFilterShown,
    surveyFilterName,
    spacing,
    t,
    tableRef,
  }: RespondentsPaneProps): React.ReactElement => {
    const filterList = getRespondentFilterList({
      t,
      respondentColumnDetails: respondentStore.respondentColumns,
      isSurveyFilterShown,
    });
    if (
      respondentStore.isRPCLoading &&
      !respondentStore.isGetRespondentDetailsRPCCalled
    ) {
      return (
        <LoadingIndicator
          isLoading={
            respondentStore.isRPCLoading &&
            !respondentStore.isGetRespondentDetailsRPCCalled
          }
          variant="overlay"
        />
      );
    }

    return (
      <Stack
        padding={spacing.spaceXL}
        gap={spacing.spaceLG}
        height="100%"
        minWidth="1570px"
        maxWidth="100%"
        margin="auto"
      >
        {respondentStore.isPaginatedListVisible && (
          <Stack width="100%">
            <FilterComponent
              isDisabled={
                respondentStore.isRPCLoading ||
                respondentStore.doesRespondentStoreContainError
              }
              store={respondentStore.filterStore}
              onApplyFiltersClick={async () => {
                respondentStore.respondentUIStore.setIsApplyFiltersClicked(
                  true,
                );
                tableRef.current?.reload();
              }}
              onRemoveFiltersClick={() => {
                respondentStore.respondentUIStore.setIsApplyFiltersClicked(
                  false,
                );
                tableRef.current?.reload();
              }}
              filters={mapRespondentFiltersToFilterComponentFilters(
                filterList,
                respondentStore.surveyList,
                t,
              )}
            />
          </Stack>
        )}
        <RespondentList
          respondentStore={respondentStore}
          surveyFilterName={surveyFilterName}
          tableRef={tableRef}
        />
      </Stack>
    );
  },
);
