import {
  Dialog,
  FoundationColorTokens,
  Typography as LeoTypography,
  MultiSelectTableStore,
  Spacing,
  TableReloadHandle,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { RespondentStore, UnassignSurveyError } from "../store/RespondentStore";
import { Instance } from "mobx-state-tree";
import {
  NavigateToFunctions,
  processProjectParams,
} from "../../../routes/RoutesHelper";
import {
  END_OF_COUNTDOWN,
  COUNTDOWN_INTERVAL_IN_MS,
  TOTAL_COUNTDOWN_TIME_IN_SEC,
} from "@pulse/shared-components";
import { NestedTypographyDestructiveComponent } from "./NestedTypographyDestructiveComponent";
import { ErrorSuccessDialogContent } from "./ErrorSuccessDialogContent";
import { UnassignRespondentDialogState } from "../store/RespondentUIStore";
import { DialogSuccessContent } from "../../../components/DialogSuccessContent";

interface UnassignSurveyRespondentDialogProps {
  isUnassignRespondentDialogVisible: boolean;
  setIsUnassignRespondentDialogVisible: (
    newIsUnassignDialogVisible: boolean,
  ) => void;
  typography: LeoTypography;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  spacing: Spacing;
  respondentStore: Instance<typeof RespondentStore>;
  navigateTo: NavigateToFunctions;
  multiSelectTableStore: Instance<typeof MultiSelectTableStore>;
  tableRef: React.MutableRefObject<TableReloadHandle | null>;
  surveyFilterName: string;
}

export const UnassignSurveyRespondentDialog = observer(
  ({
    isUnassignRespondentDialogVisible,
    setIsUnassignRespondentDialogVisible,
    typography,
    t,
    tokens,
    spacing,
    respondentStore,
    navigateTo,
    multiSelectTableStore,
    tableRef,
    surveyFilterName,
  }: UnassignSurveyRespondentDialogProps): React.ReactElement => {
    const [primaryButtonCountDown, setPrimaryButtonCountDown] = useState(
      TOTAL_COUNTDOWN_TIME_IN_SEC,
    );

    useEffect(() => {
      const interval = setInterval(() => {
        setPrimaryButtonCountDown(primaryButtonCountDown - 1);
      }, COUNTDOWN_INTERVAL_IN_MS);
      if (primaryButtonCountDown === END_OF_COUNTDOWN) clearInterval(interval);
      return () => clearInterval(interval);
    }, [primaryButtonCountDown]);

    useEffect(() => {
      return () => {
        respondentStore.respondentUIStore.setUnassignRespondentDialogState(
          UnassignRespondentDialogState.UnassignRespondent,
        );
      };
    }, []);

    const getPrimaryButtonText = (): string | undefined => {
      switch (respondentStore.respondentUIStore.unassignRespondentDialogState) {
        case UnassignRespondentDialogState.UnassignRespondent: {
          return primaryButtonCountDown !== END_OF_COUNTDOWN
            ? t("projects.unassignRespondents.unassign", {
                primaryButtonCountdown: primaryButtonCountDown,
              })
            : t("common.unassign");
        }
      }
    };

    const respondentIdOrCount = respondentStore.isSingleRespondentSelected
      ? t("common.id", {
          id: respondentStore.selectedRespondentDetails?.respondentDetails.id
            ? respondentStore.selectedRespondentDetails?.respondentDetails.id
            : multiSelectTableStore.selectedRowIds[0],
        })
      : multiSelectTableStore.areAllRowsSelected
        ? t("common.respondentCount", {
            respondentsCount: respondentStore.totalItems,
          })
        : t("common.respondentCount", {
            respondentsCount: multiSelectTableStore.selectedRowIds.length,
          });

    const singleOrBulkRespondent = respondentStore.isSingleRespondentSelected
      ? t(
          "projects.unassignRespondents.unassignSingleSurveyRespondentDialogText",
        )
      : t(
          "projects.unassignRespondents.unassignBulkSurveyRespondentDialogText",
        );

    const getErrorText = (): string => {
      switch (respondentStore.rpcErrors) {
        case UnassignSurveyError.SurveyAlreadySubmitted:
          return t(
            "projects.unassignRespondents.surveyAlreadySubmittedErrorMessage",
          );
        case UnassignSurveyError.DeletedColumnFilter:
          return t("common.deletedColumnFilterErrorText");
        case UnassignSurveyError.ProjectAlreadyArchived:
          return t(
            "projects.unassignRespondents.projectAlreadyArchivedUnassignRespondentError",
          );
        case UnassignSurveyError.RespondentsAlreadyUnassigned:
          return t("projects.unassignRespondents.respondentsAlreadyUnassigned");
        default:
          return t(
            "projects.unassignRespondents.unassignRespondentGenericErrorDialogText",
          );
      }
    };

    const getDialogChild = (): React.ReactElement => {
      switch (respondentStore.respondentUIStore.unassignRespondentDialogState) {
        case UnassignRespondentDialogState.UnassignRespondent: {
          return (
            <NestedTypographyDestructiveComponent
              tokens={tokens}
              typography={typography}
              middleBoldText={respondentIdOrCount}
              startText={t("projects.unassignRespondents.areYouSureText")}
              endText={singleOrBulkRespondent}
              noteText={t(
                "projects.unassignRespondents.unassignSurveyDialogNote",
              )}
              spacing={spacing}
            />
          );
        }
        case UnassignRespondentDialogState.Error: {
          return (
            <ErrorSuccessDialogContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              isError={true}
              errorSuccessText={getErrorText()}
            />
          );
        }
        case UnassignRespondentDialogState.Success: {
          return (
            <DialogSuccessContent
              spacing={spacing}
              typography={typography}
              tokens={tokens}
              description={t("projects.unassignRespondents.successDescription")}
            />
          );
        }
      }
    };

    const getSecondaryButtonText = (): string => {
      switch (respondentStore.respondentUIStore.unassignRespondentDialogState) {
        case UnassignRespondentDialogState.UnassignRespondent: {
          return t("common.cancel");
        }
        case UnassignRespondentDialogState.Error:
        case UnassignRespondentDialogState.Success: {
          return t("common.close");
        }
      }
    };

    return (
      <Dialog
        title={t(
          "projects.unassignRespondents.unassignSurveyRespondentDialogTitle",
        )}
        open={isUnassignRespondentDialogVisible}
        secondaryButtonText={getSecondaryButtonText()}
        onSecondaryButtonClick={() => {
          setIsUnassignRespondentDialogVisible(false);
          respondentStore.resetSelectedRespondentDetails();
          respondentStore.respondentUIStore.setUnassignRespondentDialogState(
            UnassignRespondentDialogState.UnassignRespondent,
          );
        }}
        isSecondaryButtonDisabled={respondentStore.isRPCLoading}
        primaryButtonText={getPrimaryButtonText()}
        isPrimaryButtonDisabled={
          primaryButtonCountDown !== END_OF_COUNTDOWN ||
          respondentStore.isRPCLoading
        }
        primaryButtonColor="destructive"
        contentPadding={spacing.spaceLG}
        disableBackdropClick={true}
        onPrimaryButtonClick={async (): Promise<void> => {
          processProjectParams(async (projectId) => {
            await respondentStore.unassignSurvey(projectId, surveyFilterName);
          }, navigateTo);
          tableRef.current?.reload();
        }}
      >
        {/*
        getDialogChild() is not called like <DialogChild />.
        This is done to avoid giving it its own lifecycle which will result in loss of focus from <TextInputField /> on store updation.
        */}
        {getDialogChild()}
      </Dialog>
    );
  },
);
