import {
  MultiSelectGraphTypeV1,
  MultiSelectResultV1,
  QuestionViewTypeV1,
} from "@pulse/pulse-rpcs";
import { Instance, types } from "mobx-state-tree";
import {
  createSampleBreakdownModel,
  SampleBreakdownModel,
} from "./SampleBreakdownModel";
import { QuestionTypeGraphs } from "../components/ChartSelection";
import { getHundredthDecimalRoundedPercentageString } from "@pulse/shared-components";

const MultiSelectResultV1Model = types.model("MultiSelectResultV1", {
  answer: types.string,
  code: types.string,
  count: types.number,
  percentage: types.string,
});

const createMultiSelectResultV1Model = (
  multiSelectResultV1: MultiSelectResultV1,
): Instance<typeof MultiSelectResultV1Model> => {
  return MultiSelectResultV1Model.create({
    answer: multiSelectResultV1.answer.answer,
    code: multiSelectResultV1.answer.code.code,
    count: multiSelectResultV1.commonResultBreakdownDetails.count.count,
    percentage: getHundredthDecimalRoundedPercentageString(
      multiSelectResultV1.commonResultBreakdownDetails.percentage,
    ),
  });
};

export const MultiSelectQuestionViewModelV1 = types
  .model("MultiSelectQuestionViewModelV1", {
    sampleBreakdown: SampleBreakdownModel,
    multiSelectGraphType: types.enumeration(
      "MultiSelectGraphTypeV1",
      Object.values(MultiSelectGraphTypeV1.MultiSelectGraphTypeV1),
    ),
    updatedMultiSelectGraphType: types.enumeration(
      "MultiSelectGraphTypeV1",
      Object.values(MultiSelectGraphTypeV1.MultiSelectGraphTypeV1),
    ),
    question: types.maybeNull(types.string),
    multiSelectResults: types.array(MultiSelectResultV1Model),
  })
  .views((store) => ({
    get isGraphSelectionUpdated(): boolean {
      return store.updatedMultiSelectGraphType !== store.multiSelectGraphType;
    },
  }))
  .actions((store) => ({
    updateSelectedGraphType: (
      updatedMultiSelectGraphType: QuestionTypeGraphs,
    ): void => {
      store.updatedMultiSelectGraphType =
        MultiSelectGraphTypeV1.MultiSelectGraphTypeV1[
          updatedMultiSelectGraphType as keyof typeof MultiSelectGraphTypeV1.MultiSelectGraphTypeV1
        ];
    },
  }));

export const createMultiSelectQuestionViewModelV1 = (
  multiSelectQuestionView: QuestionViewTypeV1.MultiSelect,
  question: string | null = null,
): Instance<typeof MultiSelectQuestionViewModelV1> => {
  return MultiSelectQuestionViewModelV1.create({
    sampleBreakdown: createSampleBreakdownModel(
      multiSelectQuestionView.sampleBreakdown,
    ),
    multiSelectGraphType: multiSelectQuestionView.multiSelectGraphType,
    updatedMultiSelectGraphType: multiSelectQuestionView.multiSelectGraphType,
    multiSelectResults: multiSelectQuestionView.multiSelectResults.map(
      (multiSelectResult) => createMultiSelectResultV1Model(multiSelectResult),
    ),
    question,
  });
};
