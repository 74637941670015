import { Stack, Typography } from "@mui/material";
import {
  BorderStyle,
  HUNDRED_PERCENTAGE_VALUE,
  PRIMARY_COLORS,
  SINGLE_SPACING,
  SURVEY_RESULTS_BREAKDOWN_BAR_WIDTH_IN_PX,
  VIEWED_SURVEY_LINK_COLOR_TOKEN,
  ZERO_PERCENTAGE_VALUE,
  ZERO_VALUE,
} from "@pulse/shared-components";
import {
  Button,
  CornerRadius,
  FoundationColorTokens,
  Typography as LeoTypography,
  Spacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React from "react";
import { SurveyLinkBreakdownTable } from "./SurveyLinkBreakdownTable";
import { EmptyListPane } from "../../../surveys/components/EmptyListPane";
import { BarChart3, Download } from "lucide-react";
import { DividerComponent } from "../../../surveys/components/commonQuestionConfigurationDetailsComponents/DividerComponent";
import { DownloadReportsDialogState } from "../../store/ReportStore";
import {
  NavigateToFunctions,
  processProjectParams,
} from "../../../../routes/RoutesHelper";
import { UserPrivileges } from "../../../store/user/UserPrivileges";
import { ProjectDetailsStore } from "../../store/ProjectDetailsStore";
import { SignInStore } from "../../../auth/store/SignInStore";

export interface SurveyResultsPaneProps {
  borderRadius: CornerRadius;
  border: BorderStyle;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  spacing: Spacing;
  projectStore: Instance<typeof ProjectDetailsStore>;
  navigateTo: NavigateToFunctions;
  signInStore: Instance<typeof SignInStore>;
  setIsDownloadReportsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SurveyResultsPane = observer(
  ({
    border,
    borderRadius,
    t,
    tokens,
    typography,
    spacing,
    navigateTo,
    projectStore,
    signInStore,
    setIsDownloadReportsDialogOpen,
  }: SurveyResultsPaneProps): React.ReactElement => {
    const surveyDataModel = projectStore.dashboardStore.surveyData;
    const SurveyBreakdownBar = ({
      linkPercentage,
      color,
    }: {
      linkPercentage: number;
      color: string;
    }): React.ReactElement => {
      return (
        <Stack
          bgcolor={color}
          borderRadius={borderRadius.radiusXXS}
          // Here, the width is computed based on the percentage of links and the total width of the breakdown bar.
          width={`${
            SURVEY_RESULTS_BREAKDOWN_BAR_WIDTH_IN_PX *
            (linkPercentage / HUNDRED_PERCENTAGE_VALUE)
          }px`}
        />
      );
    };

    return (
      <Stack borderRight={border.default}>
        <Stack
          maxWidth="464px"
          padding={`${spacing.spaceXL} ${spacing.space2XL}`}
          gap={spacing.spaceXL}
          height="100%"
        >
          <Typography sx={{ ...typography.sh1, color: tokens.label }}>
            {t("projects.dashboard.surveyResultsTitle")}
          </Typography>
          <Typography sx={{ ...typography.h3, color: tokens.label }}>
            {surveyDataModel ? surveyDataModel.totalLinks : ZERO_VALUE}
            {SINGLE_SPACING}
            <Typography
              component="span"
              sx={{ ...typography.b1, color: tokens.labelSubtle }}
            >
              {t("projects.dashboard.invitationsText")}
            </Typography>
          </Typography>
          {surveyDataModel ? (
            <Stack gap={spacing.spaceXS}>
              <Typography
                sx={{ ...typography.ol1, color: tokens.labelLowEmphasis }}
              >
                {t("projects.dashboard.surveyBreakdownText")}
              </Typography>
              <Stack height="8px" direction="row" gap={spacing.spaceXXS}>
                {surveyDataModel.submittedLinks.percentage !==
                  ZERO_PERCENTAGE_VALUE && (
                  <SurveyBreakdownBar
                    linkPercentage={surveyDataModel.submittedLinks.percentage}
                    color={PRIMARY_COLORS[500]}
                  />
                )}
                {surveyDataModel.lastPageLinks.percentage !==
                  ZERO_PERCENTAGE_VALUE && (
                  <SurveyBreakdownBar
                    linkPercentage={surveyDataModel.lastPageLinks.percentage}
                    color={PRIMARY_COLORS[400]}
                  />
                )}
                {surveyDataModel.inProgressLinks.percentage !==
                  ZERO_PERCENTAGE_VALUE && (
                  <SurveyBreakdownBar
                    linkPercentage={surveyDataModel.inProgressLinks.percentage}
                    color={PRIMARY_COLORS[300]}
                  />
                )}
                {surveyDataModel.startedLinks.percentage !==
                  ZERO_PERCENTAGE_VALUE && (
                  <SurveyBreakdownBar
                    linkPercentage={surveyDataModel.startedLinks.percentage}
                    color={PRIMARY_COLORS[200]}
                  />
                )}
                {surveyDataModel.viewedLinks.percentage !==
                  ZERO_PERCENTAGE_VALUE && (
                  <SurveyBreakdownBar
                    linkPercentage={surveyDataModel.viewedLinks.percentage}
                    color={VIEWED_SURVEY_LINK_COLOR_TOKEN}
                  />
                )}
                {surveyDataModel.notStartedLinks.percentage !==
                  ZERO_PERCENTAGE_VALUE && (
                  <SurveyBreakdownBar
                    linkPercentage={surveyDataModel.notStartedLinks.percentage}
                    color={PRIMARY_COLORS[100]}
                  />
                )}
              </Stack>
            </Stack>
          ) : (
            <Stack textAlign="center" height="100%" justifyContent="center">
              <EmptyListPane
                headingText={t("projects.dashboard.noDataTitle")}
                subHeadingText={t(
                  "projects.dashboard.noSurveyBreakdownDescription",
                )}
              />
            </Stack>
          )}

          {surveyDataModel && (
            <SurveyLinkBreakdownTable
              t={t}
              typography={typography}
              tokens={tokens}
              spacing={spacing}
              surveyDataModel={surveyDataModel}
              radius={borderRadius}
            />
          )}
        </Stack>
        <Stack
          borderTop={
            signInStore.isPrivilegeGranted(UserPrivileges.viewSurveyReport) ||
            surveyDataModel
              ? border.default
              : undefined
          }
          direction="row"
          divider={<DividerComponent orientation="vertical" height="100%" />}
        >
          {signInStore.isPrivilegeGranted(UserPrivileges.viewSurveyReport) && (
            <Stack padding={spacing.spaceXS} width="100%">
              <Button
                label={t("projects.reports.downloadReports")}
                variant="plain-color"
                onClick={async (): Promise<void> => {
                  projectStore.reportStore.setDownloadReportsDialogState(
                    DownloadReportsDialogState.DownloadPreference,
                  );
                  setIsDownloadReportsDialogOpen(true);
                }}
                name="downloadReports"
                size="medium"
                icon={<Download />}
                fullWidth={true}
                disabled={projectStore.dashboardStore.isAllSurveyShown}
              />
            </Stack>
          )}
          {surveyDataModel && (
            <Stack padding={spacing.spaceXS} width="100%">
              <Button
                label={t(
                  "projects.reports.embeds.embedView.viewReportButtonText",
                )}
                variant="plain-color"
                onClick={(): void => {
                  processProjectParams(async (projectId): Promise<void> => {
                    if (projectStore.dashboardStore.selectedSurvey) {
                      navigateTo.report(
                        projectId,
                        projectStore.dashboardStore.selectedSurvey?.surveyId,
                      );
                    } else {
                      console.error("No survey id found");
                      navigateTo.internalServerError();
                    }
                  }, navigateTo);
                }}
                name="viewReports"
                size="medium"
                icon={<BarChart3 />}
                fullWidth={true}
                disabled={projectStore.dashboardStore.isAllSurveyShown}
              />
            </Stack>
          )}
        </Stack>
      </Stack>
    );
  },
);
