import { Bar, BarCustomLayerProps, BarDatum } from "@nivo/bar";
import React, { ReactElement } from "react";
import { GraphData } from "../SelectedGraph";
import {
  DASHBOARD_CHART_PRIMARY_COLORS,
  PROPORTION_GRAPH_LABEL_SKIP_WIDTH_IN_PIXELS,
  QUESTION_VIEW_MAX_WIDTH_IN_PIXELS,
  RAG_PALETTE_COLORS,
} from "../../../utils/constants";
import { DataProps } from "../DataSection";
import { BorderStyle, EMPTY_CHARACTER } from "@pulse/shared-components";
import { Stack } from "@mui/material";
import { Legends } from "./Legends";
import {
  CornerRadius,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TooltipContent } from "./TooltipContent";
import { CustomWidthTooltip } from "../../../surveys/components/CustomWidthToolTip";

interface ProportionGraphProps {
  data: DataProps[];
  isNeutral: boolean | undefined;
  spacing: Spacing;
  cornerRadius: CornerRadius;
  tokens: FoundationColorTokens<string>;
  border: BorderStyle;
  typography: Typography;
  isLegendsVisible: boolean;
  graphWidth: number | undefined;
}
export const ProportionGraph = ({
  data,
  isNeutral,
  cornerRadius,
  spacing,
  tokens,
  border,
  typography,
  isLegendsVisible,
  graphWidth,
}: ProportionGraphProps): ReactElement => {
  const graphData: GraphData[] = data.map((item, index) => {
    return {
      id: item.option ?? EMPTY_CHARACTER,
      label: (item.option ?? EMPTY_CHARACTER) + index,
      value: item.count,
      percentage: item.percentage,
    };
  });
  const transformedDataForProportion: BarDatum[] = [
    {
      ...Object.fromEntries(
        graphData
          .filter((item) => item.value > 0) // Filter out items with value 0
          .map((item) => [item.label, item.value]),
      ),
    },
  ];
  const getSevenScalePaletteColors = (
    isDarkMode: boolean,
    numberOfOptions: number,
    optionIndex: number | undefined,
    isDarkGradientPalette: boolean,
  ): string | undefined => {
    if (numberOfOptions === 5) {
      switch (optionIndex) {
        case 0: {
          return isDarkMode
            ? isDarkGradientPalette
              ? RAG_PALETTE_COLORS.FAR_LEFT.darkMode.selected
              : RAG_PALETTE_COLORS.FAR_LEFT.darkMode.unselected
            : isDarkGradientPalette
              ? RAG_PALETTE_COLORS.FAR_LEFT.lightMode.selected
              : RAG_PALETTE_COLORS.FAR_LEFT.lightMode.unselected;
        }
        case 1: {
          return isDarkMode
            ? isDarkGradientPalette
              ? RAG_PALETTE_COLORS.LEFT.darkMode.selected
              : RAG_PALETTE_COLORS.LEFT.darkMode.unselected
            : isDarkGradientPalette
              ? RAG_PALETTE_COLORS.LEFT.lightMode.selected
              : RAG_PALETTE_COLORS.LEFT.lightMode.unselected;
        }
        case 2: {
          return isDarkMode
            ? isDarkGradientPalette
              ? RAG_PALETTE_COLORS.MIDDLE.darkMode.selected
              : RAG_PALETTE_COLORS.MIDDLE.darkMode.unselected
            : isDarkGradientPalette
              ? RAG_PALETTE_COLORS.MIDDLE.lightMode.selected
              : RAG_PALETTE_COLORS.MIDDLE.lightMode.unselected;
        }
        case 3: {
          return isDarkMode
            ? isDarkGradientPalette
              ? RAG_PALETTE_COLORS.RIGHT.darkMode.selected
              : RAG_PALETTE_COLORS.RIGHT.darkMode.unselected
            : isDarkGradientPalette
              ? RAG_PALETTE_COLORS.RIGHT.lightMode.selected
              : RAG_PALETTE_COLORS.RIGHT.lightMode.unselected;
        }
        case 4: {
          return isDarkMode
            ? isDarkGradientPalette
              ? RAG_PALETTE_COLORS.FAR_RIGHT.darkMode.selected
              : RAG_PALETTE_COLORS.FAR_RIGHT.darkMode.selected
            : isDarkGradientPalette
              ? RAG_PALETTE_COLORS.FAR_RIGHT.lightMode.selected
              : RAG_PALETTE_COLORS.FAR_RIGHT.lightMode.unselected;
        }
      }
    } else if (numberOfOptions === 7) {
      switch (optionIndex) {
        case 0: {
          return isDarkMode
            ? isDarkGradientPalette
              ? RAG_PALETTE_COLORS.LEFT_MOST.darkMode.selected
              : RAG_PALETTE_COLORS.LEFT_MOST.darkMode.unselected
            : isDarkGradientPalette
              ? RAG_PALETTE_COLORS.LEFT_MOST.lightMode.selected
              : RAG_PALETTE_COLORS.LEFT_MOST.lightMode.unselected;
        }
        case 1: {
          return isDarkMode
            ? isDarkGradientPalette
              ? RAG_PALETTE_COLORS.FAR_LEFT.darkMode.selected
              : RAG_PALETTE_COLORS.FAR_LEFT.darkMode.unselected
            : isDarkGradientPalette
              ? RAG_PALETTE_COLORS.FAR_LEFT.lightMode.selected
              : RAG_PALETTE_COLORS.FAR_LEFT.lightMode.unselected;
        }
        case 2: {
          return isDarkMode
            ? isDarkGradientPalette
              ? RAG_PALETTE_COLORS.LEFT.darkMode.selected
              : RAG_PALETTE_COLORS.LEFT.darkMode.unselected
            : isDarkGradientPalette
              ? RAG_PALETTE_COLORS.LEFT.lightMode.selected
              : RAG_PALETTE_COLORS.LEFT.lightMode.unselected;
        }
        case 3: {
          return isDarkMode
            ? isDarkGradientPalette
              ? RAG_PALETTE_COLORS.MIDDLE.darkMode.selected
              : RAG_PALETTE_COLORS.MIDDLE.darkMode.unselected
            : isDarkGradientPalette
              ? RAG_PALETTE_COLORS.MIDDLE.lightMode.selected
              : RAG_PALETTE_COLORS.MIDDLE.lightMode.unselected;
        }
        case 4: {
          return isDarkMode
            ? isDarkGradientPalette
              ? RAG_PALETTE_COLORS.RIGHT.darkMode.selected
              : RAG_PALETTE_COLORS.RIGHT.darkMode.unselected
            : isDarkGradientPalette
              ? RAG_PALETTE_COLORS.RIGHT.lightMode.selected
              : RAG_PALETTE_COLORS.RIGHT.lightMode.unselected;
        }
        case 5: {
          return isDarkMode
            ? isDarkGradientPalette
              ? RAG_PALETTE_COLORS.FAR_RIGHT.darkMode.selected
              : RAG_PALETTE_COLORS.FAR_RIGHT.darkMode.unselected
            : isDarkGradientPalette
              ? RAG_PALETTE_COLORS.FAR_RIGHT.lightMode.selected
              : RAG_PALETTE_COLORS.FAR_RIGHT.lightMode.unselected;
        }
        case 6: {
          return isDarkMode
            ? isDarkGradientPalette
              ? RAG_PALETTE_COLORS.RIGHT_MOST.darkMode.selected
              : RAG_PALETTE_COLORS.RIGHT_MOST.darkMode.unselected
            : isDarkGradientPalette
              ? RAG_PALETTE_COLORS.RIGHT_MOST.lightMode.selected
              : RAG_PALETTE_COLORS.RIGHT_MOST.lightMode.unselected;
        }
      }
    }
  };
  const getDarkGradientColorForIndexPosition = (
    optionIndex: number | undefined,
    numberOfOptions: number,
    isDarkMode: boolean,
  ): string => {
    if (optionIndex === undefined) {
      return "unset";
    }
    const sevenScalePaletteColor = getSevenScalePaletteColors(
      isDarkMode,
      numberOfOptions,
      optionIndex,
      true,
    );
    if (sevenScalePaletteColor !== undefined) {
      return sevenScalePaletteColor;
    }
    let percentage = optionIndex / numberOfOptions;
    if (percentage <= 0.5) {
      percentage = percentage * 2.0;
      return isDarkMode
        ? `rgba(${247 + 8 * percentage}, ${112 + 78 * percentage}, ${
            61 + 45 * (1 - percentage)
          }, 1)`
        : `rgba(${204 + 51 * percentage}, ${30 + 140 * percentage}, ${
            44 * (1 - percentage)
          }, 1)`;
    } else {
      percentage = (percentage - 0.5) * 2.0;
      return isDarkMode
        ? `rgba(${62 + 193 * (1 - percentage)}, ${175 + 15 * (1 - percentage)}, ${
            61 + 29 * percentage
          }, 1)`
        : `rgba(${255 * (1 - percentage)}, ${121 + 49 * percentage}, ${
            47 * percentage
          }, 1)`;
    }
  };
  const colors = isNeutral
    ? DASHBOARD_CHART_PRIMARY_COLORS
    : graphData.map((item, index) => {
        return getDarkGradientColorForIndexPosition(
          index,
          graphData.length,
          false,
        );
      });

  const CustomLabelLayer = (
    props: BarCustomLayerProps<BarDatum>,
  ): ReactElement => {
    const textColor = (color: string): string => {
      if (isNeutral) {
        switch (color) {
          case DASHBOARD_CHART_PRIMARY_COLORS[0]:
          case DASHBOARD_CHART_PRIMARY_COLORS[1]:
          case DASHBOARD_CHART_PRIMARY_COLORS[2]:
          case DASHBOARD_CHART_PRIMARY_COLORS[3]:
          case DASHBOARD_CHART_PRIMARY_COLORS[4]:
          case DASHBOARD_CHART_PRIMARY_COLORS[5]: {
            return tokens.labelHighEmphasis;
          }
          default: {
            return tokens.labelOnSurface;
          }
        }
      } else {
        switch (color) {
          case RAG_PALETTE_COLORS.LEFT.darkMode.selected:
          case RAG_PALETTE_COLORS.LEFT.darkMode.unselected:
          case RAG_PALETTE_COLORS.LEFT.lightMode.selected:
          case RAG_PALETTE_COLORS.LEFT.lightMode.unselected:
          case RAG_PALETTE_COLORS.MIDDLE.darkMode.selected:
          case RAG_PALETTE_COLORS.MIDDLE.darkMode.unselected:
          case RAG_PALETTE_COLORS.MIDDLE.lightMode.selected:
          case RAG_PALETTE_COLORS.MIDDLE.lightMode.unselected: {
            return tokens.labelHighEmphasis;
          }
          default: {
            return tokens.labelOnSurface;
          }
        }
      }
    };

    const percentage = (index: number): string | undefined => {
      return graphData.find(
        (graphDataItem) =>
          graphDataItem.label.slice(0, -String(index).length) ===
          `${props.bars[index].data.id}`.slice(0, -String(index).length),
      )?.percentage;
    };
    return (
      <g transform="translate(0, 0)">
        {graphData.map((item, index) =>
          props.bars[index].width &&
          props.bars[index].width >=
            PROPORTION_GRAPH_LABEL_SKIP_WIDTH_IN_PIXELS ? (
            <foreignObject
              key={index}
              width={props.bars[index].width}
              height={props.bars[index].height}
              x={props.bars[index].x}
              y={props.bars[index].y}
            >
              <CustomWidthTooltip
                title={
                  <TooltipContent
                    spacing={spacing}
                    cornerRadius={cornerRadius}
                    tokens={tokens}
                    count={`${item.value}`}
                    label={`${item.label.slice(0, -String(index).length)}`}
                    percentage={`${item.percentage}`}
                    border={border}
                    typography={typography}
                  />
                }
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: tokens.backgroundElevatedLevel1,
                      padding: "0px !important",
                    },
                  },
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  {PROPORTION_GRAPH_LABEL_SKIP_WIDTH_IN_PIXELS && (
                    <>
                      {" "}
                      <text
                        style={{
                          color: textColor(props.bars[index].color),
                        }}
                      >
                        {props.bars[index].data.value}
                      </text>
                      <text
                        style={{
                          color: textColor(props.bars[index].color),
                        }}
                      >
                        {percentage ? `${percentage(index)}%` : EMPTY_CHARACTER}
                      </text>
                    </>
                  )}
                </Stack>
              </CustomWidthTooltip>
            </foreignObject>
          ) : (
            <foreignObject
              key={index}
              width={props.bars[index].width}
              height={props.bars[index].height}
              x={props.bars[index].x}
              y={props.bars[index].y}
            >
              <CustomWidthTooltip
                title={
                  <TooltipContent
                    spacing={spacing}
                    cornerRadius={cornerRadius}
                    tokens={tokens}
                    count={`${item.value}`}
                    label={`${item.label.slice(0, -String(index).length)}`}
                    percentage={`${item.percentage}`}
                    border={border}
                    typography={typography}
                  />
                }
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: tokens.backgroundElevatedLevel1,
                      padding: "0px !important",
                    },
                  },
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                ></Stack>
              </CustomWidthTooltip>
            </foreignObject>
          ),
        )}
      </g>
    );
  };
  return (
    <Stack width={`${QUESTION_VIEW_MAX_WIDTH_IN_PIXELS}px`}>
      <Bar
        data={transformedDataForProportion}
        keys={graphData.map((item) => item.label)}
        height={200}
        width={graphWidth ?? QUESTION_VIEW_MAX_WIDTH_IN_PIXELS}
        margin={{ top: 20, right: 0, bottom: 50, left: 0 }}
        layout="horizontal"
        animate={true}
        groupMode="stacked"
        colors={colors}
        indexBy="count"
        axisBottom={null}
        axisLeft={null}
        enableLabel={false}
        labelFormat={(value): string => `${value}%`}
        layers={[
          "grid",
          "axes",
          "bars",
          "totals",
          "markers",
          "annotations",
          CustomLabelLayer,
        ]}
        tooltip={() => {
          return <></>;
        }}
      />
      {isLegendsVisible && (
        <Legends
          legends={graphData.map((item, index) =>
            item.label.slice(0, -String(index).length),
          )}
          spacing={spacing}
          colors={colors}
          cornerRadius={cornerRadius}
        />
      )}
    </Stack>
  );
};
