import React, { ReactElement } from "react";
import {
  CornerRadius,
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  BorderStyle,
  RICH_TEXT_MARKDOWN_PROPS,
  richTextTypography,
} from "@pulse/shared-components";
import {
  DataProps,
  DataPropsForGridStackedAndGroupedBar,
} from "../DataSection";
import { ProportionGraph } from "../graphs/ProportionGraph";
import { CustomWordcloud } from "../graphs/CustomWordcloud";
import { StackedColumnGroupedBarGraph } from "../graphs/StackedColumnGroupedBarGraph";
import { Stack, Typography } from "@mui/material";
import { RichTextEditor } from "@surya-digital/leo-reactjs-remirror";
import { TFunction } from "i18next";
import { QuestionTypeGraphs } from "../ChartSelection";
import { DeveloperErrorFlow } from "../../../../components/DeveloperErrorFlow";
import { CHILD_QUESTION_VIEW_MAX_WIDTH_IN_PIXELS } from "../../../utils/constants";

interface GridSelectedGraphProps {
  selectedGraph: QuestionTypeGraphs;
  data: DataPropsForGridStackedAndGroupedBar[] | DataProps[][] | DataProps[];
  isNeutral?: boolean;
  spacing: Spacing;
  cornerRadius: CornerRadius;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  border: BorderStyle;
  gridSingleSelectRowOptions: string[] | undefined;
  t: TFunction;
  isChildQuestion: boolean;
  naOptionCode?: string | null;
}

export const GridSelectedGraph = ({
  selectedGraph,
  data,
  isNeutral,
  spacing,
  cornerRadius,
  tokens,
  typography,
  border,
  gridSingleSelectRowOptions,
  t,
  isChildQuestion,
  naOptionCode = null,
}: GridSelectedGraphProps): ReactElement => {
  switch (selectedGraph) {
    case QuestionTypeGraphs.PROPORTION: {
      const isMeanPresent = (index: number): boolean => {
        return Boolean(
          Array.isArray(data[index]) &&
            data[index].length > 0 &&
            data[index][0].mean,
        );
      };
      return (
        <Stack>
          {data.map((dataForGraph, index) => (
            <Stack key={index}>
              {gridSingleSelectRowOptions && (
                <Stack direction="row" justifyContent="space-between">
                  <RichTextEditor
                    name={gridSingleSelectRowOptions[index]}
                    typography={richTextTypography()}
                    mode="preview"
                    borderOnPreview={false}
                    initialValue={gridSingleSelectRowOptions[index]}
                    supports={RICH_TEXT_MARKDOWN_PROPS}
                  />
                  {isMeanPresent(index) && (
                    <Typography
                      {...typography.b2}
                      color={tokens.label}
                      paddingRight={spacing.spaceXL}
                    >
                      {t("surveyDashboard.mean")}
                    </Typography>
                  )}
                </Stack>
              )}
              <Stack
                direction="row"
                width="100%"
                height="fit-content"
                justifyContent="center"
                alignItems="center"
                gap={spacing.spaceSM}
              >
                <ProportionGraph
                  data={
                    naOptionCode !== null
                      ? (dataForGraph as DataProps[]).filter(
                          (item) => item.id !== naOptionCode,
                        )
                      : (dataForGraph as DataProps[])
                  }
                  isNeutral={isNeutral}
                  spacing={spacing}
                  cornerRadius={cornerRadius}
                  tokens={tokens}
                  border={border}
                  typography={typography}
                  isLegendsVisible={data.length === index + 1}
                  graphWidth={isMeanPresent(index) ? 820 : undefined}
                />
                {
                  // We cannot use `isMeanPresent(index)` here as the condition is required here for type safety.
                  Array.isArray(dataForGraph) &&
                    dataForGraph.length > 0 &&
                    dataForGraph[0].mean && (
                      <Stack
                        height="100%"
                        width="100%"
                        gap={spacing.spaceSM}
                        paddingTop={spacing.space2XL}
                      >
                        <Stack
                          justifyContent="center"
                          height="106px"
                          width="72px"
                          padding={spacing.spaceXS}
                          sx={{
                            backgroundColor: tokens.backgroundSubtle,
                          }}
                        >
                          <Typography
                            {...typography.sh4}
                            color={tokens.labelHighEmphasis}
                          >
                            {dataForGraph[0].mean}
                          </Typography>
                        </Stack>
                      </Stack>
                    )
                }
              </Stack>
            </Stack>
          ))}
        </Stack>
      );
    }
    case QuestionTypeGraphs.WORD_CLOUD: {
      return <CustomWordcloud graphData={data as DataProps[]} />;
    }
    case QuestionTypeGraphs.STACKED_COLUMN: {
      return (
        <StackedColumnGroupedBarGraph
          data={data as DataPropsForGridStackedAndGroupedBar[]}
          spacing={spacing}
          cornerRadius={cornerRadius}
          tokens={tokens}
          border={border}
          typography={typography}
          isStackedBarGraph={true}
          graphWidth={
            isChildQuestion
              ? CHILD_QUESTION_VIEW_MAX_WIDTH_IN_PIXELS
              : undefined
          }
        />
      );
    }
    case QuestionTypeGraphs.GROUPED_BAR: {
      return (
        <StackedColumnGroupedBarGraph
          data={data as DataPropsForGridStackedAndGroupedBar[]}
          spacing={spacing}
          cornerRadius={cornerRadius}
          tokens={tokens}
          border={border}
          typography={typography}
          isStackedBarGraph={false}
          graphWidth={
            isChildQuestion
              ? CHILD_QUESTION_VIEW_MAX_WIDTH_IN_PIXELS
              : undefined
          }
        />
      );
    }
    case QuestionTypeGraphs.NONE: {
      return <></>;
    }
    case QuestionTypeGraphs.DONUT:
    case QuestionTypeGraphs.GAUGE:
    case QuestionTypeGraphs.HORIZONTAL_BAR:
    case QuestionTypeGraphs.LINE:
    case QuestionTypeGraphs.LINE_AREA:
    case QuestionTypeGraphs.PIE:
    case QuestionTypeGraphs.VERTICAL_BAR: {
      return <DeveloperErrorFlow />;
    }
  }
};
