import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import { DetailSectionComponent } from "../../../components/DetailSectionComponent";
import { BriefcaseIcon, HomeIcon, Plus } from "lucide-react";
import {
  Button,
  LoadingIndicator,
  TableReloadHandle,
  TabProps,
  Tabs,
  useCornerRadius,
  useFoundationColorTokens,
  useShadow,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  useProjectsRootStore,
  useRespondentStore,
  useSignInStore,
} from "../../store/Hooks";
import {
  processProjectParams,
  useRouteNavigator,
} from "../../../routes/RoutesHelper";
import {
  EMPTY_CHARACTER,
  ICON_SIZE,
  getIconProps,
  getProjectDateRangeChipString,
  useBorder,
} from "@pulse/shared-components";
import { FeatureInDevelopmentSnackbar } from "@pulse/shared-components";
import { UserPrivileges } from "../../store/user/UserPrivileges";
import { useReportStore } from "../store/hooks";
import { useNetworkingErrorStore } from "../../networking/store/hooks";
import { navigateOrGetNetworkErrorPage } from "../../../utility/navigateOrGetNetworkErrorPage";
import { DownloadReportsDialog } from "../components/downloadReports/DownloadReportsDialog";
import { CreateSurveyDialog } from "../components/CreateSurveyDialog";
import { getRespondentSectionHeader } from "../components/RespondentSectionHeader";
import { SurveyResultsDashboard } from "../components/SurveyResultsDashboard";
import { SurveyDetailsList } from "../components/SurveyDetailsList";
import { RespondentsPane } from "../components/RespondentsPane";
import { UserAccessTabElement } from "../components/userAccess/UserAccessTabElement";
import { DeveloperErrorFlow } from "../../../components/DeveloperErrorFlow";
import { AddSPoCDialog } from "../components/userAccess/AddSPoCDialog";
import { UserAccessTabs } from "../store/UserAccessStore";
import { getFullySpacedString } from "../utils/StringUtils";
import { AddNewEmbedDialog } from "../components/embed/AddNewEmbedDialog";
import { AddProjectBOUDialog } from "../components/userAccess/AddProjectBOUDialog";
import { ProjectSettingsPane } from "../components/ProjectSettings/ProjectSettingsPane";
import { CloneSurveyDialog } from "../components/surveyDetails/CloneSurveyDialog";
import {
  getProjectDetailsPageTabs,
  setURLTabParam,
} from "../utils/ProjectDetailsTabUtils";

export enum ProjectDetailsTabs {
  Survey = "survey",
  Respondent = "respondent",
  Dashboard = "dashboard",
  UserAccess = "userAccess",
  Settings = "settings",
}

export const ProjectDetailsPage = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const navigateTo = useRouteNavigator();
  const projectRootStore = useProjectsRootStore();
  const respondentStore = useRespondentStore();
  const signInStore = useSignInStore();
  const spacing = useSpacing();
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const errorStore = useNetworkingErrorStore();
  const [isDownloadReportsDialogOpen, setIsDownloadReportsDialogOpen] =
    useState(false);
  const tokens = useFoundationColorTokens();
  const reportStore = useReportStore();
  const [isCreateSurveyDialogOpen, setIsCreateSurveyDialogOpen] =
    useState(false);
  const [isCloneSurveyDialogOpen, setIsCloneSurveyDialogOpen] = useState(false);
  const [selectedSurveyId, setSelectedSurveyId] = useState<string | null>(null);
  const [selectedSurveyName, setSelectedSurveyName] = useState<string | null>(
    null,
  );
  const typography = useTypography();
  const borderRadius = useCornerRadius();
  const border = useBorder();
  const [isChildHeightVariable, setIsChildHeightVariable] = useState(false);
  const boxShadow = useShadow();
  const respondentTableRef = useRef<TableReloadHandle | null>(null);
  const userAccessTableRef = useRef<TableReloadHandle | null>(null);
  const [isAddNewEmbedDialogOpen, setIsAddNewEmbedDialogOpen] = useState(false);
  const [isProjectDateRangeInputInvalid, setIsProjectDateRangeInputInvalid] =
    useState(false);
  const projectDetailsStore = projectRootStore.projectDetailsStore;
  const userVisibleTabs = getProjectDetailsPageTabs(t);

  const getDefaultTab = (): ProjectDetailsTabs | undefined => {
    const selectedURLTab = new URLSearchParams(location.search).get("tab");
    let defaultTab;
    if (selectedURLTab !== null) {
      defaultTab = userVisibleTabs.find((tabValue) => {
        return tabValue.type === selectedURLTab;
      })?.type;
      if (defaultTab === undefined) {
        navigateTo.pageNotFound();
      }
    } else {
      navigateTo.pageNotFound();
    }
    return defaultTab;
  };

  const [selectedTab, setSelectedTab] = useState<ProjectDetailsTabs>();

  useEffect(() => {
    const defaultTab = getDefaultTab();
    if (defaultTab !== undefined) {
      setSelectedTab(defaultTab);
    }
    return () => {
      projectDetailsStore.resetProjectDetails();
      projectDetailsStore.clearRPCError();
      projectDetailsStore.surveyStore.resetSurveyName();
      errorStore.removeError();
    };
  }, []);

  const callRpcBasedOnTab = async (): Promise<void> => {
    switch (selectedTab) {
      case ProjectDetailsTabs.Survey: {
        await processProjectParams(
          projectDetailsStore.getProjectSurveyDetails,
          navigateTo,
        );
        break;
      }
      case ProjectDetailsTabs.Dashboard: {
        processProjectParams(async (projectId) => {
          projectDetailsStore.dashboardStore.setIsRPCLoading(true);
          await projectDetailsStore.getProjectSurveyNames(projectId);
          const projectDetails =
            await projectDetailsStore.dashboardStore.getProjectSurveyResults(
              projectId,
            );
          if (projectDetails !== undefined) {
            projectDetailsStore.setProjectDetails(projectDetails);
          }
          projectDetailsStore.dashboardStore.setIsRPCLoading(false);
        }, navigateTo);
        break;
      }
      case ProjectDetailsTabs.UserAccess:
      case ProjectDetailsTabs.Settings: {
        if (projectDetailsStore.projectName === EMPTY_CHARACTER) {
          await processProjectParams(
            projectDetailsStore.getProjectDetails,
            navigateTo,
          );
        }
        break;
      }
    }
  };

  useEffect(() => {
    respondentStore.setIsRespondentUpdateStatusInProgress(false);
    respondentStore.multiSelectTableStore.unselectAllRows();
    respondentStore.resetIsGetRespondentsRPCCalled();
    respondentStore.resetIsLiveRespondentsSelected();
    projectDetailsStore.dashboardStore.setSelectedSurvey(null);
    projectDetailsStore.dashboardStore.setIsGetProjectSurveyResultsRPCCalled(
      false,
    );
    callRpcBasedOnTab();

    return () => {
      projectDetailsStore.userAccessStore.resetSelectedTab();
    };
  }, [selectedTab]);

  const errorPage = navigateOrGetNetworkErrorPage(
    errorStore.error,
    navigateTo,
    errorStore.removeError,
  );

  if (errorPage) {
    errorStore.removeError();
    return errorPage;
  }

  const TabElement = ({
    index,
  }: {
    index: ProjectDetailsTabs;
  }): React.ReactElement => {
    switch (index) {
      case ProjectDetailsTabs.Survey:
        return (
          <SurveyDetailsList
            t={t}
            navigateTo={navigateTo}
            setIsCreateSurveyDialogOpen={(): void => {
              projectDetailsStore.clearSurveyNameAndRPCError();
              setIsCreateSurveyDialogOpen(true);
            }}
            spacing={spacing}
            border={border}
            borderRadius={borderRadius}
            tokens={tokens}
            typography={typography}
            setIsChildHeightVariable={setIsChildHeightVariable}
            surveyStore={projectDetailsStore.surveyStore}
            setSelectedSurveyId={setSelectedSurveyId}
            setSelectedSurveyName={setSelectedSurveyName}
            setIsCloneSurveyDialogOpen={setIsCloneSurveyDialogOpen}
          />
        );
      case ProjectDetailsTabs.Respondent:
        return (
          <RespondentsPane
            isSurveyFilterShown={true}
            respondentStore={respondentStore}
            spacing={spacing}
            t={t}
            tokens={tokens}
            typography={typography}
            surveyFilterName={undefined}
            tableRef={respondentTableRef}
          />
        );
      case ProjectDetailsTabs.Dashboard:
        return (
          <SurveyResultsDashboard
            projectStore={projectDetailsStore}
            navigateTo={navigateTo}
            spacing={spacing}
            t={t}
            tokens={tokens}
            typography={typography}
            borderRadius={borderRadius}
            border={border}
            setIsChildHeightVariable={setIsChildHeightVariable}
            setIsDownloadReportsDialogOpen={setIsDownloadReportsDialogOpen}
            signInStore={signInStore}
          />
        );
      case ProjectDetailsTabs.UserAccess:
        return projectDetailsStore.areProjectDetailsEmpty ? (
          <LoadingIndicator
            isLoading={projectDetailsStore.isRPCLoading}
            variant="overlay"
          />
        ) : (
          <UserAccessTabElement
            userAccessStore={projectDetailsStore.userAccessStore}
            t={t}
            navigateTo={navigateTo}
            tokens={tokens}
            typography={typography}
            spacing={spacing}
            signInStore={signInStore}
            userAccessTableRef={userAccessTableRef}
          />
        );
      case ProjectDetailsTabs.Settings:
        return projectDetailsStore.areProjectDetailsEmpty ? (
          <LoadingIndicator
            isLoading={projectDetailsStore.isRPCLoading}
            variant="overlay"
          />
        ) : (
          <ProjectSettingsPane
            projectRootStore={projectRootStore}
            spacing={spacing}
            t={t}
            tokens={tokens}
            typography={typography}
            setIsProjectDateRangeInputInvalid={
              setIsProjectDateRangeInputInvalid
            }
          />
        );
      default:
        return <DeveloperErrorFlow />;
    }
  };

  const isUpdateRespondentPrivilegeGranted = signInStore.isPrivilegeGranted(
    UserPrivileges.processRespondent,
  );

  const getUserAccessTabs = (): TabProps[] => {
    const userTabs: TabProps[] = [];
    if (signInStore.isPrivilegeGranted(UserPrivileges.viewProjectBOUs)) {
      userTabs.push({ label: t("projects.userAccess.manageBOU") });
    }
    if (signInStore.isPrivilegeGranted(UserPrivileges.viewSpocs)) {
      userTabs.push({ label: t("projects.userAccess.manageSPoCs") });
    }
    return userTabs;
  };

  const BOUserActionElement = observer((): React.ReactElement => {
    const [isAddBOUDialogOpen, setIsAddBOUDialogOpen] = useState(false);

    return (
      <>
        <Button
          icon={<Plus />}
          iconPosition="leading"
          label={t("projects.userAccess.addBOUButtonText")}
          name={t("projects.userAccess.addBOUButtonText")}
          variant="filled"
          size="medium"
          onClick={async (): Promise<void> => {
            setIsAddBOUDialogOpen(true);
            await projectDetailsStore.userAccessStore.addProjectBOUStore.getBOUserDetails();
          }}
        />
        {isAddBOUDialogOpen && (
          <AddProjectBOUDialog
            isAddBOUDialogOpen={isAddBOUDialogOpen}
            setIsAddBOUDialogOpen={setIsAddBOUDialogOpen}
            t={t}
            spacing={spacing}
            addNewSPoCStore={
              projectDetailsStore.userAccessStore.addProjectBOUStore
            }
            tokens={tokens}
            typography={typography}
            border={border}
            navigateTo={navigateTo}
            tableRef={userAccessTableRef}
          />
        )}
      </>
    );
  });

  const SpocActionElement = observer((): React.ReactElement => {
    const [isAddSPoCDialogOpen, setIsAddSPoCDialogOpen] = useState(false);

    return (
      <>
        <Button
          icon={<Plus />}
          iconPosition="leading"
          label={t("projects.userAccess.addSPoCButtontext")}
          name={t("projects.userAccess.addSPoCButtontext")}
          variant="filled"
          size="medium"
          onClick={(): void => {
            setIsAddSPoCDialogOpen(true);
          }}
        />
        {isAddSPoCDialogOpen && (
          <AddSPoCDialog
            isAddSPoCDialogOpen={isAddSPoCDialogOpen}
            setIsAddSPoCDialogOpen={setIsAddSPoCDialogOpen}
            t={t}
            spacing={spacing}
            addNewSPoCStore={
              projectDetailsStore.userAccessStore.addNewSPoCStore
            }
            navigateTo={navigateTo}
            tokens={tokens}
            typography={typography}
            tableRef={userAccessTableRef}
          />
        )}
      </>
    );
  });

  const getUserAccessActionElement = (): React.ReactElement | undefined => {
    switch (projectDetailsStore.userAccessStore.selectedTab) {
      case UserAccessTabs.ManageBOU: {
        if (
          signInStore.isPrivilegeGranted(UserPrivileges.processProjectBOUser)
        ) {
          return <BOUserActionElement />;
        } else {
          return;
        }
      }
      case UserAccessTabs.ManageSPoCs: {
        if (signInStore.isPrivilegeGranted(UserPrivileges.processSpocUser)) {
          return <SpocActionElement />;
        } else {
          return;
        }
      }
    }
  };

  const SettingsActionElement = observer((): React.ReactElement => {
    return (
      <Button
        label={t("common.saveChangesButtonText")}
        name={t("common.saveChangesButtonText")}
        variant="filled"
        size="medium"
        onClick={async (): Promise<void> => {
          if (
            projectDetailsStore.updateProjectDetailsStore.isProjectNameValid()
          ) {
            await processProjectParams(
              projectDetailsStore.updateProjectDetailsStore
                .updateProjectDetails,
              navigateTo,
            );
          }
        }}
        disabled={
          isProjectDateRangeInputInvalid ||
          projectDetailsStore.updateProjectDetailsStore
            .isSaveChangesButtonDisabled ||
          projectDetailsStore.isUpdateProjectDetailsSaveChangesButtonDisabled
        }
      />
    );
  });

  return selectedTab ? (
    <>
      {isCreateSurveyDialogOpen && (
        <CreateSurveyDialog
          isCreateSurveyDialogOpen={isCreateSurveyDialogOpen}
          t={t}
          spacing={spacing}
          tokens={tokens}
          typography={typography}
          projectStore={projectDetailsStore}
          navigateTo={navigateTo}
          setIsCreateSurveyDialogOpen={setIsCreateSurveyDialogOpen}
        />
      )}

      {isCloneSurveyDialogOpen && (
        <CloneSurveyDialog
          isCloneSurveyDialogOpen={isCloneSurveyDialogOpen}
          setIsCloneSurveyDialogOpen={setIsCloneSurveyDialogOpen}
          t={t}
          spacing={spacing}
          tokens={tokens}
          typography={typography}
          projectStore={projectDetailsStore}
          navigateTo={navigateTo}
          surveyId={selectedSurveyId}
          surveyName={selectedSurveyName}
        />
      )}

      <DetailSectionComponent
        isManageUsersLogoVisible={true}
        isAuditLogsLogoVisible={true}
        mainHeaderProps={{
          title: getFullySpacedString(projectDetailsStore.projectName),
          icon: (
            <BriefcaseIcon
              {...getIconProps(tokens.iconSubtle, ICON_SIZE.default)}
            />
          ),
          chipProps: {
            label:
              projectDetailsStore.projectStartDate &&
              projectDetailsStore.projectEndDate
                ? getProjectDateRangeChipString(
                    projectDetailsStore.projectStartDate,
                    projectDetailsStore.projectEndDate,
                  )
                : EMPTY_CHARACTER,
            color: "neutral",
          },
          prefersLargeTitle: true,
        }}
        breadcrumbProps={{
          links: [
            {
              label: t("projects.projects"),
              icon: <HomeIcon />,
              onLabelClick: navigateTo.project,
            },
          ],
          currentLabel: projectDetailsStore.projectName,
          currentLabelIcon: <BriefcaseIcon />,
        }}
        isChildHeightVariable={isChildHeightVariable}
        tabsProps={{
          tabs: userVisibleTabs,
          selectedTabIndex: userVisibleTabs.findIndex((tab) => {
            return tab.type === selectedTab;
          }),
          onTabChange: (selectedIndex: number): void => {
            setIsChildHeightVariable(false);
            const selectedTabType = userVisibleTabs.find((_, index) => {
              return index === selectedIndex;
            });
            if (selectedTabType === undefined) {
              console.error(
                `The selected tab could not be found for index: ${selectedIndex}`,
              );
              return;
            }
            setURLTabParam(selectedTabType.type);
            setSelectedTab(selectedTabType.type);
          },
          disableBottomBorder: true,
        }}
        {...(selectedTab === ProjectDetailsTabs.Respondent && {
          sectionHeaderProps: getRespondentSectionHeader(
            respondentStore,
            isUpdateRespondentPrivilegeGranted,
            respondentStore.respondentUploadStore,
            tokens,
            projectDetailsStore,
            navigateTo,
            typography,
            borderRadius,
            spacing,
            border,
            boxShadow,
            respondentTableRef,
            signInStore,
          ),
        })}
        {...(selectedTab === ProjectDetailsTabs.Survey && {
          sectionHeaderProps: {
            title: t("surveys.surveys"),
            actionElement: (
              <Button
                icon={<Plus />}
                iconPosition="leading"
                label={t("surveys.addSurvey.createSurveyButtonText")}
                name={t("surveys.addSurvey.createSurveyButtonText")}
                variant="filled"
                size="medium"
                onClick={(): void => {
                  projectDetailsStore.clearSurveyNameAndRPCError();
                  setIsCreateSurveyDialogOpen(true);
                }}
              />
            ),
            prefersLargeTitle: true,
          },
        })}
        {...(selectedTab === ProjectDetailsTabs.UserAccess &&
          (signInStore.isPrivilegeGranted(UserPrivileges.viewSpocs) ||
            signInStore.isPrivilegeGranted(UserPrivileges.viewProjectBOUs)) && {
            sectionHeaderProps: {
              title: t("projects.userAccess.userAccessTitle"),
              actionElement: projectDetailsStore.isRPCLoading
                ? undefined
                : getUserAccessActionElement(),
              prefersLargeTitle: true,
              tabElement: !projectDetailsStore.isRPCLoading ? (
                <Tabs
                  tabs={getUserAccessTabs()}
                  onTabChange={(index: number): void => {
                    projectDetailsStore.userAccessStore.setSelectedTab(index);
                  }}
                  selectedTabIndex={
                    projectDetailsStore.userAccessStore.selectedTab
                  }
                  disableBottomBorder={true}
                />
              ) : undefined,
            },
            isChildHeightVariable: true,
          })}
        {...(selectedTab === ProjectDetailsTabs.Settings && {
          sectionHeaderProps: {
            title: t("projects.settings.settingsTitle"),
            actionElement: <SettingsActionElement />,
            prefersLargeTitle: true,
          },
        })}
        isOverflowHidden={false}
      >
        <TabElement index={selectedTab} />
        <FeatureInDevelopmentSnackbar
          isSnackbarVisible={isSnackbarVisible}
          setIsSnackbarVisible={setIsSnackbarVisible}
        />
        {isDownloadReportsDialogOpen && (
          <DownloadReportsDialog
            isDownloadReportsDialogOpen={isDownloadReportsDialogOpen}
            setIsDownloadReportsDialogOpen={setIsDownloadReportsDialogOpen}
            setIsSnackbarVisible={setIsSnackbarVisible}
            t={t}
            spacing={spacing}
            reportStore={reportStore}
            projectStore={projectDetailsStore}
            tokens={tokens}
            typography={typography}
            navigateTo={navigateTo}
          />
        )}
        {isAddNewEmbedDialogOpen && (
          <AddNewEmbedDialog
            isAddNewEmbedDialogOpen={isAddNewEmbedDialogOpen}
            setIsAddNewEmbedDialogOpen={setIsAddNewEmbedDialogOpen}
            t={t}
            spacing={spacing}
            embedStore={reportStore.embedStore}
            projectDetailsStore={projectDetailsStore}
            tokens={tokens}
            typography={typography}
            navigateTo={navigateTo}
            border={border}
          />
        )}
      </DetailSectionComponent>
    </>
  ) : (
    <>
      {
        // We are returning an empty element if selectedTab is undefined to prevent the project details page from rendering.
        // If we add a loading indicator here, it will be rendered before keycloak provider and since, keyclaok provided gives a blank screen,
        // the transition will look like: a loading indicator -> blank screen -> pageNotFound Page. With the current implementation,
        // the transition will be like: blank screen -> pageNotFound Page.
      }
    </>
  );
});
