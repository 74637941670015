import { EMPTY_CHARACTER, URL_DELIMITER } from "@pulse/shared-components";
import { useNavigate, useLocation } from "react-router-dom";
import { ProjectDetailsTabs } from "../modules/projects/pages/ProjectDetailsPage";

export const enum Route {
  Root = "/",
  SignIn = "/signIn",
  PageNotFound = "/404",
  InternalServerError = "/500",
  NetworkError = "/408",
  PathNotFound = "*",

  // Projects
  Project = "/projects",
  ProjectDetails = "/projects/project",

  // Manage Users
  ManageUsers = "/manage-users",

  // Audit Logs
  AuditLogs = "/audit-logs",

  // Profile Settings
  ProfileSettings = "/profile",

  // Surveys
  SurveyDetails = "/projects/project/survey",
  SurveyAddQuestion = "/projects/project/survey/manage-questions",

  // SPoCRespondent
  SPoCRespondent = "/projects/project/spoc",

  Report = "/projects/dashboard/report",
  SurveyView = "/projects/project/survey-view",
}

export const getParentRoute = (route: string, searchParam: string): string => {
  const routeParams = route
    .split(URL_DELIMITER)
    .filter((pathSegment) => pathSegment !== EMPTY_CHARACTER);
  routeParams.pop();
  return URL_DELIMITER + routeParams.join(URL_DELIMITER) + searchParam;
};

export interface NavigateToFunctions {
  root: () => void;
  signIn: () => void;
  pageNotFound: () => void;
  internalServerError: () => void;
  networkError: () => void;
  reload: () => void;
  parent: () => void;
  previousPage: () => void;
  project: () => void;
  manageUsers: () => void;
  auditLogs: () => void;
  projectDetails: (
    projectId: string,
    selectedTab: ProjectDetailsTabs | undefined,
  ) => void;
  surveyDetails: (projectId: string, surveyId: string) => void;
  surveyAddQuestion: (projectId: string, surveyId: string) => void;
  spocRespondent: (projectId: string, spocId: string) => void;
  profileSettings: () => void;
  report: (projectId: string, surveyId: string) => void;
  surveyView: (
    projectId: string,
    surveyId: string,
    surveyViewId: string,
  ) => void;
}

const isUserOnInternalServerErrorPage = (): boolean => {
  return (
    // window.location.href.substring(window.location.href.length - 4) gets the last 4 characters from the URL to be compared with /500 that is the path for internal server error page.
    // Right now there is no case where /500 would be the last characters for a link, but if in future there exists a case then this check will fail and hence should be updated.
    window.location.href.substring(window.location.href.length - 4) ===
    Route.InternalServerError
  );
};

export const processSurveyParams = async (
  callback: (surveyId: string, projectId: string) => Promise<void>,
  navigateTo: NavigateToFunctions,
): Promise<void> => {
  if (isUserOnInternalServerErrorPage()) {
    return;
  }
  const surveyId = new URLSearchParams(location.search).get("surveyId");
  const projectId = new URLSearchParams(location.search).get("projectId");

  if (surveyId === null || projectId === null) {
    navigateTo.pageNotFound();
  } else {
    await callback(surveyId, projectId);
  }
};

export const processSurveyViewParams = async (
  callback: (
    surveyViewId: string,
    surveyId: string,
    projectId: string,
  ) => Promise<void>,
  navigateTo: NavigateToFunctions,
): Promise<void> => {
  if (isUserOnInternalServerErrorPage()) {
    return;
  }
  const surveyId = new URLSearchParams(location.search).get("surveyId");
  const projectId = new URLSearchParams(location.search).get("projectId");
  const surveyViewId = new URLSearchParams(location.search).get("surveyViewId");

  if (surveyId === null || projectId === null || surveyViewId === null) {
    navigateTo.pageNotFound();
  } else {
    await callback(surveyViewId, surveyId, projectId);
  }
};

export const processProjectParams = async (
  callback: (projectId: string) => Promise<void>,
  navigateTo: NavigateToFunctions,
): Promise<void> => {
  const projectId = new URLSearchParams(location.search).get("projectId");
  if (isUserOnInternalServerErrorPage()) {
    return;
  }
  if (projectId === null) {
    navigateTo.pageNotFound();
  } else {
    await callback(projectId);
  }
};

export const processSPoCParams = async (
  callback: (projectId: string, spocId: string) => Promise<void>,
  navigateTo: NavigateToFunctions,
): Promise<void> => {
  if (isUserOnInternalServerErrorPage()) {
    return;
  }
  const projectId = new URLSearchParams(location.search).get("projectId");
  const spocId = new URLSearchParams(location.search).get("spocId");

  if (projectId === null || spocId === null) {
    navigateTo.pageNotFound();
  } else {
    await callback(projectId, spocId);
  }
};

/**
 * Thsi function is designed to handle all navigation within the project.
 * It encapsulates navigation logic and provides a set of functions used for navigation purposes.
 * We should only this function for navigation, and should avoid using the navigation from `react-router-dom`.
 *
 * @returns An object used to navigating to specific routes within the project.
 *
 * @example
 *  // Usage:
 *  const navigateTo = useRouteNavigator();
 *  navigateTo.projectDetails(projectStore.projectName);
 */
export const useRouteNavigator = (): NavigateToFunctions => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateTo: NavigateToFunctions = {
    root: () => navigate(Route.Root),
    signIn: () => navigate(Route.SignIn),
    pageNotFound: () => navigate(Route.PageNotFound, { replace: true }),
    networkError: () => navigate(Route.NetworkError, { replace: true }),
    internalServerError: () =>
      navigate(Route.InternalServerError, { replace: true }),
    parent: () => navigate(getParentRoute(location.pathname, location.search)),
    previousPage: () => navigate(-1),
    reload: () => navigate(0),
    project: () => navigate(Route.Project),
    manageUsers: () => navigate(Route.ManageUsers),
    auditLogs: () => navigate(Route.AuditLogs),
    projectDetails: (projectId: string, selectedTab: string | undefined) => {
      navigate(
        `${Route.ProjectDetails}?projectId=${projectId}&tab=${selectedTab}`,
      );
    },
    surveyDetails: (projectId: string, surveyId: string) => {
      navigate(
        `${Route.SurveyDetails}?projectId=${projectId}&surveyId=${surveyId}`,
      );
    },
    surveyAddQuestion: (projectId: string, surveyId: string) => {
      navigate(
        `${Route.SurveyAddQuestion}?projectId=${projectId}&surveyId=${surveyId}`,
      );
    },
    spocRespondent: (projectId: string, spocId: string) => {
      navigate(
        `${Route.SPoCRespondent}?projectId=${projectId}&spocId=${spocId}`,
      );
    },
    profileSettings: () => navigate(Route.ProfileSettings),
    report: (projectId: string, surveyId: string) => {
      navigate(`${Route.Report}?projectId=${projectId}&surveyId=${surveyId}`);
    },
    surveyView: (projectId: string, surveyId: string, surveyViewId: string) => {
      navigate(
        `${Route.SurveyView}?projectId=${projectId}&surveyId=${surveyId}&surveyViewId=${surveyViewId}`,
      );
    },
  };
  return navigateTo;
};
