import {
  QuestionViewTypeV1,
  SingleSelectNpsGraphTypeV1,
  SingleSelectNpsResultV1,
} from "@pulse/pulse-rpcs";
import { Instance, types } from "mobx-state-tree";
import {
  createSampleBreakdownModel,
  SampleBreakdownModel,
} from "./SampleBreakdownModel";
import { QuestionTypeGraphs } from "../components/ChartSelection";
import { getHundredthDecimalRoundedPercentageString } from "@pulse/shared-components";

export const SingleSelectNPSResultsModelV1 = types.model(
  "SingleSelectNPSResultsModelV1",
  {
    answer: types.string,
    percentage: types.string,
    count: types.number,
    rangeStart: types.number,
    rangeEnd: types.number,
  },
);

export const createSingleSelectNPSResultsModelV1 = (
  singleSelectResultV1: SingleSelectNpsResultV1,
): Instance<typeof SingleSelectNPSResultsModelV1> => {
  return SingleSelectNPSResultsModelV1.create({
    answer: singleSelectResultV1.answer,
    percentage: getHundredthDecimalRoundedPercentageString(
      singleSelectResultV1.commonResultBreakdownDetails.percentage,
    ),
    count: singleSelectResultV1.commonResultBreakdownDetails.count.count,
    rangeEnd: singleSelectResultV1.rangeEnd,
    rangeStart: singleSelectResultV1.rangeStart,
  });
};

export const NPSSingleSelectQuestionModelV1 = types
  .model("NPSSingleSelectQuestionModelV1", {
    question: types.maybeNull(types.string),
    detractors: SingleSelectNPSResultsModelV1,
    passives: SingleSelectNPSResultsModelV1,
    promoters: SingleSelectNPSResultsModelV1,
    sampleBreakdown: SampleBreakdownModel,
    singleSelectNPSGraphType: types.enumeration(
      "SingleSelectNpsGraphTypeV1",
      Object.values(SingleSelectNpsGraphTypeV1.SingleSelectNpsGraphTypeV1),
    ),
    updatedSingleSelectNPSGraphType: types.enumeration(
      "SingleSelectNpsGraphTypeV1",
      Object.values(SingleSelectNpsGraphTypeV1.SingleSelectNpsGraphTypeV1),
    ),
  })
  .views((store) => ({
    get isGraphSelectionUpdated(): boolean {
      return (
        store.updatedSingleSelectNPSGraphType !== store.singleSelectNPSGraphType
      );
    },
  }))
  .actions((store) => ({
    updateSelectedGraphType: (
      updatedSingleSelectNPSGraphType: QuestionTypeGraphs,
    ): void => {
      store.updatedSingleSelectNPSGraphType =
        SingleSelectNpsGraphTypeV1.SingleSelectNpsGraphTypeV1[
          updatedSingleSelectNPSGraphType as keyof typeof SingleSelectNpsGraphTypeV1.SingleSelectNpsGraphTypeV1
        ];
    },
  }));

export const createNPSSingleSelectQuestionModelV1 = (
  singleSelectNPSQuestion: QuestionViewTypeV1.SingleSelectNps,
  question: string | null = null,
): Instance<typeof NPSSingleSelectQuestionModelV1> => {
  return NPSSingleSelectQuestionModelV1.create({
    detractors: createSingleSelectNPSResultsModelV1(
      singleSelectNPSQuestion.detractors,
    ),
    passives: createSingleSelectNPSResultsModelV1(
      singleSelectNPSQuestion.passives,
    ),
    promoters: createSingleSelectNPSResultsModelV1(
      singleSelectNPSQuestion.promoters,
    ),
    sampleBreakdown: createSampleBreakdownModel(
      singleSelectNPSQuestion.sampleBreakdown,
    ),
    singleSelectNPSGraphType: singleSelectNPSQuestion.singleSelectNpsGraphType,
    updatedSingleSelectNPSGraphType:
      singleSelectNPSQuestion.singleSelectNpsGraphType,
    question,
  });
};
