import {
  Dialog,
  FoundationColorTokens,
  Spacing,
  TableReloadHandle,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import React, { useEffect } from "react";
import { BOUserStore } from "../store/BOUserStore";
import { observer } from "mobx-react";
import {
  AddBOUserDialogState,
  AddBOUserRPCError,
} from "../store/AddBOUserStore";
import { DialogErrorContent } from "../../../components/DialogErrorContent";
import { AddBOUserChildComponent } from "./AddBOUserChildComponent";
import { DialogLoaderContent } from "../../projects/components/DialogLoaderContent";

interface AddNewBOUserDialogProps {
  isAddNewBOUserDialogOpen: boolean;
  setIsAddNewBOUserDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  t: TFunction;
  spacing: Spacing;
  boUserStore: Instance<typeof BOUserStore>;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  tableRef: React.MutableRefObject<TableReloadHandle | null>;
}

export const AddNewBOUserDialog = observer(
  ({
    isAddNewBOUserDialogOpen,
    setIsAddNewBOUserDialogOpen,
    t,
    spacing,
    boUserStore,
    tokens,
    typography,
    tableRef,
  }: AddNewBOUserDialogProps): React.ReactElement => {
    const addBOUserStore = boUserStore.addBOUserStore;

    useEffect(() => {
      addBOUserStore.setDialogState(AddBOUserDialogState.AddBOUser);
      return () => {
        addBOUserStore.resetStoreData();
      };
    }, []);

    const getPrimaryButtonText = (): string | undefined => {
      switch (addBOUserStore.addBOUserDialogState) {
        case AddBOUserDialogState.FetchingRoles:
        case AddBOUserDialogState.Error: {
          return undefined;
        }
        case AddBOUserDialogState.AddBOUser: {
          return t("manageUsers.addNewBOUDialog.addBOU");
        }
      }
    };

    const getSecondaryButtonText = (): string | undefined => {
      switch (addBOUserStore.addBOUserDialogState) {
        case AddBOUserDialogState.FetchingRoles: {
          return undefined;
        }
        case AddBOUserDialogState.Error:
        case AddBOUserDialogState.AddBOUser: {
          return t("common.cancel");
        }
      }
    };

    const getErrorText = (): string => {
      switch (addBOUserStore.rpcError) {
        case AddBOUserRPCError.BOUserAlreadyExists: {
          return t("manageUsers.addNewBOUDialog.errorTexts.userAlreadyExists");
        }
        case AddBOUserRPCError.SPoCCannotBeBOUser: {
          return t("manageUsers.addNewBOUDialog.errorTexts.spocCannotBeBOU");
        }
        case AddBOUserRPCError.InvalidRoleId:
        case AddBOUserRPCError.UnableToAddBOUser: {
          return t("manageUsers.addNewBOUDialog.errorTexts.unexpectedError");
        }
        default: {
          return t("manageUsers.addNewBOUDialog.errorTexts.unexpectedError");
        }
      }
    };

    const getDialogChild = (): React.ReactElement => {
      switch (addBOUserStore.addBOUserDialogState) {
        case AddBOUserDialogState.FetchingRoles: {
          return (
            <DialogLoaderContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              loaderText={t("manageUsers.addNewBOUDialog.fetchingRolesText")}
            />
          );
        }
        case AddBOUserDialogState.AddBOUser: {
          return (
            <AddBOUserChildComponent
              t={t}
              boUserStore={boUserStore}
              spacing={spacing}
            />
          );
        }
        case AddBOUserDialogState.Error: {
          return (
            <DialogErrorContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              errorText={getErrorText()}
            />
          );
        }
      }
    };

    return (
      <Dialog
        open={isAddNewBOUserDialogOpen}
        title={t("manageUsers.addNewBOUDialog.addNewBOU")}
        contentPadding={spacing.spaceLG}
        disableBackdropClick={true}
        primaryButtonText={getPrimaryButtonText()}
        secondaryButtonText={getSecondaryButtonText()}
        isPrimaryButtonDisabled={addBOUserStore.isPrimaryButtonDisabled}
        onPrimaryButtonClick={async (): Promise<void> => {
          addBOUserStore.setIsAddBOButtonClicked(true);
          await addBOUserStore.addBOUser();
          if (
            !addBOUserStore.rpcError &&
            !addBOUserStore.areTextFieldsErrored
          ) {
            setIsAddNewBOUserDialogOpen(false);
            tableRef.current?.reload();
          }
        }}
        isSecondaryButtonDisabled={addBOUserStore.isRPCLoading}
        onSecondaryButtonClick={() => {
          if (addBOUserStore.isBOUserAlreadyExistsError) {
            tableRef.current?.reload();
          }
          setIsAddNewBOUserDialogOpen(false);
          addBOUserStore.setIsAddBOButtonClicked(false);
        }}
        width="560px"
      >
        {/*
        getDialogChild() is not called like <DialogChild />.
        This is done to avoid giving it its own lifecycle which will result in loss of focus from <TextInputField /> on store updation.
        */}
        {getDialogChild()}
      </Dialog>
    );
  },
);
