import React, { SetStateAction, useEffect, useState } from "react";
import { SettingsContainer } from "../../../../components/SettingsContainer";
import { TFunction } from "i18next";
import {
  Spacing,
  Typography as LeoTypography,
  FoundationColorTokens,
  LoadingIndicator,
  Dialog,
  TextInputField,
  DateRangePickerField,
  DateRangePickerInput,
  InvalidDateError,
  Button,
} from "@surya-digital/leo-reactjs-material-ui";
import { Instance } from "mobx-state-tree";
import { ErrorSuccessDialogContent } from "../ErrorSuccessDialogContent";
import { UpdateProjectDetailsRPCErrors } from "../../store/UpdateProjectDetailsStore";
import { observer } from "mobx-react";
import { Stack } from "@mui/material";
import { DividerComponent } from "../../../surveys/components/commonQuestionConfigurationDetailsComponents/DividerComponent";
import { ArchiveProjectDialog } from "../projectList/ArchiveProjectDialog";
import { ProjectsRootStore } from "../../store/ProjectsRootStore";
import { PROJECT_SURVEY_NAME_VALIDATIONS } from "@pulse/shared-components";

export interface ProjectSettingsPaneProps {
  t: TFunction;
  spacing: Spacing;
  typography: LeoTypography;
  tokens: FoundationColorTokens<string>;
  projectRootStore: Instance<typeof ProjectsRootStore>;
  setIsProjectDateRangeInputInvalid: React.Dispatch<SetStateAction<boolean>>;
}

export const ProjectSettingsPane = observer(
  ({
    spacing,
    t,
    typography,
    tokens,
    projectRootStore,
    setIsProjectDateRangeInputInvalid,
  }: ProjectSettingsPaneProps): React.ReactElement => {
    const [isArchiveProjectDialogOpen, setIsArchiveProjectDialogOpen] =
      useState(false);
    const projectDetailsStore = projectRootStore.projectDetailsStore;
    const updateProjectDetailsStore =
      projectDetailsStore.updateProjectDetailsStore;

    useEffect(() => {
      if (updateProjectDetailsStore.projectId === undefined) {
        updateProjectDetailsStore.setupProjectDetails(
          projectDetailsStore.projectName,
          projectDetailsStore.projectStartDate,
          projectDetailsStore.projectEndDate,
          projectDetailsStore.surveyStore.projectId,
        );
      }
      return () => {
        updateProjectDetailsStore.resetStore();
      };
    }, []);

    const getSaveChangesErrorDialogText = (): string => {
      switch (updateProjectDetailsStore.rpcError) {
        case UpdateProjectDetailsRPCErrors.ProjectAlreadyArchived:
          return t("common.projectAlreadyArchivedErrorText");
        default:
          return t(
            "projects.settings.updateProjectDetails.unexpectedErrorText",
          );
      }
    };

    const getProjectNameTextFieldHelperText = (): string | undefined => {
      switch (updateProjectDetailsStore.rpcError) {
        case UpdateProjectDetailsRPCErrors.InvalidProjectName:
          return t("projects.invalidProjectNameErrorText", {
            allowedSpecialCharacters:
              PROJECT_SURVEY_NAME_VALIDATIONS.allowedSpecialCharacters,
          });
        case UpdateProjectDetailsRPCErrors.ProjectNameNotUnique:
          return t("projects.projectNameNotUniqueErrorText");

        case UpdateProjectDetailsRPCErrors.InvalidProjectNameLength: {
          return t("projects.invalidProjectNameLengthErrorText", {
            minLength: PROJECT_SURVEY_NAME_VALIDATIONS.minLength,
            maxLength: PROJECT_SURVEY_NAME_VALIDATIONS.maxLength,
          });
        }
      }
    };

    const getProjectDateFieldHelperText = (): string | undefined => {
      switch (updateProjectDetailsStore.rpcError) {
        case UpdateProjectDetailsRPCErrors.InvalidProjectDates:
          return t("projects.invalidProjectDatesErrorText");
      }
    };

    return (
      <Stack
        px={spacing.space2XL}
        width="100%"
        height="100%"
        alignItems="start"
        overflow="auto"
        divider={<DividerComponent orientation="horizontal" width="100%" />}
      >
        <SettingsContainer
          settingHeadingText={t("projects.projectNameLabel")}
          spacing={spacing}
          typography={typography}
          tokens={tokens}
        >
          <TextInputField
            name="projectName"
            value={updateProjectDetailsStore.projectName}
            onTextChange={(value) => {
              updateProjectDetailsStore.clearRPCError();
              updateProjectDetailsStore.setProjectName(value);
            }}
            label={t("projects.projectNameLabel")}
            type={"text"}
            placeholder={t("projects.enterProjectNamePlaceholder")}
            helperText={getProjectNameTextFieldHelperText()}
            error={updateProjectDetailsStore.doesProjectTextFieldContainErrors}
            required={true}
            width="444px"
          />
        </SettingsContainer>
        <SettingsContainer
          settingHeadingText={t(
            "projects.settings.updateProjectDetails.startAndEndDateTitle",
          )}
          settingSubHeadingText={t(
            "projects.settings.updateProjectDetails.startAndEndDateDescription",
          )}
          spacing={spacing}
          typography={typography}
          tokens={tokens}
        >
          <Stack gap={spacing.spaceXXS}>
            <DateRangePickerField
              value={updateProjectDetailsStore.getDateRangePickerInput}
              startDateLabel={t("projects.startDateLabel")}
              endDateLabel={t("projects.endDateLabel")}
              onChange={(newDateRangePickerInput: DateRangePickerInput) => {
                updateProjectDetailsStore.clearRPCError();
                updateProjectDetailsStore.setProjectDateRangeValue(
                  newDateRangePickerInput,
                );
              }}
              minDate={projectDetailsStore.projectStartDate ?? undefined}
              startDatePlaceholder={t("common.dateRangePlaceholderText")}
              endDatePlaceholder={t("common.dateRangePlaceholderText")}
              format="dd MMM, yyyy"
              onError={(
                startDateError: InvalidDateError | null,
                endDateError: InvalidDateError | null,
              ): void => {
                if (startDateError !== null) {
                  setIsProjectDateRangeInputInvalid(true);
                  console.error(startDateError);
                }
                if (endDateError !== null) {
                  setIsProjectDateRangeInputInvalid(true);
                  console.error(endDateError);
                }
                if (endDateError === null && startDateError === null) {
                  setIsProjectDateRangeInputInvalid(false);
                }
              }}
              startDateHelperText={getProjectDateFieldHelperText()}
              startDateError={getProjectDateFieldHelperText() !== undefined}
              endDateError={getProjectDateFieldHelperText() !== undefined}
            />
          </Stack>
        </SettingsContainer>
        <SettingsContainer
          settingHeadingText={t("projects.archiveProjectTitle")}
          settingSubHeadingText={t(
            "projects.settings.updateProjectDetails.archiveProjectDescription",
          )}
          spacing={spacing}
          typography={typography}
          tokens={tokens}
        >
          <Button
            label={t("projects.archiveProjectTitle")}
            name="archiveProject"
            onClick={function (): void {
              updateProjectDetailsStore.projectId
                ? projectRootStore.setSelectedProjectDetails(
                    updateProjectDetailsStore.projectId,
                    projectDetailsStore.projectName,
                  )
                : console.error(
                    "Project Id found to be null while trying to archive the project.",
                  );
              setIsArchiveProjectDialogOpen(true);
            }}
            size="medium"
            variant="filled"
            color="destructive"
          />
          {isArchiveProjectDialogOpen && (
            <ArchiveProjectDialog
              isArchiveProjectDialogOpen={isArchiveProjectDialogOpen}
              t={t}
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              projectRootStore={projectRootStore}
              setIsArchiveProjectDialogOpen={setIsArchiveProjectDialogOpen}
              tableRef={undefined}
            />
          )}
        </SettingsContainer>
        {updateProjectDetailsStore.isRPCLoading && (
          <LoadingIndicator
            isLoading={updateProjectDetailsStore.isRPCLoading}
          />
        )}
        {updateProjectDetailsStore.isUpdateProjectDetailsErrorDialogVisible && (
          <Dialog
            open={
              updateProjectDetailsStore.isUpdateProjectDetailsErrorDialogVisible
            }
            title={t(
              "surveys.settings.saveChanges.saveChangesErrorDialogTitle",
            )}
            secondaryButtonText={t("common.close")}
            onSecondaryButtonClick={() => {
              updateProjectDetailsStore.setupProjectDetails(
                projectDetailsStore.projectName,
                projectDetailsStore.projectStartDate,
                projectDetailsStore.projectEndDate,
                projectDetailsStore.surveyStore.projectId,
              );
              updateProjectDetailsStore.resetErrorDialog();
            }}
          >
            <ErrorSuccessDialogContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              isError={true}
              errorSuccessText={getSaveChangesErrorDialogText()}
            />
          </Dialog>
        )}
      </Stack>
    );
  },
);
