import {
  Dialog,
  FoundationColorTokens,
  Spacing,
  TableReloadHandle,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import {
  SPoCRespondentStore,
  UnassignRespondentFromSpocDialogState,
  UnassignRespondentFromSpocRPCErrors,
} from "../../../store/SPoCRespondentStore";
import { Instance } from "mobx-state-tree";
import { TFunction } from "i18next";
import { NestedTypographyDestructiveComponent } from "../../NestedTypographyDestructiveComponent";
import {
  NavigateToFunctions,
  processSPoCParams,
} from "../../../../../routes/RoutesHelper";
import { DialogErrorContent } from "../../../../../components/DialogErrorContent";

interface UnassignRespondentFromSPoCDialogProps {
  isUnassignRespondentFromSPoCDialogOpen: boolean;
  setIsUnassignRespondentFromSPoCDialogOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setSelectedRespondent: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  spocRespondentStore: Instance<typeof SPoCRespondentStore>;
  spacing: Spacing;
  t: TFunction;
  selectedRespondent: number | undefined;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  tableRef: React.MutableRefObject<TableReloadHandle | null>;
  navigateTo: NavigateToFunctions;
}

export const UnassignRespondentFromSPoCDialog = ({
  isUnassignRespondentFromSPoCDialogOpen: isUnassignSPoCRespondentDialogOpen,
  setIsUnassignRespondentFromSPoCDialogOpen:
    setIsUnassignSPoCRespondentDialogOpen,
  spocRespondentStore,
  spacing,
  t,
  selectedRespondent,
  tokens,
  typography,
  setSelectedRespondent,
  navigateTo,
  tableRef,
}: UnassignRespondentFromSPoCDialogProps): React.ReactElement => {
  const getPrimaryButtonText = (): string | undefined => {
    switch (spocRespondentStore.unassignRespondentFromSpocDialogState) {
      case UnassignRespondentFromSpocDialogState.UnassignSPoCRespondentState: {
        return t("projects.spocRespondent.remove");
      }
    }
  };

  const getSecondaryButtonText = (): string => {
    switch (spocRespondentStore.unassignRespondentFromSpocDialogState) {
      case UnassignRespondentFromSpocDialogState.UnassignSPoCRespondentState: {
        return t("common.cancel");
      }
      case UnassignRespondentFromSpocDialogState.ErrorState: {
        return t("common.close");
      }
    }
  };

  const getNestedTypographyDestructiveComponentProps = (): {
    middleBoldText: string;
    endText: string;
  } => {
    if (selectedRespondent) {
      return {
        middleBoldText: `${selectedRespondent}`,
        endText: t("projects.spocRespondent.removeRespondentDescription"),
      };
    } else if (spocRespondentStore.isSingleRespondentSelected) {
      return {
        middleBoldText: `${spocRespondentStore.multiSelectTableStore.selectedRowIds[0]}`,
        endText: t("projects.spocRespondent.removeRespondentDescription"),
      };
    } else {
      return {
        middleBoldText: `${spocRespondentStore.multiSelectTableStore.totalSelectedItems(spocRespondentStore.totalItems)}`,
        endText: t(
          "projects.spocRespondent.removeMultipleRespondentsDescription",
        ),
      };
    }
  };

  const getDialogErrorText = (): string => {
    switch (spocRespondentStore.rpcError) {
      case UnassignRespondentFromSpocRPCErrors.DeleteColumnFilter:
      case UnassignRespondentFromSpocRPCErrors.InvalidRespondentId: {
        return t(
          "projects.spocRespondent.unexpectedErrorWhileRemovingRepondentFromSPoc",
        );
      }
      case UnassignRespondentFromSpocRPCErrors.ProjectAlreadyArchived: {
        return t("common.projectAlreadyArchivedErrorText");
      }
      default: {
        console.error(
          `The error message has not been handled for error code ${spocRespondentStore.rpcError}`,
        );
        return t(
          "projects.spocRespondent.unexpectedErrorWhileRemovingRepondentFromSPoc",
        );
      }
    }
  };

  const getDialogChild = (): React.ReactElement => {
    switch (spocRespondentStore.unassignRespondentFromSpocDialogState) {
      case UnassignRespondentFromSpocDialogState.UnassignSPoCRespondentState: {
        return (
          <NestedTypographyDestructiveComponent
            tokens={tokens}
            typography={typography}
            startText={t("projects.spocRespondent.areYouSureYouWantToRemove")}
            noteText={undefined}
            {...getNestedTypographyDestructiveComponentProps()}
            spacing={spacing}
          />
        );
      }
      case UnassignRespondentFromSpocDialogState.ErrorState: {
        return (
          <DialogErrorContent
            spacing={spacing}
            tokens={tokens}
            typography={typography}
            errorText={getDialogErrorText()}
          />
        );
      }
    }
  };

  const resetDialogProps = (): void => {
    setIsUnassignSPoCRespondentDialogOpen(false);
    setSelectedRespondent(undefined);
    spocRespondentStore.setUnassignRespondentFromSpocDialogState(
      UnassignRespondentFromSpocDialogState.UnassignSPoCRespondentState,
    );
  };

  return (
    <Dialog
      open={isUnassignSPoCRespondentDialogOpen}
      contentPadding={spacing.spaceLG}
      disableBackdropClick={true}
      primaryButtonColor="destructive"
      width="560px"
      isSecondaryButtonDisabled={spocRespondentStore.isRPCLoading}
      onPrimaryButtonClick={async (): Promise<void> => {
        await processSPoCParams(
          async (projectId: string, spocId: string): Promise<void> => {
            spocRespondentStore.unassignRespondentFromSpoc(
              projectId,
              spocId,
              selectedRespondent,
            );
          },
          navigateTo,
        );
        if (!spocRespondentStore.doesStoreContainError) {
          resetDialogProps();
          tableRef.current?.reload();
        }
      }}
      onSecondaryButtonClick={() => {
        resetDialogProps();
        if (spocRespondentStore.doesStoreContainError) {
          tableRef.current?.reload();
        }
      }}
      primaryButtonText={getPrimaryButtonText()}
      title={t("projects.spocRespondent.removeRespondentWithQuestionMark")}
      secondaryButtonText={getSecondaryButtonText()}
    >
      {/*
        getDialogChild() is not called like <DialogChild />.
        This is done to avoid giving it its own lifecycle which will result in loss of focus from <TextInputField /> on store updation.
        */}
      {getDialogChild()}
    </Dialog>
  );
};
