import { APIClient } from "@surya-digital/tedwig";
import {
  flow,
  getParentOfType,
  getRoot,
  Instance,
  types,
} from "mobx-state-tree";
import { getAPIClient } from "../../networking/APIClient";
import {
  EditDashboardViewRPC,
  SurveyViewDetails,
  SurveyViewName,
} from "@pulse/pulse-rpcs";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import { SPoCChartCustomisations } from "../stores/AddViewStore";
import { useEditDashboardViewRPCClientImpl } from "../rpcs/RPC";
import { ProjectDetailsStore } from "../../projects/store/ProjectDetailsStore";
import { ReportsStore } from "../stores/ReportsStore";
import { CommonErrors, surveyViewNameRegex } from "@pulse/shared-components";
import { RootStore } from "../../root/store/RootStore";

export enum EditSurveyViewDialogState {
  EDIT = "EDIT",
  ERROR = "ERROR",
}

export enum EditViewRPCError {
  ProjectAlreadyArchived = "PROJECT_ALREADY_ARCHIVED",
  InvalidSurveyViewId = "INVALID_SURVEY_VIEW_ID",
  SurveyViewAlreadyExists = "SURVEY_VIEW_ALREADY_EXISTS",
  MasterSurveyViewCannotBeEdited = "MASTER_SURVEY_VIEW_CANNOT_BE_EDITED",
  InvalidViewName = "INVALID_VIEW_NAME",
  InvalidViewNameLength = "INVALID_VIEW_NAME_LENGTH",
}

export const SurveyDashboardViewModel = types
  .model("SurveyDashboardViewModel", {
    surveyViewId: types.string,
    surveyViewName: types.string,
    updatedSurveyViewName: types.string,
    isMasterView: types.boolean,
    editSurveyViewDialogState: types.optional(
      types.enumeration(Object.values(EditSurveyViewDialogState)),
      EditSurveyViewDialogState.EDIT,
    ),
    isRPCLoading: types.optional(types.boolean, false),
    rpcError: types.maybeNull(
      types.enumeration(Object.values(EditViewRPCError)),
    ),
    spocChartCustomisation: types.enumeration(
      Object.values(SPoCChartCustomisations),
    ),
    updatedSPoCChartCustomisation: types.enumeration(
      Object.values(SPoCChartCustomisations),
    ),
  })
  .views((store) => ({
    get isEditSurveyViewDialogPrimaryButtonDisabled(): boolean {
      return (
        store.surveyViewName === store.updatedSurveyViewName &&
        store.spocChartCustomisation === store.updatedSPoCChartCustomisation
      );
    },
    get isUpdatedSPoCChangeGraphAllowed(): boolean {
      return (
        store.updatedSPoCChartCustomisation ===
        SPoCChartCustomisations.AllowSPoCToChangeGraphType
      );
    },
    get doesStoreContainViewNameError(): boolean {
      return (
        store.rpcError === EditViewRPCError.InvalidViewName ||
        store.rpcError === EditViewRPCError.InvalidViewNameLength ||
        store.rpcError === EditViewRPCError.SurveyViewAlreadyExists
      );
    },
    get doesStoreContainError(): boolean {
      return store.rpcError !== null;
    },
  }))
  .actions((store) => ({
    resetStoreValues: (): void => {
      store.updatedSurveyViewName = store.surveyViewName;
      store.rpcError = null;
      store.editSurveyViewDialogState = EditSurveyViewDialogState.EDIT;
      store.updatedSPoCChartCustomisation = store.spocChartCustomisation;
    },
    setUpdatedSurveyViewName: (updatedSurveyViewName: string): void => {
      store.rpcError = null;
      store.updatedSurveyViewName = updatedSurveyViewName;
    },
    setUpdatedSpoCChartCustomisation: (
      spocChartCustomisation: SPoCChartCustomisations,
    ): void => {
      store.updatedSPoCChartCustomisation = spocChartCustomisation;
    },
    validateViewName: (): boolean => {
      try {
        new SurveyViewName(store.updatedSurveyViewName.trim());
        const regex = new RegExp(surveyViewNameRegex);
        if (!regex.test(store.updatedSurveyViewName)) {
          store.rpcError = EditViewRPCError.InvalidViewName;
          return false;
        }
        return true;
      } catch (e) {
        store.rpcError = EditViewRPCError.InvalidViewNameLength;
        return false;
      }
    },
    editView: flow(function* (surveyId: string, projectId: string) {
      const projectDetailsStore = getParentOfType(store, ProjectDetailsStore);
      store.isRPCLoading = true;
      store.rpcError = null;
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new EditDashboardViewRPC.Request(
          new LeoUUID(projectId),
          new LeoUUID(surveyId),
          new LeoUUID(store.surveyViewId),
          new SurveyViewDetails(
            new SurveyViewName(store.updatedSurveyViewName),
            store.isUpdatedSPoCChangeGraphAllowed,
          ),
        );
        const {
          response,
          error,
        }: {
          response?: EditDashboardViewRPC.Response;
          error?: EditDashboardViewRPC.Errors.Errors;
        } = yield useEditDashboardViewRPCClientImpl(apiClient).execute(request);
        if (response) {
          projectDetailsStore.surveyStore.setSurveyName(
            response.surveyDetailsAndDashboardViews.surveyStatusAndName
              .surveyName.name,
          );
          projectDetailsStore.surveyStore.setSurveyStatus(
            response.surveyDetailsAndDashboardViews.surveyStatusAndName
              .surveyStatus,
          );
          getParentOfType(store, ReportsStore).setSurveyDashboardViews(
            response.surveyDetailsAndDashboardViews.surveyDashboardViews,
          );
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              break;
            case EditViewRPCError.InvalidViewName:
              store.rpcError = EditViewRPCError.InvalidViewName;
              // We are returning here and not setting EditSurveyViewDialogState to EditSurveyViewDialogState.Error.
              // This is done because this error is handled through helper text.
              // Setting EditSurveyViewDialogState to EditSurveyViewDialogState.Error will render the error dialog.
              return;
            case EditViewRPCError.ProjectAlreadyArchived:
              store.rpcError = EditViewRPCError.ProjectAlreadyArchived;
              break;
            case EditViewRPCError.SurveyViewAlreadyExists:
              store.rpcError = EditViewRPCError.SurveyViewAlreadyExists;
              // We are returning here and not setting EditSurveyViewDialogState to EditSurveyViewDialogState.Error.
              // This is done because this error is handled through helper text.
              // Setting EditSurveyViewDialogState to EditSurveyViewDialogState.Error will render the error dialog.
              return;
            case EditViewRPCError.InvalidSurveyViewId:
              store.rpcError = EditViewRPCError.InvalidSurveyViewId;
              break;
            case EditViewRPCError.InvalidViewNameLength:
              store.rpcError = EditViewRPCError.InvalidViewNameLength;
              break;
            case EditViewRPCError.MasterSurveyViewCannotBeEdited:
              store.rpcError = EditViewRPCError.MasterSurveyViewCannotBeEdited;
              break;
            default:
              console.error(
                `Unhandled error ${error.code} from EditDashboardViewRPC`,
              );
              break;
          }
          store.editSurveyViewDialogState = EditSurveyViewDialogState.ERROR;
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(
            `Unhandled error ${e} in createView action in AddViewStore`,
          );
        }
      } finally {
        store.isRPCLoading = false;
      }
    }),
  }));

export const createSurveyDashboardViewModel = (
  surveyViewId: string,
  surveyViewName: string,
  isMasterView: boolean,
  isSPoCChangeGraphAllowed: boolean,
): Instance<typeof SurveyDashboardViewModel> => {
  return SurveyDashboardViewModel.create({
    surveyViewId,
    surveyViewName,
    isMasterView,
    updatedSurveyViewName: surveyViewName,
    spocChartCustomisation: isSPoCChangeGraphAllowed
      ? SPoCChartCustomisations.AllowSPoCToChangeGraphType
      : SPoCChartCustomisations.RestrictToSelectType,
    updatedSPoCChartCustomisation: isSPoCChangeGraphAllowed
      ? SPoCChartCustomisations.AllowSPoCToChangeGraphType
      : SPoCChartCustomisations.RestrictToSelectType,
  });
};
