import { Instance } from "mobx-state-tree";
import React, { ReactElement, useEffect } from "react";
import {
  EditSurveyViewDialogState,
  EditViewRPCError,
  SurveyDashboardViewModel,
} from "../models/SurveyDashboardViewModel";
import {
  Dialog,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { EditSurveyViewDialogChild } from "./EditSurveyViewDialogChild";
import { TFunction } from "i18next";
import { RadioProps } from "@mui/material";
import { BorderStyle } from "@pulse/shared-components";
import {
  NavigateToFunctions,
  processSurveyParams,
} from "../../../routes/RoutesHelper";
import { observer } from "mobx-react";
import { DialogErrorContent } from "../../../components/DialogErrorContent";

interface EditSurveyViewDialogProps {
  isEditSurveyViewDialogOpen: boolean;
  setIsEditSurveyViewDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  surveyDashboardView: Instance<typeof SurveyDashboardViewModel>;
  t: TFunction;
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  radioProps: RadioProps;
  border: BorderStyle;
  navigateTo: NavigateToFunctions;
}

export const EditSurveyViewDialog = observer(
  ({
    isEditSurveyViewDialogOpen,
    setIsEditSurveyViewDialogOpen,
    surveyDashboardView,
    t,
    border,
    spacing,
    tokens,
    typography,
    radioProps,
    navigateTo,
  }: EditSurveyViewDialogProps): ReactElement => {
    useEffect(() => {
      surveyDashboardView.resetStoreValues();
    }, []);
    const getPrimaryButtonText = (): string | undefined => {
      switch (surveyDashboardView.editSurveyViewDialogState) {
        case EditSurveyViewDialogState.EDIT: {
          return t("surveyDashboard.addViewDialog.editView");
        }
        default: {
          return undefined;
        }
      }
    };
    const getSecondaryButtonText = (): string | undefined => {
      switch (surveyDashboardView.editSurveyViewDialogState) {
        case EditSurveyViewDialogState.EDIT: {
          return t("common.cancel");
        }
        case EditSurveyViewDialogState.ERROR: {
          return t("common.close");
        }
      }
    };

    const getErrorText = (): string => {
      switch (surveyDashboardView.rpcError) {
        case EditViewRPCError.ProjectAlreadyArchived:
          return t("common.projectAlreadyArchivedErrorText");
        default:
          return t(
            "surveyDashboard.addViewDialog.errorMessages.unexpectedError",
          );
      }
    };

    const getDialogChild = (): React.ReactElement => {
      switch (surveyDashboardView.editSurveyViewDialogState) {
        case EditSurveyViewDialogState.EDIT: {
          return (
            <EditSurveyViewDialogChild
              surveyDashboardView={surveyDashboardView}
              t={t}
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              radioProps={radioProps}
              border={border}
            />
          );
        }
        case EditSurveyViewDialogState.ERROR: {
          return (
            <DialogErrorContent
              spacing={spacing}
              tokens={tokens}
              typography={typography}
              errorText={getErrorText()}
            />
          );
        }
      }
    };

    return (
      <Dialog
        open={isEditSurveyViewDialogOpen}
        title={t("surveyDashboard.addViewDialog.editView")}
        width="560px"
        primaryButtonText={getPrimaryButtonText()}
        onPrimaryButtonClick={async (): Promise<void> => {
          if (surveyDashboardView.validateViewName()) {
            await processSurveyParams(surveyDashboardView.editView, navigateTo);
            if (!surveyDashboardView.doesStoreContainError) {
              setIsEditSurveyViewDialogOpen(false);
            } else {
              switch (surveyDashboardView.rpcError) {
                case EditViewRPCError.InvalidSurveyViewId: {
                  navigateTo.pageNotFound();
                }
              }
            }
          }
        }}
        isPrimaryButtonDisabled={
          surveyDashboardView.isEditSurveyViewDialogPrimaryButtonDisabled
        }
        secondaryButtonText={getSecondaryButtonText()}
        onSecondaryButtonClick={() => {
          setIsEditSurveyViewDialogOpen(false);
        }}
        isSecondaryButtonDisabled={false}
        disableBackdropClick={true}
      >
        {/*
        getDialogChild() is not called like <DialogChild />.
        This is done to avoid giving it its own lifecycle which will result in loss of focus from <TextInputField /> on store updation.
        */}
        {getDialogChild()}
      </Dialog>
    );
  },
);
