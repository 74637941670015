import {
  Dialog,
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
  TableReloadHandle,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import React, { useEffect, useState } from "react";
import {
  CommonRPCError,
  DeleteProjectSpocsRPCErrors,
  DeleteSPoCDialogState,
  UserAccessStore,
} from "../../../store/UserAccessStore";
import { Instance } from "mobx-state-tree";
import {
  NavigateToFunctions,
  processProjectParams,
  processSPoCParams,
} from "../../../../../routes/RoutesHelper";
import {
  COUNTDOWN_INTERVAL_IN_MS,
  EMPTY_CHARACTER,
  END_OF_COUNTDOWN,
  ICON_SIZE,
  TOTAL_COUNTDOWN_TIME_IN_SEC,
  ZERO_VALUE,
  getIconProps,
} from "@pulse/shared-components";
import { DialogErrorContent } from "../../../../../components/DialogErrorContent";
import { Stack } from "@mui/material";
import { Info } from "lucide-react";
import { NestedTypographyComponent } from "../../NestedTypographyComponent";
import { getDefaultProjectDetailsTab } from "../../../utils/ProjectDetailsTabUtils";

interface DeleteSPoCDialogProps {
  isDeleteSPoCDialogOpen: boolean;
  t: TFunction;
  spacing: Spacing;
  userAccessStore: Instance<typeof UserAccessStore>;
  setIsDeleteSPoCDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  navigateTo: NavigateToFunctions;
  tokens: FoundationColorTokens<string>;
  typography: LeoTypography;
  navigateOnSuccess: boolean;
  tableRef: React.MutableRefObject<TableReloadHandle | null> | undefined;
}

export const DeleteSPoCDialog = ({
  isDeleteSPoCDialogOpen,
  t,
  spacing,
  userAccessStore,
  navigateTo,
  setIsDeleteSPoCDialogOpen,
  tokens,
  typography,
  navigateOnSuccess,
  tableRef,
}: DeleteSPoCDialogProps): React.ReactElement => {
  const [countDownValue, setCountDownValue] = useState(
    TOTAL_COUNTDOWN_TIME_IN_SEC,
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDownValue(countDownValue - 1);
    }, COUNTDOWN_INTERVAL_IN_MS);
    if (countDownValue === END_OF_COUNTDOWN) clearInterval(interval);
    return () => clearInterval(interval);
  }, [countDownValue]);

  const getPrimaryButtonText = (): string | undefined => {
    if (!userAccessStore.isDeleteSPoCDialogState) {
      return;
    }
    if (countDownValue === 0) {
      return t("common.remove");
    } else {
      return t("common.remove") + ` (${countDownValue})`;
    }
  };

  const getSecondaryButtonText = (): string => {
    if (userAccessStore.isDeleteSPoCDialogState) {
      return t("common.cancel");
    } else {
      return t("common.close");
    }
  };

  const getErrorText = (): string => {
    switch (userAccessStore.deleteSPoCDialogState) {
      case DeleteSPoCDialogState.ErrorState: {
        if (
          userAccessStore.rpcErrors === CommonRPCError.ProjectAlreadyArchived
        ) {
          return t("common.projectAlreadyArchivedErrorText");
        } else if (
          userAccessStore.rpcErrors ===
          DeleteProjectSpocsRPCErrors.RespondentsAssignedToSpoc
        ) {
          return t("projects.spocRespondent.settings.respondentAssignedToSPoC");
        } else {
          return t(
            "projects.spocRespondent.settings.unexpectedErrorWhileRemovingSPoc",
          );
        }
      }
    }
    console.error("This flow is developer error");
    return EMPTY_CHARACTER;
  };

  const getDialogChild = (): React.ReactElement => {
    const getMiddleText = (): string | undefined => {
      if (userAccessStore.isSPoCBulkSelected) {
        return `${userAccessStore.multiSelectTableStore.totalSelectedItems(userAccessStore.totalItems)}`;
      } else {
        return userAccessStore.initialSelectedSPoCDetails?.fullName;
      }
    };

    switch (userAccessStore.deleteSPoCDialogState) {
      case DeleteSPoCDialogState.DeleteSPoCState: {
        return (
          <Stack direction="row" gap={spacing.spaceXS}>
            <Info {...getIconProps(tokens.iconError, ICON_SIZE.default)} />
            <Stack gap={spacing.spaceXS}>
              <NestedTypographyComponent
                textColor={tokens.labelError}
                startText={t(
                  "projects.spocRespondent.settings.areYouSureYouWantToRemove",
                )}
                middleBoldText={getMiddleText()}
                endText={
                  userAccessStore.isSPoCBulkSelected
                    ? t("projects.spocRespondent.spocsFromThisProject")
                    : t(
                        "projects.spocRespondent.settings.onceRemovedDescription",
                      )
                }
                startAndEndTextTypography={typography.b2}
                boldTextTypography={typography.s2}
                isSpan={false}
              />
            </Stack>
          </Stack>
        );
      }
      case DeleteSPoCDialogState.ErrorState: {
        return (
          <DialogErrorContent
            spacing={spacing}
            tokens={tokens}
            typography={typography}
            errorText={getErrorText()}
          />
        );
      }
    }
  };
  return (
    <Dialog
      open={isDeleteSPoCDialogOpen}
      title={t("projects.spocRespondent.settings.removeSPoCWithQuestionMark")}
      contentPadding={spacing.spaceLG}
      width="560px"
      onPrimaryButtonClick={async (): Promise<void> => {
        if (userAccessStore.initialSelectedSPoCDetails) {
          await processProjectParams(async (projectId): Promise<void> => {
            await userAccessStore.deleteSPoC(
              projectId,
              userAccessStore.initialSelectedSPoCDetails?.spocId,
            );
          }, navigateTo);
        } else if (userAccessStore.isSPoCBulkSelected) {
          await processProjectParams(async (projectId): Promise<void> => {
            await userAccessStore.deleteSPoC(projectId, undefined);
          }, navigateTo);
        } else {
          await processSPoCParams(userAccessStore.deleteSPoC, navigateTo);
        }
        if (
          !navigateOnSuccess &&
          tableRef &&
          userAccessStore.isNoRPCError &&
          userAccessStore.deleteSPoCDialogState
        ) {
          setIsDeleteSPoCDialogOpen(false);
          tableRef.current?.reload();
        }
        if (
          userAccessStore.isNoRPCError &&
          userAccessStore.deleteSPoCDialogState &&
          navigateOnSuccess
        ) {
          processProjectParams(async (projectId: string): Promise<void> => {
            navigateTo.projectDetails(
              projectId,
              getDefaultProjectDetailsTab(t, navigateTo),
            );
          }, navigateTo);
        }
      }}
      primaryButtonText={getPrimaryButtonText()}
      isPrimaryButtonDisabled={countDownValue !== ZERO_VALUE}
      primaryButtonColor="destructive"
      isSecondaryButtonDisabled={userAccessStore.isRPCLoading}
      secondaryButtonText={getSecondaryButtonText()}
      onSecondaryButtonClick={(): void => {
        setIsDeleteSPoCDialogOpen(false);
        userAccessStore.resetDeleteSPoCProps();
      }}
      disableBackdropClick={true}
    >
      {/*
        getDialogChild() is not called like <DialogChild />.
        This is done to avoid giving it its own lifecycle which will result in loss of focus from <TextInputField /> on store updation.
        */}
      {getDialogChild()}
    </Dialog>
  );
};
