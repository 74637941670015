import React, { useState } from "react";
import { Instance } from "mobx-state-tree";
import {
  CornerRadius,
  FoundationColorTokens,
  Shadow,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { BorderStyle } from "@pulse/shared-components";
import { SurveyQuestionViewStoreNames } from "../stores/SurveyViewDetailsStore";
import { QuestionView } from "./QuestionView";
import { QuestionTypeGraphs } from "./ChartSelection";
import { observer } from "mobx-react";
import { OpenEndedQuestionViewModelV1 } from "../models/OpenEndedQuestionViewModelV1";

interface OpenEndedQuestionViewProps {
  surveyQuestionView: Instance<typeof OpenEndedQuestionViewModelV1>;
  typography: Typography;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  spacing: Spacing;
  border: BorderStyle;
  cornerRadius: CornerRadius;
  boxShadow: Shadow;
  isChildQuestion: boolean;
}

export const OpenEndedQuestionView = observer(
  ({
    surveyQuestionView,
    spacing,
    t,
    tokens,
    typography,
    border,
    cornerRadius,
    boxShadow,
    isChildQuestion,
  }: OpenEndedQuestionViewProps): React.ReactElement => {
    const [
      featureInDevelopmentSnackbarVisible,
      setFeatureInDevelopmentSnackbarVisible,
    ] = useState(false);

    return (
      <QuestionView
        t={t}
        spacing={spacing}
        typography={typography}
        tokens={tokens}
        cornerRadius={cornerRadius}
        border={border}
        isNPS={false}
        questionText={surveyQuestionView.question}
        sampleBreakdown={surveyQuestionView.sampleBreakdown}
        total={null}
        selectedGraph={
          QuestionTypeGraphs[surveyQuestionView.updatedOpenEndedGraphType]
        }
        surveyQuestionViewType={
          SurveyQuestionViewStoreNames.OPEN_ENDED_QUESTION_MODEL
        }
        updateSelectedGraphType={surveyQuestionView.updateSelectedGraphType}
        data={surveyQuestionView.openEndedResultData}
        buttonProps={{
          onDownloadClick: () => {
            setFeatureInDevelopmentSnackbarVisible(true);
          },
          onSaveChangeClick: () => {
            setFeatureInDevelopmentSnackbarVisible(true);
          },
          isDownloadDisabled: false,
          isSaveChangeDisabled: !surveyQuestionView.isGraphSelectionUpdated,
        }}
        boxShadow={boxShadow}
        topNWordsProps={{
          selectedTopNWords: surveyQuestionView.topNWords,
          setSelectedTopNWords: surveyQuestionView.setTopNWords,
        }}
        featureInDevelopmentSnackbarVisible={
          featureInDevelopmentSnackbarVisible
        }
        setFeatureInDevelopmentSnackbarVisible={
          setFeatureInDevelopmentSnackbarVisible
        }
        isChildQuestion={isChildQuestion}
      />
    );
  },
);
