import {
  ClickAwayListener,
  Collapse,
  List,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import {
  BorderStyle,
  DROPDOWN_ANIMATION_TIMING_IN_MS,
  ICON_SIZE,
} from "@pulse/shared-components";
import {
  CornerRadius,
  FoundationColorTokens,
  Shadow,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { ChevronUp, ChevronDown } from "lucide-react";
import React, { useState } from "react";
import { TOP_N_OPEN_ENDED_WORDS } from "../../utils/constants";
import { TFunction } from "i18next";

export interface TopNWordsDropDownProps {
  spacing: Spacing;
  tokens: FoundationColorTokens<string>;
  cornerRadius: CornerRadius;
  boxShadow: Shadow;
  border: BorderStyle;
  selectedTopNWords: number;
  setSelectedTopNWords: (topNWords: number) => void;
  typography: LeoTypography;
  t: TFunction;
}

export const TopNWordsDropDown = ({
  spacing,
  tokens,
  cornerRadius,
  boxShadow,
  border,
  typography,
  t,
  selectedTopNWords,
  setSelectedTopNWords,
}: TopNWordsDropDownProps): React.ReactElement => {
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);

  const handleDropDownClick = (): void => {
    setIsDropDownVisible(!isDropDownVisible);
  };
  return (
    <Stack alignSelf="flex-start">
      <ClickAwayListener
        onClickAway={(): void => {
          setIsDropDownVisible(false);
        }}
      >
        <List style={{ zIndex: 1, padding: 0 }}>
          <ListItemButton
            sx={{
              ":hover": {
                backgroundColor: tokens.backgroundHovered,
              },
              border: border.buttonBorderOutlinedNeutral,
              borderRadius: cornerRadius.radiusXS,
              padding: `${spacing.spaceXS} ${spacing.spaceSM}`,
              justifyContent: "center",
            }}
            onClick={handleDropDownClick}
          >
            <Stack
              direction="row"
              spacing={spacing.spaceXXS}
              alignItems="center"
            >
              <Typography {...typography.bt3} color={tokens.label}>
                {t("surveyDashboard.topNWords", { n: selectedTopNWords })}
              </Typography>
              {isDropDownVisible ? (
                <ChevronUp height={ICON_SIZE.small} width={ICON_SIZE.small} />
              ) : (
                <ChevronDown height={ICON_SIZE.small} width={ICON_SIZE.small} />
              )}
            </Stack>
          </ListItemButton>
          <Collapse
            in={isDropDownVisible}
            timeout={DROPDOWN_ANIMATION_TIMING_IN_MS}
            style={{
              position: "absolute",
              width: "max-content",
              left: 0,
              top: "36px",
              background: tokens.backgroundElevatedLevel2,
            }}
          >
            <List
              style={{
                boxShadow: boxShadow.level3,
                borderRadius: cornerRadius.radiusXS,
                border: border.subtle,
                width: "160px",
                padding: `${spacing.spaceXS} 0px`,
              }}
            >
              {TOP_N_OPEN_ENDED_WORDS.map((topNWords, index) => {
                return (
                  <ListItemButton
                    onClick={(): void => {
                      setSelectedTopNWords(topNWords);
                      setIsDropDownVisible(false);
                    }}
                    sx={{
                      padding: `${spacing.spaceMD} ${spacing.spaceSM}`,
                      backgroundColor:
                        topNWords === selectedTopNWords
                          ? tokens.backgroundSelected
                          : tokens.backgroundElevatedLevel2,
                      "&:hover": tokens.backgroundHoveredElevated,
                    }}
                    key={index}
                  >
                    <Typography {...typography.b1} color={tokens.label}>
                      {t("surveyDashboard.topNWords", { n: topNWords })}
                    </Typography>
                  </ListItemButton>
                );
              })}
            </List>
          </Collapse>
        </List>
      </ClickAwayListener>
    </Stack>
  );
};
