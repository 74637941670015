import { Instance, flow, types, getRoot, cast } from "mobx-state-tree";
import { RootStore } from "../../root/store/RootStore";
import { getAPIClient } from "../../networking/APIClient";
import { KeycloakToken, SignInRPC, UserName } from "@pulse/pulse-rpcs";
import { useSignInRPCClientImpl } from "../auth";
import { UserLocalStorageKeys } from "../../store/user/UserLocalStorageKeys";
import {
  UserPrivileges,
  getPersistedUserId,
  getPersistedUserName,
  getPersistedUserPrivileges,
} from "../../store/user/UserPrivileges";
import { UserNameModel, createUserNameModel } from "../../models/UserNameModel";
import { keycloak } from "../keycloak/keycloak";

export enum SignInError {
  KeycloakNotFound = "KEYCLOAK_NOT_FOUND",
  InvalidAccessToken = "INVALID_ACCESS_TOKEN",
}

export const SignInStore = types
  .model("SignInStore", {
    privileges: types.array(types.string),
    userName: UserNameModel,
    userId: types.string,
    error: types.maybeNull(
      types.enumeration<SignInError>("SignInError", Object.values(SignInError)),
    ),
  })
  .views((store) => ({
    isPrivilegeGranted(privilege: UserPrivileges): boolean {
      return store.privileges.includes(privilege);
    },
    get checkIfViewAssignedProjectPrivilegeGrantedAndGetUserId():
      | string
      | null {
      return store.privileges.includes(
        UserPrivileges.viewCreatedAndAssignedProjects,
      )
        ? store.userId
        : null;
    },
  }))
  .actions((store) => ({
    setUserName(firstName: string, lastName: string): void {
      store.userName = createUserNameModel(firstName, lastName);
    },
    setPrivilegesAndUserDetails(
      privileges: string[],
      userName: UserName,
      userId: string,
    ): void {
      store.privileges = cast(privileges);
      store.userName = createUserNameModel(
        userName.firstName,
        userName.lastName,
      );
      store.userId = userId;
      // The privileges are persisted for the following reasons.
      // 1. They help to identify if the user is logged in or not.
      // 2. This ensures that we don't lose privileges once the page reloads ( since the state is refreshed ).
      localStorage.setItem(
        UserLocalStorageKeys.privileges,
        privileges.toString(),
      );
      localStorage.setItem(
        UserLocalStorageKeys.firstName,
        userName.firstName.trim(),
      );
      localStorage.setItem(
        UserLocalStorageKeys.lastName,
        userName.lastName.trim(),
      );
      localStorage.setItem(UserLocalStorageKeys.userId, userId);
    },
  }))
  .actions((store) => ({
    signInUser: flow(function* () {
      store.error = null;
      const apiClient = getAPIClient(store);
      if (keycloak.token && keycloak.refreshToken) {
        try {
          const request = new SignInRPC.Request(
            new KeycloakToken(keycloak.token, keycloak.refreshToken),
          );
          const {
            response,
            error,
          }: {
            response?: SignInRPC.Response;
            error?: SignInRPC.Errors.Errors;
          } = yield useSignInRPCClientImpl(apiClient).execute(request);
          if (response) {
            const privileges = response.privileges;
            store.setPrivilegesAndUserDetails(
              privileges,
              response.userName,
              response.userId.uuid,
            );
          } else if (error) {
            store.error = SignInError.InvalidAccessToken;
            console.error(
              `Access token not found while calling SignInUser RPC: ${error}`,
            );
            keycloak.logout({
              redirectUri: `${window.location.protocol}//${window.location.host}`,
            });
          }
        } catch (e) {
          if (e instanceof Error) {
            const rootStore = getRoot<typeof RootStore>(store);
            rootStore.networkingStore.errorStore.setLeoError(e);
          } else {
            console.error(`Unhandled error occured: ${e}`);
          }
        }
      } else {
        store.error = SignInError.KeycloakNotFound;
        console.error("Keycloak tokens not present while calling sign-in");
      }
    }),
  }));

export const createSignInStore = (): Instance<typeof SignInStore> => {
  return SignInStore.create({
    privileges: getPersistedUserPrivileges(),
    userName: getPersistedUserName(),
    userId: getPersistedUserId(),
  });
};
