import { Bar, BarCustomLayerProps, BarDatum } from "@nivo/bar";
import React, { ReactElement } from "react";
import {
  DASHBOARD_CHART_COLORS,
  QUESTION_VIEW_MAX_WIDTH_IN_PIXELS,
} from "../../../utils/constants";
import { DataProps } from "../DataSection";
import {
  BorderStyle,
  EMPTY_CHARACTER,
  PRIMARY_FONT,
  RICH_TEXT_DEFAULT_FONT_COLOR,
  RICH_TEXT_MARKDOWN_PROPS,
  richTextTypography,
  RTERegularTextType,
  ZERO_VALUE,
} from "@pulse/shared-components";
import { Stack } from "@mui/material";
import { Legends } from "./Legends";
import {
  CornerRadius,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { RichTextEditor } from "@surya-digital/leo-reactjs-remirror";
import { TooltipContent } from "./TooltipContent";
import { SurveySettingsEnums } from "@pulse/pulse-rpcs";

interface BarGraphProps {
  data: DataProps[];
  isHorizontal: boolean;
  spacing: Spacing;
  cornerRadius: CornerRadius;
  tokens: FoundationColorTokens<string>;
  typography: Typography;
  border: BorderStyle;
}

export const BarGraph = ({
  data,
  isHorizontal,
  cornerRadius,
  spacing,
  tokens,
  typography,
  border,
}: BarGraphProps): ReactElement => {
  const barGraphData: BarDatum[] = data.map((item) => {
    return {
      id: item.id ?? EMPTY_CHARACTER,
      label: item.option ?? EMPTY_CHARACTER,
      value: item.count,
      percentage: item.percentage,
    };
  });
  const getMargin = (): {
    top: number;
    right: number;
    bottom: number;
    left: number;
  } => {
    if (isHorizontal) {
      return { top: 20, right: 30, bottom: 50, left: 130 };
    } else {
      return { top: 20, right: 0, bottom: 50, left: 40 };
    }
  };

  const CustomAxisLabels = (
    props: BarCustomLayerProps<BarDatum>,
  ): ReactElement => {
    if (isHorizontal) {
      return (
        <g transform="translate(0, 0)">
          {barGraphData.map((item, index) => (
            <foreignObject
              key={index}
              width={100}
              height={props.bars[index].height}
              x={-100}
              y={props.bars[index].y}
            >
              <RichTextEditor
                name={`${item.label}`}
                typography={richTextTypography(
                  PRIMARY_FONT,
                  SurveySettingsEnums.FontSizeScale.FontSizeScale.SMALL_SCALE,
                  RICH_TEXT_DEFAULT_FONT_COLOR,
                  true,
                  RTERegularTextType.OPTION_TEXT,
                )}
                mode="preview"
                borderOnPreview={false}
                initialValue={`${item.label}`}
                supports={RICH_TEXT_MARKDOWN_PROPS}
              />
            </foreignObject>
          ))}
        </g>
      );
    } else {
      return (
        <g transform="translate(0, 370)">
          {barGraphData.map((item, index) => (
            <foreignObject
              key={index}
              height={100}
              width={props.bars[index].width}
              x={props.bars[index].x}
              y={10}
            >
              <Stack
                sx={{
                  "& .ProseMirror": {
                    textAlign: "center",
                  },
                }}
              >
                <RichTextEditor
                  name={`${item.label}`}
                  typography={richTextTypography()}
                  mode="preview"
                  borderOnPreview={false}
                  initialValue={`${item.label}`}
                  supports={RICH_TEXT_MARKDOWN_PROPS}
                />
              </Stack>
            </foreignObject>
          ))}
        </g>
      );
    }
  };

  const commonProps = {
    data: barGraphData,
    colors: DASHBOARD_CHART_COLORS,
    keys: ["value"],
    indexBy: "id",
    width: QUESTION_VIEW_MAX_WIDTH_IN_PIXELS,
    margin: getMargin(),
    enableLabel: false,
    animate: false,
  };
  const horizontalBarGraphHeight = Math.max(48 * data.length, 240);

  return (
    <Stack
      width={`${QUESTION_VIEW_MAX_WIDTH_IN_PIXELS}px`}
      gap={isHorizontal ? ZERO_VALUE : spacing.spaceXL}
    >
      {isHorizontal ? (
        <Bar
          {...commonProps}
          height={horizontalBarGraphHeight}
          colorBy="indexValue"
          layers={["grid", CustomAxisLabels, "bars", "axes"]}
          layout={"horizontal"}
          enableGridX={true}
          enableGridY={false}
          axisBottom={{
            format: (e) => (Math.floor(e) === e ? e : ""),
            renderTick: (tick) => {
              return (
                <text
                  x={tick.x}
                  y={tick.y + 20}
                  color={tokens.labelSubtle}
                  {...typography.b2}
                >
                  {Math.floor(tick.value) === tick.value ? tick.value : ""}
                </text>
              );
            },
          }}
          axisLeft={{
            renderTick: () => {
              return <></>;
            },
          }}
          tooltip={(tooltipData) => {
            return (
              <TooltipContent
                spacing={spacing}
                cornerRadius={cornerRadius}
                tokens={tokens}
                label={`${tooltipData.data.label ?? EMPTY_CHARACTER}`}
                percentage={`${tooltipData.data.percentage}`}
                count={`${tooltipData.value}`}
                border={border}
                typography={typography}
              />
            );
          }}
        />
      ) : (
        <Bar
          {...commonProps}
          height={440}
          colorBy="indexValue"
          layers={["grid", CustomAxisLabels, "bars", "axes"]}
          layout="vertical"
          enableGridX={false}
          enableGridY={true}
          axisBottom={{
            renderTick: () => {
              return <></>;
            },
          }}
          axisLeft={{
            renderTick: (tick) => {
              return (
                <text
                  x={tick.x - 30}
                  y={tick.y}
                  color={tokens.labelSubtle}
                  {...typography.b2}
                >
                  {Math.floor(tick.value) === tick.value ? tick.value : ""}
                </text>
              );
            },
          }}
          tooltip={(tooltipData) => {
            return (
              <TooltipContent
                spacing={spacing}
                cornerRadius={cornerRadius}
                tokens={tokens}
                label={`${tooltipData.data.label ?? EMPTY_CHARACTER}`}
                percentage={`${tooltipData.data.percentage}`}
                count={`${tooltipData.value}`}
                border={border}
                typography={typography}
              />
            );
          }}
        />
      )}
      <Legends
        legends={barGraphData.map((item) => `${item.label}`)}
        spacing={spacing}
        colors={DASHBOARD_CHART_COLORS}
        cornerRadius={cornerRadius}
      />
    </Stack>
  );
};
