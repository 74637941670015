import {
  ProportionChartType,
  QuestionViewTypeV1,
  SingleSelectWithProportionGraphTypeV1,
} from "@pulse/pulse-rpcs";
import { Instance, types } from "mobx-state-tree";
import {
  createSampleBreakdownModel,
  SampleBreakdownModel,
} from "./SampleBreakdownModel";
import {
  createSingleSelectResultsModelV1,
  SingleSelectResultsModelV1,
} from "./SingleSelectResultsModelV1";
import { QuestionTypeGraphs } from "../components/ChartSelection";

export const SingleSelectQuestionWithProportionModelV1 = types
  .model("SingleSelectQuestionWithProportionModelV1", {
    question: types.maybeNull(types.string),
    sampleBreakdown: SampleBreakdownModel,
    singleSelectResults: types.array(SingleSelectResultsModelV1),
    singleSelectGraphType: types.enumeration(
      "SingleSelectWithProportionGraphTypeV1",
      Object.values(
        SingleSelectWithProportionGraphTypeV1.SingleSelectWithProportionGraphTypeV1,
      ),
    ),
    updatedSingleSelectGraphType: types.enumeration(
      "SingleSelectWithProportionGraphTypeV1",
      Object.values(
        SingleSelectWithProportionGraphTypeV1.SingleSelectWithProportionGraphTypeV1,
      ),
    ),
    mean: types.maybe(types.number),
    naOptionCode: types.maybeNull(types.string),
  })
  .views((store) => ({
    get isGraphSelectionUpdated(): boolean {
      return store.updatedSingleSelectGraphType !== store.singleSelectGraphType;
    },
  }))
  .actions((store) => ({
    updateSelectedGraphType: (
      updatedSingleSelectGraphType: QuestionTypeGraphs,
    ): void => {
      store.updatedSingleSelectGraphType =
        SingleSelectWithProportionGraphTypeV1.SingleSelectWithProportionGraphTypeV1[
          updatedSingleSelectGraphType as keyof typeof SingleSelectWithProportionGraphTypeV1.SingleSelectWithProportionGraphTypeV1
        ];
    },
  }));

export const createSingleSelectQuestionWithProportionModelV1 = (
  singleSelectWithProportion: QuestionViewTypeV1.SingleSelectWithProportion,
  question: string | null = null,
): Instance<typeof SingleSelectQuestionWithProportionModelV1> => {
  const getMean = (): number | undefined => {
    if (
      singleSelectWithProportion.proportionChartType instanceof
      ProportionChartType.Ryg
    ) {
      return singleSelectWithProportion.proportionChartType.mean;
    } else if (
      singleSelectWithProportion.proportionChartType instanceof
      ProportionChartType.Neutral
    ) {
      return undefined;
    } else {
      console.error(
        `Unknown proportion chart type: ${singleSelectWithProportion.proportionChartType} found`,
      );
      return undefined;
    }
  };

  return SingleSelectQuestionWithProportionModelV1.create({
    sampleBreakdown: createSampleBreakdownModel(
      singleSelectWithProportion.sampleBreakdown,
    ),
    singleSelectGraphType:
      singleSelectWithProportion.singleSelectWithProportionGraphType,
    updatedSingleSelectGraphType:
      singleSelectWithProportion.singleSelectWithProportionGraphType,
    singleSelectResults: createSingleSelectResultsModelV1(
      singleSelectWithProportion.singleSelectResults,
    ),
    mean: getMean(),
    question,
    naOptionCode: singleSelectWithProportion.naOptionCode?.code,
  });
};
