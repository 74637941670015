import {
  AddNewEmbedRPC,
  AddNewEmbedRPCClientImpl,
  CreateDashboardViewRPC,
  CreateDashboardViewRPCClientImpl,
  DeleteEmbedRPC,
  DeleteEmbedRPCClientImpl,
  EditDashboardViewRPC,
  EditDashboardViewRPCClientImpl,
  GetEmbedsRPC,
  GetEmbedsRPCClientImpl,
  GetPaginatedSurveyViewResultsRPC,
  GetPaginatedSurveyViewResultsRPCClientImpl,
  GetSurveyDashboardViewsRPC,
  GetSurveyDashboardViewsRPCClientImpl,
} from "@pulse/pulse-rpcs";
import { MOCK } from "@pulse/shared-components";
import { APIClient } from "@surya-digital/tedwig";
import { MockAddNewEmbedRPCClientImpl } from "./MockAddNewEmbed";
import { MockGetEmbedsRPCClientImpl } from "./MockGetEmbeds";
import { MockDeleteEmbedRPCClientImpl } from "./MockDeleteEmbed";
import { MockGetSurveyDashboardViewsRPCClientImpl } from "./MockGetSurveyDashboardViewsRPCClientImpl";
import { MockCreateDashboardViewRPCClientImpl } from "./MockCreateDashboardViewRPCClientImpl";
import { MockGetPaginatedSurveyViewResultsRPCClientImpl } from "./MockGetPaginatedSurveyViewResultsRPCClientImpl";
import { MockEditDashboardViewRPCClientImpl } from "./MockEditDashboardViewRPCClientImpl";

export const useAddNewEmbedRPCClient = (
  apiClient: APIClient,
): AddNewEmbedRPC => {
  if (MOCK.reportDetails) {
    return new MockAddNewEmbedRPCClientImpl();
  } else {
    return new AddNewEmbedRPCClientImpl(apiClient);
  }
};

export const useGetEmbedsRPCClient = (apiClient: APIClient): GetEmbedsRPC => {
  if (MOCK.reportDetails) {
    return new MockGetEmbedsRPCClientImpl();
  } else {
    return new GetEmbedsRPCClientImpl(apiClient);
  }
};

export const useDeleteEmbedRPCClient = (
  apiClient: APIClient,
): DeleteEmbedRPC => {
  if (MOCK.reportDetails) {
    return new MockDeleteEmbedRPCClientImpl();
  } else {
    return new DeleteEmbedRPCClientImpl(apiClient);
  }
};

export const useGetSurveyDashboardViewsRPCClient = (
  apiClient: APIClient,
): GetSurveyDashboardViewsRPC => {
  if (MOCK.reportDetails) {
    return new MockGetSurveyDashboardViewsRPCClientImpl();
  } else {
    return new GetSurveyDashboardViewsRPCClientImpl(apiClient);
  }
};

export const useCreateDashboardViewRPCClientImpl = (
  apiClient: APIClient,
): CreateDashboardViewRPC => {
  if (MOCK.reportDetails) {
    return new MockCreateDashboardViewRPCClientImpl();
  } else {
    return new CreateDashboardViewRPCClientImpl(apiClient);
  }
};

export const useGetPaginatedSurveyViewResultsRPCClient = (
  apiClient: APIClient,
): GetPaginatedSurveyViewResultsRPC => {
  if (MOCK.reportDetails) {
    return new MockGetPaginatedSurveyViewResultsRPCClientImpl();
  } else {
    return new GetPaginatedSurveyViewResultsRPCClientImpl(apiClient);
  }
};

export const useEditDashboardViewRPCClientImpl = (
  apiClient: APIClient,
): EditDashboardViewRPC => {
  if (MOCK.reportDetails) {
    return new MockEditDashboardViewRPCClientImpl();
  } else {
    return new EditDashboardViewRPCClientImpl(apiClient);
  }
};
