import { getType, Instance } from "mobx-state-tree";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  SurveyQuestionViewStoreNames,
  SurveyViewDetailsStore,
} from "../stores/SurveyViewDetailsStore";
import { RegularSingleSelectQuestionView } from "./RegularSingleSelectQuestionView";
import { RegularSingleSelectQuestionModelV1 } from "../models/RegularSingleSelectQuestionModelV1";
import {
  CornerRadius,
  FoundationColorTokens,
  Shadow,
  LoadingIndicator,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import { BorderStyle, ZERO_VALUE } from "@pulse/shared-components";
import { Stack, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { NPSSingleSelectQuestionModelV1 } from "../models/NPSSingleSelectQuestionModelV1";
import { NPSSingleSelectQuestionView } from "./NPSSingleSelectQuestionView";
import { SingleSelectQuestionWithProportionModelV1 } from "../models/SingleSelectQuestionWithProportionModelV1";
import { SingleSelectQuestionWithProportionView } from "./SingleSelectQuestionWithProportionView";
import { OpenEndedQuestionView } from "./OpenEndedQuestionView";
import { OpenEndedQuestionViewModelV1 } from "../models/OpenEndedQuestionViewModelV1";
import {
  NavigateToFunctions,
  processSurveyViewParams,
} from "../../../routes/RoutesHelper";
import { RankingQuestionView } from "./RankingQuestionView";
import { RankingQuestionViewModelV1 } from "../models/RankingQuestionViewModelV1";
import { MultiSelectQuestionView } from "./MultiSelectQuestionView";
import { MultiSelectQuestionViewModelV1 } from "../models/MultiSelectQuestionViewModelV1";
import { QuestionViewSectionHeader } from "./QuestionViewSectionHeader";
import { getIconProps, ICON_SIZE } from "@pulse/shared-components";
import { FileX2 } from "lucide-react";
import { GroupQuestionViewModelV1 } from "../models/GroupQuestionViewModelV1";
import { GroupQuestionView } from "./GroupQuestionView";
import { GridQuestionViewV1Model } from "../models/GridQuestionV1Model";
import { GridQuestionView } from "./gridView/GridQuestionView";

interface QuestionViewsProps {
  surveyViewDetailsStore: Instance<typeof SurveyViewDetailsStore>;
  typography: LeoTypography;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  spacing: Spacing;
  border: BorderStyle;
  cornerRadius: CornerRadius;
  boxShadow: Shadow;
  navigateTo: NavigateToFunctions;
}

export const QuestionViews = observer(
  ({
    surveyViewDetailsStore,
    spacing,
    t,
    tokens,
    typography,
    border,
    cornerRadius,
    boxShadow,
    navigateTo,
  }: QuestionViewsProps): ReactElement => {
    const questionViewsSectionComponentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (
        surveyViewDetailsStore.isLazyRPCLoading &&
        questionViewsSectionComponentRef.current
      ) {
        questionViewsSectionComponentRef.current.scrollTop =
          questionViewsSectionComponentRef.current?.scrollHeight;
      }
    }, [surveyViewDetailsStore.isLazyRPCLoading]);

    const isPageScrolledToBottom = useCallback((): boolean => {
      return (
        Math.abs(
          (questionViewsSectionComponentRef.current?.scrollHeight ??
            ZERO_VALUE) -
            ((questionViewsSectionComponentRef.current?.scrollTop ??
              ZERO_VALUE) +
              (questionViewsSectionComponentRef.current?.clientHeight ??
                ZERO_VALUE)),
        ) <= 1
      );
    }, [questionViewsSectionComponentRef.current]);

    const onScroll = useMemo(() => {
      return (): void => {
        const atBottom = isPageScrolledToBottom();
        if (
          atBottom &&
          !surveyViewDetailsStore.isLastElementReached &&
          !surveyViewDetailsStore.isLazyRPCLoading
        ) {
          processSurveyViewParams(
            async (
              surveyViewId: string,
              surveyId: string,
              projectId: string,
            ): Promise<void> => {
              await surveyViewDetailsStore.getPaginatedSurveyViewResults(
                surveyViewId,
                surveyId,
                projectId,
                true,
              );
            },
            navigateTo,
          );
        }
      };
    }, [isPageScrolledToBottom, surveyViewDetailsStore, navigateTo]);

    useEffect(() => {
      if (questionViewsSectionComponentRef.current) {
        questionViewsSectionComponentRef.current?.addEventListener(
          "scroll",
          onScroll,
        );
      }
    }, []);

    return (
      <>
        <QuestionViewSectionHeader
          spacing={spacing}
          surveyViewDetailsStore={surveyViewDetailsStore}
          shadow={boxShadow}
          t={t}
          tokens={tokens}
          typography={typography}
          border={border}
          borderRadius={cornerRadius}
          navigateTo={navigateTo}
          questionViewsSectionComponentRef={questionViewsSectionComponentRef}
        />
        {surveyViewDetailsStore.isNoDataPresent ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            gap={spacing.spaceXS}
            height="100%"
          >
            <FileX2
              {...getIconProps(tokens.iconLowEmphasis, ICON_SIZE.large)}
            />
            <Typography {...typography.b1} color={tokens.labelLowEmphasis}>
              {t("surveyDashboard.noDataFound")}
            </Typography>
          </Stack>
        ) : (
          <Stack
            ref={questionViewsSectionComponentRef}
            paddingY={spacing.spaceXL}
            gap={spacing.spaceXL}
            height="100%"
            width="100%"
            alignItems="center"
            overflow="auto"
          >
            {surveyViewDetailsStore.surveyQuestionViews.map(
              (surveyQuestionView, index) => {
                switch (getType(surveyQuestionView).name) {
                  case SurveyQuestionViewStoreNames.REGULAR_SINGLE_SELECT_QUESTION_MODEL: {
                    return (
                      <RegularSingleSelectQuestionView
                        key={index}
                        surveyQuestionView={
                          surveyQuestionView as Instance<
                            typeof RegularSingleSelectQuestionModelV1
                          >
                        }
                        spacing={spacing}
                        t={t}
                        tokens={tokens}
                        typography={typography}
                        border={border}
                        cornerRadius={cornerRadius}
                        boxShadow={boxShadow}
                        isChildQuestion={false}
                      />
                    );
                  }
                  case SurveyQuestionViewStoreNames.SINGLE_SELECT_QUESTION_WITH_PROPORTION_MODEL: {
                    return (
                      <SingleSelectQuestionWithProportionView
                        key={index}
                        surveyQuestionView={
                          surveyQuestionView as Instance<
                            typeof SingleSelectQuestionWithProportionModelV1
                          >
                        }
                        spacing={spacing}
                        t={t}
                        tokens={tokens}
                        typography={typography}
                        border={border}
                        cornerRadius={cornerRadius}
                        boxShadow={boxShadow}
                        isChildQuestion={false}
                      />
                    );
                  }
                  case SurveyQuestionViewStoreNames.NPS_SINGLE_SELECT_QUESTION_MODEL: {
                    return (
                      <NPSSingleSelectQuestionView
                        key={index}
                        surveyQuestionView={
                          surveyQuestionView as Instance<
                            typeof NPSSingleSelectQuestionModelV1
                          >
                        }
                        spacing={spacing}
                        t={t}
                        tokens={tokens}
                        typography={typography}
                        border={border}
                        cornerRadius={cornerRadius}
                        boxShadow={boxShadow}
                        isChildQuestion={false}
                      />
                    );
                  }
                  case SurveyQuestionViewStoreNames.OPEN_ENDED_QUESTION_MODEL: {
                    return (
                      <OpenEndedQuestionView
                        key={index}
                        surveyQuestionView={
                          surveyQuestionView as Instance<
                            typeof OpenEndedQuestionViewModelV1
                          >
                        }
                        spacing={spacing}
                        t={t}
                        tokens={tokens}
                        typography={typography}
                        border={border}
                        cornerRadius={cornerRadius}
                        boxShadow={boxShadow}
                        isChildQuestion={false}
                      />
                    );
                  }
                  case SurveyQuestionViewStoreNames.MULTI_SELECT_QUESTION_MODEL: {
                    return (
                      <MultiSelectQuestionView
                        key={index}
                        surveyQuestionView={
                          surveyQuestionView as Instance<
                            typeof MultiSelectQuestionViewModelV1
                          >
                        }
                        spacing={spacing}
                        t={t}
                        tokens={tokens}
                        typography={typography}
                        border={border}
                        cornerRadius={cornerRadius}
                        boxShadow={boxShadow}
                        isChildQuestion={false}
                      />
                    );
                  }
                  case SurveyQuestionViewStoreNames.RANKING_QUESTION_MODEL: {
                    return (
                      <RankingQuestionView
                        key={index}
                        surveyQuestionView={
                          surveyQuestionView as Instance<
                            typeof RankingQuestionViewModelV1
                          >
                        }
                        spacing={spacing}
                        t={t}
                        tokens={tokens}
                        typography={typography}
                        border={border}
                        cornerRadius={cornerRadius}
                        boxShadow={boxShadow}
                        isChildQuestion={false}
                      />
                    );
                  }
                  case SurveyQuestionViewStoreNames.GROUP_QUESTION_MODEL: {
                    return (
                      <GroupQuestionView
                        key={index}
                        surveyQuestionView={
                          surveyQuestionView as Instance<
                            typeof GroupQuestionViewModelV1
                          >
                        }
                        spacing={spacing}
                        t={t}
                        tokens={tokens}
                        typography={typography}
                        border={border}
                        cornerRadius={cornerRadius}
                        boxShadow={boxShadow}
                      />
                    );
                  }
                  case SurveyQuestionViewStoreNames.GRID_QUESTION_MODEL: {
                    return (
                      <GridQuestionView
                        key={index}
                        surveyQuestionView={
                          surveyQuestionView as Instance<
                            typeof GridQuestionViewV1Model
                          >
                        }
                        spacing={spacing}
                        t={t}
                        tokens={tokens}
                        typography={typography}
                        border={border}
                        cornerRadius={cornerRadius}
                        boxShadow={boxShadow}
                        isChildQuestion={false}
                      />
                    );
                  }
                }
              },
            )}
            {surveyViewDetailsStore.isLazyRPCLoading && (
              <LoadingIndicator
                isLoading={true}
                variant="container"
                loadingText={t("surveyDashboard.lazyLoadingText")}
              />
            )}
          </Stack>
        )}
      </>
    );
  },
);
