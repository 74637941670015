import { APIClient } from "@surya-digital/tedwig";
import {
  cast,
  flow,
  getParentOfType,
  getRoot,
  Instance,
  types,
} from "mobx-state-tree";
import { getAPIClient } from "../../networking/APIClient";
import {
  GetPaginatedSurveyViewResultsRPC,
  GetRespondentColumnNamesRPC,
  QuestionViewResult,
  QuestionViewResultEnums,
  QuestionViewTypeV1,
  SurveyViewResultsEnums,
  TableFilter,
  TableFilterEnums,
} from "@pulse/pulse-rpcs";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import { useGetPaginatedSurveyViewResultsRPCClient } from "../rpcs/RPC";
import { ProjectDetailsStore } from "../../projects/store/ProjectDetailsStore";
import {
  CommonErrors,
  EMPTY_CHARACTER,
  NetworkingError,
} from "@pulse/shared-components";
import { RootStore } from "../../root/store/RootStore";
import {
  createSingleSelectQuestionWithProportionModelV1,
  SingleSelectQuestionWithProportionModelV1,
} from "../models/SingleSelectQuestionWithProportionModelV1";
import {
  createRegularSingleSelectQuestionModelV1,
  RegularSingleSelectQuestionModelV1,
} from "../models/RegularSingleSelectQuestionModelV1";
import {
  createNPSSingleSelectQuestionModelV1,
  NPSSingleSelectQuestionModelV1,
} from "../models/NPSSingleSelectQuestionModelV1";
import {
  createOpenEndedQuestionViewModelV1,
  OpenEndedQuestionViewModelV1,
} from "../models/OpenEndedQuestionViewModelV1";
import {
  createMultiSelectQuestionViewModelV1,
  MultiSelectQuestionViewModelV1,
} from "../models/MultiSelectQuestionViewModelV1";
import {
  createRankingQuestionViewModelV1,
  RankingQuestionViewModelV1,
} from "../models/RankingQuestionViewModelV1";
import {
  createFilterStore,
  FilterStore,
} from "../../../components/filter/store/FilterStore";
import { FilterType } from "../../../components/filter/model/FilterModel";
import { getFormatedLeoDate } from "../../utils/DateUtils";
import {
  createRespondentColumnDetailsModel,
  RespondentColumnDetailsModel,
} from "../../projects/models/RespondentColumnDetailsModel";
import { useGetRespondentColumnNamesRPCClientImpl } from "../../projects/rpcs/RPC";
import {
  createGroupQuestionViewModelV1,
  GroupQuestionViewModelV1,
} from "../models/GroupQuestionViewModelV1";
import {
  createGridQuestionViewV1Model,
  GridQuestionViewV1Model,
} from "../models/GridQuestionV1Model";

enum GetPaginatedSurveyViewResultsRPCErrors {
  InvalidSurveyViewId = "INVALID_SURVEY_VIEW_ID",
  InvalidQuestionViewId = "INVALID_QUESTION_VIEW_ID",
  DeletedColumnFilter = "DELETED_COLUMN_FILTER",
}

export enum SurveyQuestionViewStoreNames {
  REGULAR_SINGLE_SELECT_QUESTION_MODEL = "RegularSingleSelectQuestionModelV1",
  NPS_SINGLE_SELECT_QUESTION_MODEL = "NPSSingleSelectQuestionModelV1",
  SINGLE_SELECT_QUESTION_WITH_PROPORTION_MODEL = "SingleSelectQuestionWithProportionModelV1",
  OPEN_ENDED_QUESTION_MODEL = "OpenEndedQuestionViewModelV1",
  MULTI_SELECT_QUESTION_MODEL = "MultiSelectQuestionViewModelV1",
  RANKING_QUESTION_MODEL = "RankingQuestionViewModelV1",
  GROUP_QUESTION_MODEL = "GroupQuestionViewModelV1",
  GRID_QUESTION_MODEL = "GridQuestionViewV1Model",
  GRID_SINGLE_SELECT_MODEL = "GridSingleSelectModel",
  GRID_SINGLE_SELECT_PROPORTION_MODEL = "GridSingleSelectWithMeanModel",
  GRID_OPEN_ENDED_MODEL = "GridOpenEndedModel",
  GRID_MULTI_SELECT_MODEL = "GridMultiSelectModel",
}

export const SurveyViewDetailsStore = types
  .model("SurveyViewDetailsStore", {
    isRPCLoading: types.optional(types.boolean, false),
    isLazyRPCLoading: types.optional(types.boolean, false),
    currentQuestionViewIds: types.array(types.string),
    rpcError: types.maybeNull(
      types.enumeration(
        "GetPaginatedSurveyViewResultsRPCErrors",
        Object.values(GetPaginatedSurveyViewResultsRPCErrors),
      ),
    ),
    surveyViewName: types.maybeNull(types.string),
    surveyQuestionViews: types.array(
      types.union(
        SingleSelectQuestionWithProportionModelV1,
        RegularSingleSelectQuestionModelV1,
        NPSSingleSelectQuestionModelV1,
        OpenEndedQuestionViewModelV1,
        MultiSelectQuestionViewModelV1,
        RankingQuestionViewModelV1,
        GridQuestionViewV1Model,
        GroupQuestionViewModelV1,
      ),
    ),
    isLastElementReached: types.optional(types.boolean, false),
    filterStore: FilterStore,
    respondentColumns: types.array(RespondentColumnDetailsModel),
    isNoDataPresent: types.optional(types.boolean, false),
  })
  .actions((store) => ({
    getFilters: (): TableFilter[] => {
      const requestFilters: TableFilter[] = [];
      try {
        if (!store.filterStore.areNoFiltersAdded) {
          store.filterStore.appliedFilters.map((filter) => {
            if (filter.key === undefined) {
              console.error("The filter key cannot be null");
              return;
            }
            let tableFilters:
              | TableFilterEnums.Input.DateRange
              | TableFilterEnums.Input.OpenEnded
              | TableFilterEnums.Input.PresetMultiSelect;
            if (filter.filterType === FilterType.OpenEnded) {
              if (filter.openEndedFilterValue === undefined) {
                console.error(
                  "The filter value for Open Ended Filter cannot be null",
                );
                return;
              }
              tableFilters = new TableFilterEnums.Input.OpenEnded(
                filter.openEndedFilterValue,
              );
            } else if (filter.filterType === FilterType.MultiSelect) {
              tableFilters = new TableFilterEnums.Input.PresetMultiSelect(
                filter.multiSelectFilterValue.map((filterValue) => {
                  if (filterValue.id === undefined) {
                    console.error(
                      "The filter value for Multi Select Filter cannot be null",
                    );
                  }
                  return filterValue.id ?? EMPTY_CHARACTER;
                }),
              );
            } else {
              if (
                filter.dateRangeFilterValue &&
                filter.dateRangeFilterValue.startDate &&
                filter.dateRangeFilterValue.endDate
              ) {
                tableFilters = new TableFilterEnums.Input.DateRange(
                  getFormatedLeoDate(filter.dateRangeFilterValue.startDate),
                  getFormatedLeoDate(filter.dateRangeFilterValue.endDate),
                );
              } else {
                console.error("The filter value for date range cannot be null");
                return;
              }
            }
            requestFilters.push(
              new TableFilter(filter.key ?? EMPTY_CHARACTER, tableFilters),
            );
          });
        }
      } catch (e) {
        console.error(
          `Unexpected error ${e} at applyFilters action in RespondentStore.`,
        );
      }
      return requestFilters;
    },
  }))
  .actions((store) => ({
    resetStoreData: (): void => {
      store.isRPCLoading = false;
      store.currentQuestionViewIds.clear();
      store.rpcError = null;
      store.surveyViewName = null;
      store.surveyQuestionViews.clear();
      store.isNoDataPresent = false;
      store.isLastElementReached = false;
      store.filterStore.clearStore();
    },
    resetCurrentQuestionViewIds: (): void => {
      store.currentQuestionViewIds.clear();
    },
    resetIsLastElementReached: (): void => {
      store.isLastElementReached = false;
    },
    getRespondentColumnNames: flow(function* (projectId: string) {
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new GetRespondentColumnNamesRPC.Request(
          new LeoUUID(projectId),
        );
        const {
          response,
          error,
        }: {
          response?: GetRespondentColumnNamesRPC.Response;
          error?: GetRespondentColumnNamesRPC.Errors.Errors;
        } =
          yield useGetRespondentColumnNamesRPCClientImpl(apiClient).execute(
            request,
          );
        if (response) {
          store.respondentColumns = cast(
            response.respondentColumnDetails.map((respondentColumnDetail) => {
              return createRespondentColumnDetailsModel(respondentColumnDetail);
            }),
          );
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
              // This error is already handled in default response interceptor.
              break;
            default: {
              console.error(`Unhandled error occured: ${error}`);
              break;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const rootStore = getRoot<typeof RootStore>(store);
          rootStore.networkingStore.errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      }
    }),
    getPaginatedSurveyViewResults: flow(function* (
      surveyViewId: string,
      surveyId: string,
      projectId: string,
      isLazyCall: boolean,
    ) {
      if (isLazyCall) {
        store.isLazyRPCLoading = true;
      } else {
        store.isRPCLoading = true;
      }
      const requestFilters: TableFilter[] = store.getFilters();
      const errorStore =
        getRoot<typeof RootStore>(store).networkingStore.errorStore;
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new GetPaginatedSurveyViewResultsRPC.Request(
          new LeoUUID(projectId),
          new LeoUUID(surveyId),
          new LeoUUID(surveyViewId),
          store.currentQuestionViewIds.map((questionViewId) => {
            return new LeoUUID(questionViewId);
          }),
          requestFilters,
        );
        const {
          response,
          error,
        }: {
          response?: GetPaginatedSurveyViewResultsRPC.Response;
          error?: GetPaginatedSurveyViewResultsRPC.Errors.Errors;
        } =
          yield useGetPaginatedSurveyViewResultsRPCClient(apiClient).execute(
            request,
          );
        if (response) {
          if (!isLazyCall) {
            const projectDetailsStore = getParentOfType(
              store,
              ProjectDetailsStore,
            );
            projectDetailsStore.surveyStore.setSurveyName(
              response.surveyViewResults.surveyStatusAndName.surveyName.name,
            );
            projectDetailsStore.surveyStore.setSurveyStatus(
              response.surveyViewResults.surveyStatusAndName.surveyStatus,
            );
            store.surveyViewName =
              response.surveyViewResults.surveyViewName.name;
          }
          const questionViewResults: Instance<
            | typeof SingleSelectQuestionWithProportionModelV1
            | typeof NPSSingleSelectQuestionModelV1
            | typeof RegularSingleSelectQuestionModelV1
            | typeof OpenEndedQuestionViewModelV1
            | typeof MultiSelectQuestionViewModelV1
            | typeof RankingQuestionViewModelV1
            | typeof GridQuestionViewV1Model
            | typeof GroupQuestionViewModelV1
          >[] = [];
          if (
            response.surveyViewResults.questionData instanceof
            SurveyViewResultsEnums.QuestionData.NoDataFound
          ) {
            store.isNoDataPresent = true;
            store.isLastElementReached = true;
          } else if (
            response.surveyViewResults.questionData instanceof
            SurveyViewResultsEnums.QuestionData.QuestionDataPresent
          ) {
            if (
              response.surveyViewResults.questionData.questionViewResults
                .length === 0
            ) {
              store.isLastElementReached = true;
            }

            response.surveyViewResults.questionData.questionViewResults.map(
              (questionViewResult: QuestionViewResult) => {
                if (
                  questionViewResult.version instanceof
                  QuestionViewResultEnums.Version.Version1
                ) {
                  store.currentQuestionViewIds.push(
                    questionViewResult.surveyQuestionViewId.uuid,
                  );
                  if (
                    questionViewResult.version.questionViewTypeV1 instanceof
                    QuestionViewTypeV1.SingleSelect
                  ) {
                    questionViewResults.push(
                      createRegularSingleSelectQuestionModelV1(
                        questionViewResult.version.questionViewTypeV1,
                        questionViewResult.question?.text,
                      ),
                    );
                  } else if (
                    questionViewResult.version.questionViewTypeV1 instanceof
                    QuestionViewTypeV1.SingleSelectWithProportion
                  ) {
                    questionViewResults.push(
                      createSingleSelectQuestionWithProportionModelV1(
                        questionViewResult.version.questionViewTypeV1,
                        questionViewResult.question?.text,
                      ),
                    );
                  } else if (
                    questionViewResult.version.questionViewTypeV1 instanceof
                    QuestionViewTypeV1.SingleSelectNps
                  ) {
                    questionViewResults.push(
                      createNPSSingleSelectQuestionModelV1(
                        questionViewResult.version.questionViewTypeV1,
                        questionViewResult.question?.text,
                      ),
                    );
                  } else if (
                    questionViewResult.version.questionViewTypeV1 instanceof
                    QuestionViewTypeV1.OpenEnded
                  ) {
                    questionViewResults.push(
                      createOpenEndedQuestionViewModelV1(
                        questionViewResult.version.questionViewTypeV1,
                        questionViewResult.question?.text,
                      ),
                    );
                  } else if (
                    questionViewResult.version.questionViewTypeV1 instanceof
                    QuestionViewTypeV1.MultiSelect
                  ) {
                    questionViewResults.push(
                      createMultiSelectQuestionViewModelV1(
                        questionViewResult.version.questionViewTypeV1,
                        questionViewResult.question?.text,
                      ),
                    );
                  } else if (
                    questionViewResult.version.questionViewTypeV1 instanceof
                    QuestionViewTypeV1.Ranking
                  ) {
                    questionViewResults.push(
                      createRankingQuestionViewModelV1(
                        questionViewResult.version.questionViewTypeV1,
                        questionViewResult.question?.text,
                      ),
                    );
                  } else if (
                    questionViewResult.version.questionViewTypeV1 instanceof
                    QuestionViewTypeV1.Group
                  ) {
                    questionViewResults.push(
                      createGroupQuestionViewModelV1(
                        questionViewResult.version.questionViewTypeV1,
                        questionViewResult.surveyQuestionViewId.uuid,
                        questionViewResult.question?.text,
                      ),
                    );
                  } else if (
                    questionViewResult.version.questionViewTypeV1 instanceof
                    QuestionViewTypeV1.Grid
                  ) {
                    questionViewResults.push(
                      createGridQuestionViewV1Model(
                        questionViewResult.version.questionViewTypeV1,
                        questionViewResult.surveyQuestionViewId.uuid,
                        questionViewResult.question?.text,
                      ),
                    );
                  } else {
                    console.error(
                      `Unhandled case ${questionViewResult.version.questionViewTypeV1} found.`,
                    );
                  }
                }
              },
            );
          }
          if (isLazyCall) {
            const updatedQuestionViewResults: Instance<
              | typeof SingleSelectQuestionWithProportionModelV1
              | typeof NPSSingleSelectQuestionModelV1
              | typeof RegularSingleSelectQuestionModelV1
              | typeof OpenEndedQuestionViewModelV1
              | typeof MultiSelectQuestionViewModelV1
              | typeof RankingQuestionViewModelV1
              | typeof GridQuestionViewV1Model
              | typeof GroupQuestionViewModelV1
            >[] = [...store.surveyQuestionViews, ...questionViewResults];

            store.surveyQuestionViews.replace(updatedQuestionViewResults);
          } else {
            store.surveyQuestionViews.replace(questionViewResults);
          }
        } else if (error) {
          switch (error.code) {
            case CommonErrors.InvalidProjectId:
            case CommonErrors.InvalidSurveyId:
              break;
            case GetPaginatedSurveyViewResultsRPCErrors.InvalidSurveyViewId: {
              errorStore.setError(NetworkingError.PageNotFound);
              break;
            }
            case GetPaginatedSurveyViewResultsRPCErrors.InvalidQuestionViewId: {
              store.rpcError =
                GetPaginatedSurveyViewResultsRPCErrors.InvalidQuestionViewId;
              break;
            }
            case GetPaginatedSurveyViewResultsRPCErrors.DeletedColumnFilter: {
              store.rpcError =
                GetPaginatedSurveyViewResultsRPCErrors.DeletedColumnFilter;
              break;
            }
            default: {
              errorStore.setError(NetworkingError.InternalError);
              console.error(`Unhandled error occured: ${error}`);
              break;
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          errorStore.setLeoError(e);
        } else {
          console.error(`Unhandled error occured: ${e}`);
        }
      } finally {
        if (isLazyCall) {
          store.isLazyRPCLoading = false;
        } else {
          store.isRPCLoading = false;
        }
      }
    }),
  }));

export const createSurveyViewDetailsStore = (): Instance<
  typeof SurveyViewDetailsStore
> => {
  return SurveyViewDetailsStore.create({
    filterStore: createFilterStore(),
  });
};
