import { Stack, Typography } from "@mui/material";
import {
  CornerRadius,
  FoundationColorTokens,
  Spacing,
  Typography as LeoTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { TFunction } from "i18next";
import React, { ReactElement } from "react";
import { MeanProps } from "./QuestionView";
import { DividerComponent } from "../../surveys/components/commonQuestionConfigurationDetailsComponents/DividerComponent";
import { Bullet } from "@nivo/bullet";
import {
  MEAN_BULLET_WIDTH_IN_PIXELS,
  QUESTION_VIEW_MAX_WIDTH_IN_PIXELS,
} from "../../utils/constants";
import { getHundredthDecimalRoundedPercentageString } from "@pulse/shared-components";

interface MeanComponentProps {
  spacing: Spacing;
  t: TFunction;
  tokens: FoundationColorTokens<string>;
  cornerRadius: CornerRadius;
  typography: LeoTypography;
  meanProps: MeanProps;
}

export const MeanComponent = ({
  cornerRadius,
  spacing,
  t,
  tokens,
  typography,
  meanProps,
}: MeanComponentProps): ReactElement => {
  const BulletMeasure = ({
    color,
    height,
    width,
    x,
    y,
  }: {
    x: number;
    y: number;
    width: number;
    height: number;
    color: string;
  }): ReactElement => {
    return <rect x={x} y={y} width={width} height={height} fill={color} />;
  };
  return (
    <Stack
      padding={spacing.spaceXL}
      borderRadius={cornerRadius.radiusXS}
      width="100%"
      maxWidth={`${QUESTION_VIEW_MAX_WIDTH_IN_PIXELS}px`}
      direction="row"
      justifyContent="space-between"
      sx={{ backgroundColor: tokens.backgroundSubtle }}
    >
      <Stack
        divider={<DividerComponent orientation="horizontal" width="100%" />}
        gap={spacing.spaceSM}
        width="100%"
      >
        <Typography {...typography.b2} color={tokens.labelSubtle}>
          {t("surveyDashboard.mean")}
        </Typography>
        <Typography {...typography.sh4} color={tokens.label}>
          {meanProps.mean &&
            getHundredthDecimalRoundedPercentageString(meanProps.mean)}
        </Typography>
      </Stack>
      <Stack
        divider={<DividerComponent orientation="horizontal" width="100%" />}
        gap={spacing.spaceSM}
      >
        <Stack direction="row" justifyContent="center">
          {meanProps.optionCodes.map((optionCode) => {
            return (
              <Typography
                key={optionCode}
                {...typography.b2}
                color={tokens.labelSubtle}
                width={`${MEAN_BULLET_WIDTH_IN_PIXELS}px`}
                align="right"
              >
                {optionCode}
              </Typography>
            );
          })}
        </Stack>
        {meanProps.mean && (
          <Bullet
            width={MEAN_BULLET_WIDTH_IN_PIXELS * meanProps.optionCodes.length}
            height={12}
            data={[
              {
                id: meanProps.mean,
                measures: [meanProps.mean],
                ranges: meanProps.optionCodes,
              },
            ]}
            rangeColors={tokens.backgroundLowEmphasis}
            measureColors={tokens.backgroundPrimary}
            measureSize={1}
            measureComponent={BulletMeasure}
          />
        )}
      </Stack>
    </Stack>
  );
};
