import { CustomLayerProps, Line } from "@nivo/line";
import React, { ReactElement } from "react";
import { GraphData } from "../SelectedGraph";
import { DataProps } from "../DataSection";
import {
  BorderStyle,
  EMPTY_CHARACTER,
  RICH_TEXT_MARKDOWN_PROPS,
  richTextTypography,
} from "@pulse/shared-components";
import { TooltipContent } from "./TooltipContent";
import {
  CornerRadius,
  FoundationColorTokens,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import { RichTextEditor } from "@surya-digital/leo-reactjs-remirror";
import { QUESTION_VIEW_MAX_WIDTH_IN_PIXELS } from "../../../utils/constants";

interface LineGraphProps {
  data: DataProps[];
  isLineArea: boolean;
  spacing: Spacing;
  cornerRadius: CornerRadius;
  tokens: FoundationColorTokens<string>;
  border: BorderStyle;
  typography: Typography;
}

export const LineGraph = ({
  data,
  isLineArea,
  cornerRadius,
  spacing,
  tokens,
  border,
  typography,
}: LineGraphProps): ReactElement => {
  const graphData: GraphData[] = data.map((item) => {
    return {
      id: item.id,
      label: item.option ?? EMPTY_CHARACTER,
      value: item.count,
      percentage: item.percentage,
    };
  });
  const id = isLineArea ? "Line Data" : "Count: ";

  const CustomAxisLabels = (props: CustomLayerProps): ReactElement => {
    return (
      <g transform="translate(0, 240)">
        {graphData.map((item, index) => (
          <foreignObject
            key={index}
            height={100}
            width={props.innerWidth / graphData.length}
            x={props.points[index].x - 80 / graphData.length}
          >
            <RichTextEditor
              name={`${item.label}`}
              typography={richTextTypography()}
              mode="preview"
              borderOnPreview={false}
              initialValue={`${item.label}`}
              supports={RICH_TEXT_MARKDOWN_PROPS}
            />
          </foreignObject>
        ))}
      </g>
    );
  };
  return (
    <Line
      animate={true}
      data={[
        {
          id,
          data: graphData.map((element, index) => ({
            x: element.label + index,
            y: element.value,
            percentage: element.percentage,
          })),
        },
      ]}
      colors={["rgba(111, 62, 124, 1)"]}
      enableSlices="x"
      enableArea={isLineArea}
      enableGridX={false}
      height={300}
      margin={{
        bottom: 60,
        left: 80,
        right: 80,
        top: 20,
      }}
      width={QUESTION_VIEW_MAX_WIDTH_IN_PIXELS}
      yScale={{
        stacked: false,
        type: "linear",
      }}
      curve="monotoneX"
      enablePoints={graphData.length === 1}
      axisBottom={{
        renderTick: () => {
          return <></>;
        },
      }}
      axisLeft={{
        format: (e) => (Math.floor(e) === e ? e : ""),
      }}
      sliceTooltip={(tooltipData) => {
        const percentage = graphData.find(
          (item, index) =>
            item.label + index === tooltipData.slice.points[0].data.x,
        )?.percentage;
        const label = graphData.find(
          (item, index) =>
            item.label + index === tooltipData.slice.points[0].data.x,
        )?.label;
        return (
          <TooltipContent
            spacing={spacing}
            cornerRadius={cornerRadius}
            tokens={tokens}
            label={`${label ?? EMPTY_CHARACTER}`}
            percentage={`${percentage ?? EMPTY_CHARACTER}`}
            count={`${tooltipData.slice.points[0].data.y}`}
            border={border}
            typography={typography}
          />
        );
      }}
      layers={[
        CustomAxisLabels,
        "grid",
        "markers",
        "axes",
        "areas",
        "crosshair",
        "lines",
        "points",
        "slices",
        "mesh",
      ]}
    />
  );
};
