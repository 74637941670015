import { OpenEndedResultV1 } from "@pulse/pulse-rpcs";
import { getHundredthDecimalRoundedPercentageString } from "@pulse/shared-components";
import { Instance, types } from "mobx-state-tree";

export const OpenEndedResultV1Model = types.model("OpenEndedResultV1Model", {
  answer: types.string,
  percentage: types.string,
  count: types.number,
});

export const createOpenEndedResultsV1Model = (
  openEndedResultV1: OpenEndedResultV1[],
): Instance<typeof OpenEndedResultV1Model>[] => {
  return openEndedResultV1.map((openEndedResult) => {
    return OpenEndedResultV1Model.create({
      answer: openEndedResult.answer,
      percentage: getHundredthDecimalRoundedPercentageString(
        openEndedResult.commonResultBreakdownDetails.percentage,
      ),
      count: openEndedResult.commonResultBreakdownDetails.count.count,
    });
  });
};
