import { Stack } from "@mui/material";
import React, { ReactElement } from "react";
import { DataProps, DataPropsForRanking, DataSection } from "./DataSection";
import { TFunction } from "i18next";
import {
  Button,
  CornerRadius,
  FoundationColorTokens,
  Shadow,
  Spacing,
  Typography,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  BorderStyle,
  EMPTY_CHARACTER,
  FeatureInDevelopmentSnackbar,
} from "@pulse/shared-components";
import { HeaderQuestionDetails } from "./HeaderQuestionDetails";
import { QuestionTypeGraphs, ChartSelection } from "./ChartSelection";
import { Download } from "lucide-react";
import { Instance } from "mobx-state-tree";
import { SampleBreakdownModel } from "../models/SampleBreakdownModel";
import { SurveyQuestionViewStoreNames } from "../stores/SurveyViewDetailsStore";
import { observer } from "mobx-react";
import { SelectedGraph } from "./SelectedGraph";
import { TopNWordsDropDown } from "./TopNWordsDropDown";
import { MeanComponent } from "./MeanComponent";
import { QUESTION_VIEW_MAX_WIDTH_IN_PIXELS } from "../../utils/constants";

interface ButtonProps {
  onDownloadClick: () => void;
  onSaveChangeClick: () => void;
  isDownloadDisabled: boolean;
  isSaveChangeDisabled: boolean;
}

export interface MeanProps {
  mean: number | undefined;
  optionCodes: number[];
}

export interface TopNWordsProps {
  selectedTopNWords: number;
  setSelectedTopNWords: (topNWords: number) => void;
}

interface QuestionViewProps {
  t: TFunction;
  spacing: Spacing;
  typography: Typography;
  tokens: FoundationColorTokens<string>;
  cornerRadius: CornerRadius;
  border: BorderStyle;
  isNPS: boolean;
  questionText: string | null;
  sampleBreakdown: Instance<typeof SampleBreakdownModel> | undefined;
  surveyQuestionViewType: SurveyQuestionViewStoreNames;
  data: DataProps[] | DataPropsForRanking[];
  total: number | null;
  selectedGraph: QuestionTypeGraphs;
  updateSelectedGraphType: (graphType: QuestionTypeGraphs) => void;
  featureInDevelopmentSnackbarVisible: boolean;
  setFeatureInDevelopmentSnackbarVisible: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  buttonProps?: ButtonProps;
  meanProps?: MeanProps;
  topNWordsProps?: TopNWordsProps;
  boxShadow: Shadow;
  isChildQuestion: boolean;
  naOptionCode?: string | null;
}

export const QuestionView = observer(
  ({
    spacing,
    t,
    tokens,
    typography,
    cornerRadius,
    border,
    isNPS,
    questionText,
    sampleBreakdown,
    surveyQuestionViewType,
    data,
    total,
    selectedGraph,
    updateSelectedGraphType,
    buttonProps,
    boxShadow,
    meanProps,
    topNWordsProps,
    featureInDevelopmentSnackbarVisible,
    setFeatureInDevelopmentSnackbarVisible,
    isChildQuestion,
    naOptionCode = null,
  }: QuestionViewProps): ReactElement => {
    return (
      <Stack
        width={isChildQuestion ? "100%" : "1024px"}
        alignItems="center"
        border={isChildQuestion ? undefined : border.default}
        borderRadius={cornerRadius.radiusXS}
        gap={spacing.spaceXL}
        paddingBottom={spacing.space2XL}
      >
        <FeatureInDevelopmentSnackbar
          isSnackbarVisible={featureInDevelopmentSnackbarVisible}
          setIsSnackbarVisible={setFeatureInDevelopmentSnackbarVisible}
        />
        <Stack
          padding={
            isChildQuestion
              ? spacing.spaceSM
              : `${spacing.spaceMD} ${spacing.spaceXL}`
          }
          gap={isChildQuestion ? "0px" : spacing.spaceSM}
          width="100%"
          borderBottom={border.default}
        >
          {
            <Stack direction="row">
              <HeaderQuestionDetails
                questionText={questionText}
                sampleBreakdown={sampleBreakdown}
                typography={typography}
                tokens={tokens}
                t={t}
                spacing={spacing}
                surveyQuestionViewType={surveyQuestionViewType}
                isQuestionTypeIconVisible={true}
              />
              {isChildQuestion && (
                <ChartSelection
                  border={border}
                  cornerRadius={cornerRadius}
                  spacing={spacing}
                  surveyQuestionViewType={surveyQuestionViewType}
                  selectedGraph={selectedGraph}
                  onChartIconClick={updateSelectedGraphType}
                  tokens={tokens}
                />
              )}
            </Stack>
          }
          <Stack direction="row" justifyContent={"space-between"}>
            {!isChildQuestion && (
              <ChartSelection
                border={border}
                cornerRadius={cornerRadius}
                spacing={spacing}
                surveyQuestionViewType={surveyQuestionViewType}
                selectedGraph={selectedGraph}
                onChartIconClick={updateSelectedGraphType}
                tokens={tokens}
              />
            )}
            {buttonProps && !isChildQuestion && (
              <Stack gap={spacing.spaceXS} direction="row">
                <Button
                  label={EMPTY_CHARACTER}
                  onClick={buttonProps.onDownloadClick}
                  name="downloadView"
                  size="small"
                  variant="outlined-neutral"
                  disabled={buttonProps.isDownloadDisabled}
                  icon={<Download />}
                />
                <Button
                  label={t("common.saveChanges")}
                  onClick={buttonProps.onSaveChangeClick}
                  name="saveChanges"
                  size="small"
                  variant="filled"
                  disabled={buttonProps.isSaveChangeDisabled}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
        <Stack
          padding={spacing.space2XL}
          gap={spacing.spaceXL}
          width="100%"
          alignItems="center"
        >
          <SelectedGraph
            selectedGraph={selectedGraph}
            data={data}
            isNeutral={meanProps?.mean === undefined}
            cornerRadius={cornerRadius}
            tokens={tokens}
            spacing={spacing}
            typography={typography}
            border={border}
            naOptionCode={naOptionCode}
          />
          {meanProps?.mean && (
            <MeanComponent
              spacing={spacing}
              t={t}
              tokens={tokens}
              cornerRadius={cornerRadius}
              typography={typography}
              meanProps={meanProps}
            />
          )}
          {topNWordsProps && (
            <Stack
              width="100%"
              maxWidth={`${QUESTION_VIEW_MAX_WIDTH_IN_PIXELS}px`}
            >
              <TopNWordsDropDown
                spacing={spacing}
                tokens={tokens}
                cornerRadius={cornerRadius}
                boxShadow={boxShadow}
                border={border}
                selectedTopNWords={topNWordsProps.selectedTopNWords}
                setSelectedTopNWords={topNWordsProps.setSelectedTopNWords}
                typography={typography}
                t={t}
              />
            </Stack>
          )}
          <DataSection
            spacing={spacing}
            t={t}
            isNPS={isNPS}
            typography={typography}
            tokens={tokens}
            cornerRadius={cornerRadius}
            total={total}
            data={data as DataProps[]}
            border={border}
            isColoredSwatchRequired={
              meanProps === undefined &&
              surveyQuestionViewType !==
                SurveyQuestionViewStoreNames.OPEN_ENDED_QUESTION_MODEL
            }
            customOptionHeaders={undefined}
          />
        </Stack>
      </Stack>
    );
  },
);
