import { Instance } from "mobx-state-tree";
import { useRootStore } from "../root/store/RootStore";
import { ProjectDetailsStore } from "../projects/store/ProjectDetailsStore";
import { RespondentStore } from "../projects/store/RespondentStore";
import { SurveyStore } from "../surveys/store/SurveyStore";
import { ProjectsRootStore } from "../projects/store/ProjectsRootStore";
import { UserAccessStore } from "../projects/store/UserAccessStore";
import { SignInStore } from "../auth/store/SignInStore";
import { ManageBOUserStore } from "../projects/store/ManageBOUserStore";
import { BOUserStore } from "../manageUsers/store/BOUserStore";
import { ReportsStore } from "../reports/stores/ReportsStore";
import { SurveyViewDetailsStore } from "../reports/stores/SurveyViewDetailsStore";

export const useBOUserStore = (): Instance<typeof BOUserStore> => {
  return useRootStore().boUserStore;
};

export const useProjectsRootStore = (): Instance<typeof ProjectsRootStore> => {
  return useRootStore().projectsRootStore;
};

export const useProjectDetailsStore = (): Instance<
  typeof ProjectDetailsStore
> => {
  return useProjectsRootStore().projectDetailsStore;
};

export const useReportsStore = (): Instance<typeof ReportsStore> => {
  return useProjectsRootStore().projectDetailsStore.dashboardStore.reportsStore;
};

export const useSurveyViewDetailsStore = (): Instance<
  typeof SurveyViewDetailsStore
> => {
  return useProjectsRootStore().projectDetailsStore.dashboardStore.reportsStore
    .surveyViewDetailsStore;
};

export const useManageBOUserStore = (): Instance<typeof ManageBOUserStore> => {
  return useProjectsRootStore().projectDetailsStore.userAccessStore
    .manageBOUserStore;
};

export const useSignInStore = (): Instance<typeof SignInStore> => {
  return useRootStore().signInStore;
};

export const useRespondentStore = (): Instance<typeof RespondentStore> => {
  return useProjectDetailsStore().respondentStore;
};

export const useSurveyStore = (): Instance<typeof SurveyStore> => {
  return useProjectDetailsStore().surveyStore;
};

export const useUserAccessStore = (): Instance<typeof UserAccessStore> => {
  return useProjectDetailsStore().userAccessStore;
};
